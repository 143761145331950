import { useNavigate } from "react-router-dom";
import { paymentApi, s1 } from "./RootApiPath";

const updateOrder = async () => {
  const navigate = useNavigate();
  await navigate(`/paymentsuccesspage?orderId=${orderId}`);
};

export default async function RazorpayPayment(
  amount,
  FirstName,
  LastName,
  Email,
  Mobile,
  custId
) {
  // const payment = async () => {
  const data = await fetch(`${paymentApi}/razorpay`, {
    method: "POST",
    body: JSON.stringify({ amount, custId }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((x) => x.json());
  console.log(data);

  const options = {
    key: "rzp_live_jn0S7ZelnBE85s",
    // key: "rzp_test_cXuVIdRi8q8yrR",
    currency: data.currency,
    amount: data.amount,
    description: "Wallet Transaction",
    image: `${paymentApi}/gharesNewLogo.png`,
    order_id: data.id,
    handler: function (response) {
      const paymentId = response.razorpay_payment_id;
      const orderId = response.razorpay_order_id;
      alert("Payment Successful! Order ID: " + orderId);
      updateOrder();
      // window.location.href = `/paymentsuccesspage?orderId=${orderId}`;
    },
    prefill: {
      name: `${FirstName} ${LastName}`,
      email: `${Email}`,
      contact: `${Mobile}`,
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
// }
