import React from "react";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { a3, a39 } from "../../Api/RootApiPath";
import { userLoggedIn } from "../../../redux/action/Actions";

export default function PaymentFailedPage() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderId = params.get("orderId");
  const custId = params.get("custId");
  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const dispatch = useDispatch();
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  useEffect(() => {
    accountDetails();
  }, []);
  const accountDetails = async () => {
    // const formData = new FormData();
    // formData.append("Id", Customer_id);
    const formData = {
      Id: custId,
    };

    try {
      const response = await fetch(a3, {
        method: "POST",
        // body: formData,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        // body: formData,
      });
      const data = await response.json();
      // console.log("Data:", data);
      loggedIn([data.data]);
      updateOrderStatus(orderId);
      // loggedOut(userDetails);
      // setLoading(false);
      // userDetails = data.data[0];
      // setCsData(data.data[0]);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      // navigate("/checkoutpage2");
      alert(error);
    }
  };
  // {
  //   console.log(orderId);
  // }
  const updateOrderStatus = async (orderId) => {
    // const formData = new FormData();
    // formData.append("Id", Customer_id);
    const formData = {
      orderNumber: orderId,
      OrderStatus: "Payment Failed",
    };

    try {
      console.log(formData);
      const response = await fetch(a39, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      // navigate("/checkoutpage2");
      // alert(error);
    }
  };

  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="accountDetailsMainBox">
        <div className="accountDetailsFormBox">
          <h1>Order Failed</h1>
          <p>Sorry, Your order has been cancelled</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
