import React from "react";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import "../PagesStyles/CheckOut.css";
import { Link } from "react-router-dom";
export default function ShippingAddress() {
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <h1 style={{ marginTop: "2rem" }}>2.Shipping Address</h1>
      <div className="accountDetailsMainBox">
        <span>
          <h2>PIN CODE</h2>
          <input type="text" />
        </span>
        <span>
          <h2>STATE</h2>
          <input type="text" />
        </span>
        <span>
          <h2>CITY</h2>
          <input type="text" />
        </span>
        <span>
          <h2>ADDRESS</h2>
          <input type="email" />
        </span>
        <p style={{ textAlign: "left" }}>
          (mentioning of FLAT NO., HOUSE NO., STREET NAME)
        </p>
        <span>
          <h2>ADDRESS LINE 1</h2>
          <input type="text" />
        </span>
        <span>
          <h2>ADDRESS LINE 2</h2>
          <input type="text" />
        </span>
        <span>
          <h2>CITY/DISTRICT</h2>
          <input type="text" />
        </span>
        <Link to="/checkout/accountdetails">
          <button>BACK</button>
        </Link>
        <Link to="/checkout/accountdetails/shippingaddress/paymentinformation">
          <button>NEXT</button>
        </Link>
        <p>
          <em>FREE DELIVERY FOR MEMBERS ON ORDERS ABOVE 10000</em>
        </p>
      </div>
      <Footer />
    </div>
  );
}
