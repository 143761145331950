import React, { useEffect } from "react";
import Heading from "../Heading/Heading";
import Footer from "./Footer";
import "../Components Styles/Footer.css";

export default function PrivacyPolicy() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="ourContactMainBox termsMain">
        <h1>Privacy Policy</h1>
        <div className="terms-items">
          <p>
            We are committed to protecting your privacy. We will only use the
            information that we collect about you lawfully.
          </p>
          <p>
            We collect information about you for 2 reasons: firstly, to process
            your order and second, to provide you with the best possible
            service.
          </p>
          <p>
            We will not e-mail you in the future unless you have given us your
            consent.
          </p>
          <p>
            We will give you the chance to refuse any marketing email from us or
            from another trader in the future.
          </p>
          <h4>The type of information we will collect about you includes:</h4>
          <p>Your Name</p>
          <p>Address</p>
          <p>Phone Number</p>
          <p>Email Address</p>
          <p>
            We will never collect sensitive information about you without your
            explicit consent.
          </p>
          <p>
            The information we hold will be accurate and up to date. You can
            check the information that we hold about you by emailing us. If you
            find any inaccuracies we will delete or correct it promptly.
          </p>
          <p>
            The personal information which we hold will be held securely in
            accordance with our internal security policy and the law.
          </p>
          <p>
            We may use technology to track the patterns of behaviour of visitors
            to our site. This can include using a “cookie” which would be stored
            on your browser. You can usually modify your browser to prevent this
            happening. The information collected in this way can be used to
            identify you unless you modify your browser settings.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
