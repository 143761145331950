import { FILTER_OPENED } from "../ActionTypes";
import { FILTER_CLOSED } from "../ActionTypes";

export const reducer3 = (state = [], action) => {
  switch (action.type) {
    case FILTER_OPENED: {
      return [...state, action.payload];
    }
    case FILTER_CLOSED: {
      const newArray = state.filter((index) => {
        return index !== action.payload;
      });
      return newArray;
    }

    default:
      return state;
  }
};
