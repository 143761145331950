import React, { useEffect, useState } from "react";
import "../PagesStyles/ProductPage.css";
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { IoShuffleSharp } from "react-icons/io5";
import { RiTruckLine, RiHandCoinLine } from "react-icons/ri";
import { BiSupport } from "react-icons/bi";
// import { useParams } from "react-router-dom";
import { a13, s1, s3 } from "../../Api/RootApiPath";
import uspPoints from "../../Images/whyUs3.png";
import ReactImageMagnify from "react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../redux/action/Actions";
import { AiTwotoneCalendar, AiOutlineSafetyCertificate } from "react-icons/ai";
import { BsTruck } from "react-icons/bs";
import { FaExchangeAlt } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
export default function ProductDetails({
  id,
  product_Id,
  product_No,
  product_Name,
  categoryId,
  huid,
  netWt,
  size,
  grosswt,
  purity,
  collection,
  occasion,
  gender,
  description,
  product_Type,
  making_per_gram,
  making_Percentage,
  making_Fixed_Amt,
  making_Fixed_Wastage,
  purityRate,
  stoneWeight,
  stoneAmount,
  featured,
  productCode,
  mrp,
  images,
  imageList1,
  imageList2,
  // imageList3,
  // imageList4,
  // imageList5,
  statusType,
  onScrollToPriceBreakup,
  onScrollToProductDescription,
}) {
  let allImages = "";
  if (images !== null || images !== undefined) {
    allImages = images.split(",");
  }
  const imagesArray = [
    imageList1,
    imageList2,
    // imageList3,
    // imageList4,
    // imageList5,
  ];
  const dispatch = useDispatch();
  const handleAddToBag = () => {
    dispatch(addToCart(myObject));
  };
  const [loading, setLoading] = useState(false);
  const [mainImage, setMainImage] = useState(imagesArray[0]);
  useEffect(() => {
    setMainImage(allImages[0]);
  }, [id]);

  const [finalProductPrice, setFinalProductPrice] = useState("");

  const [border, setBorder] = useState("border1");
  const [count, setCount] = useState(1);

  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    purityRate,
    id
  ) => {
    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;
    let grossTotalRate = 1;

    if (mrp !== "" && mrp !== 0) {
      grossTotalRate = mrp;
    }

    grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount;
    // console.log(id, making_Fixed_Wastage);
    let GSTAdded = GST * grossTotalRate;
    let finalPrice = (grossTotalRate + GSTAdded).toFixed(0);

    if (mrp !== "" && mrp !== 0) {
      GSTAdded = GST * mrp;
      finalPrice = (mrp + GSTAdded).toFixed(0);
    }
    return setFinalProductPrice(finalPrice), setLoading(true);
  };
  useEffect(() => {
    calculateFinalPrice(
      netWt,
      making_per_gram,
      making_Percentage,
      making_Fixed_Amt,
      making_Fixed_Wastage,
      stoneAmount,
      mrp,
      purityRate,
      id
    );
  }, [loading]);
  const myObject = {
    Product_id: product_Id,
    productNo: product_No,
    title: product_Name,
    image: allImages[0],
    price: finalProductPrice,
    count: count,
    size: size,
    finalPrice: finalProductPrice,
  };
  useEffect(() => {
    setMainImage(allImages[0]);
  }, [images]);
  return (
    <div className="productDetailsMainBox">
      <div className="productImagesBox">
        <div className="smallBoxes">
          {/* {imagesArray.map((currElm, index) => { */}
          {allImages.map((currElm, index) => {
            return (
              <div className="">
                <img
                  className="smallBox"
                  // src={`${s1}${currElm}`}
                  src={`${s3}/${currElm}`}
                  alt="Product Images from different angles"
                  key={currElm}
                  onClick={() => setMainImage(currElm)}
                />
              </div>
            );
          })}
        </div>
        {/* {setMainImage(allImages[0])} */}
        <div className="largeBox">
          <ReactImageMagnify
            className="largeBox"
            enlargedImagePosition="over"
            {...{
              smallImage: {
                alt: "product Images",
                isFluidWidth: true,
                // src: `${s1}${mainImage}`,
                src: `${s3}/${mainImage}`,
              },
              largeImage: {
                // src: `${s1}${mainImage}`,
                src: `${s3}/${mainImage}`,
                width: 1500,
                height: 1800,
              },
            }}
          />
        </div>
      </div>
      <div className="productDescriptionBox">
        <div className="titleAndDetails">
          <h1>{product_Name}</h1>
          <div className="productDescriptionBoxDetails">
            <p>
              {/* {description.substr(0, 400)} */}
              {description}
              <span
                style={{ cursor: "pointer" }}
                className="productDescriptionLink"
                id="productDescriptionSection"
              >
                <em onClick={onScrollToProductDescription}>Product Details</em>
              </span>
            </p>
          </div>
        </div>
        {loading == false ? (
          <h3 style={{ margin: "2rem" }}>Loading Price...</h3>
        ) : (
          <div className="priceBox">
            <h2>Price : ₹ {parseFloat(finalProductPrice)}</h2>

            <span>
              (Incl. of all taxes)
              <span id="priceBreakupOption">
                {/* <em onClick={() => window.scrollTo(0, 1050)}>Price Breakup</em> */}
                <em onClick={onScrollToPriceBreakup}>Price Breakup</em>
              </span>
            </span>
          </div>
        )}
        <div className="addToCartBox" style={{ paddingBottom: "2rem" }}>
          <button
            className="countBtn"
            onClick={() => setCount(count > 1 ? count - 1 : count)}
          >
            -
          </button>
          <div className="countInput">{count}</div>
          <button className="countBtn" onClick={() => setCount(count + 1)}>
            +
          </button>
          <button className="addToCartBtn" onClick={handleAddToBag}>
            <AiOutlineShoppingCart size={25} style={{ marginRight: "1rem" }} />
            Add to Cart
          </button>
          <IoShuffleSharp size={25} className="addToCartIcon" />
          <AiOutlineHeart size={25} className="addToCartIcon" />
        </div>
        <div className="whyUsBox">
          {/* <img
            className="whyUsBox"
            src={uspPoints}
            alt="Benefits of Shopping with Ghare's"
          /> */}
          <div className="whyUsItems">
            <BsTruck
              size={40}
              color={"grey"}
              style={{ marginBottom: "0.5rem" }}
            />
            <p>Fast-Free Delivery</p>
          </div>
          <div className="whyUsItems">
            <AiOutlineSafetyCertificate
              size={40}
              color={"grey"}
              style={{ marginBottom: "0.5rem" }}
            />
            <p>100% Hall-Marked & Certified Products </p>
          </div>
          <div className="whyUsItems">
            <GiReceiveMoney
              size={40}
              color={"grey"}
              style={{ marginBottom: "0.5rem" }}
            />
            <p>15-Day Hassle-free Return</p>
          </div>
          <div className="whyUsItems" style={{ borderRight: "none" }}>
            <FaExchangeAlt
              size={40}
              color={"grey"}
              style={{ marginBottom: "0.5rem" }}
            />
            <p>Life-Time Exchange & Buy-Back Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
}
