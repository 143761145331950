import React from "react";
import Heading from "../../Heading/Heading";
import Caraousal from "../HomePage/Caraousal";
import Footer from "../../Footer/Footer";
import "../PagesStyles/ShopPage.css";
import FilterItems from "./FilterItems";
import { useState, useEffect } from "react";
// import { useRef } from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { addToFavList } from "../../../redux/action/Actions";
import { removeFromFavList } from "../../../redux/action/Actions";
import { a16, s1, a13, s3 } from "../../Api/RootApiPath";
import { Link } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
// import { AiOutlineShoppingCart } from "react-icons/ai";
// import { IoShuffleSharp } from "react-icons/io5";
// import { HiOutlineMagnifyingGlassPlus } from "react-icons/hi2";
// import { Link } from "react-router-dom";

export default function ShopPage() {
  // const [visible, setVisible] = useState(false);
  const [todaysCategoryRate, setTodaysCategoryRate] = useState(null);
  const [mobileMenu1, setMobileMenu1] = useState(false);
  const [data, setData] = useState([]);
  const [like, setLike] = useState(false);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [categoryId, setCategoryid] = useState("");
  // const [newArray, setNewArray] = useState([]);
  // const [search, setSearch] = useState("");
  // const searchRef = useRef();
  // const initialRef = useRef();
  // const [initial, setInitial] = useState(0);
  // const [oldData, setOlddata] = useState([]);
  // const allProducts = "https://fakestoreapi.com/products";
  // const allProducts = a16;

  // const allProducts = "/api/ProductMaster/fetchAllProduct";
  // const dispatch = useDispatch();
  const description =
    "Invest in Pure Gold with this attractive coin carved out of 24K 995 Pure Gold";
  const index = useSelector((state) => state);
  // console.log(index);
  let newArray = data;
  const isFilterOpen = index.reducer3;
  // console.log(isFilterOpen);
  // console.log(isFilterOpen);
  let filterTitle = "";
  if (isFilterOpen.length !== 0) {
    const seperated = isFilterOpen.map((x) => x.split(" "));
    const filterName = seperated.map((x) => (x = x[0]));
    const filterType = seperated.map((x) => (x = x[1]));
  } else {
    <h1>No Data Available</h1>;
  }

  // const newArray2 = data.filter((item, index, array) => {
  //   // const isFiltered = filterType.every(
  //   //   (type, i) => item[type] === filterName[i]
  //   // );

  //   const isFirstOccurrence =
  //     array.findIndex((elem) => elem.product_Name === item.product_Name) ===
  //     index;

  //   // return isFiltered && isFirstOccurrence;
  //   return isFirstOccurrence;
  // });

  const newArray2 = data.filter((item, index, array) => {
    // Trim leading and trailing spaces from the product_Name
    const trimmedName = item.product_Name.trim();

    // Check if the trimmedName matches the corresponding filterName
    // const isFiltered = filterType.every(
    //   (type, i) => trimmedName === filterName[i]
    // );

    // Find the index of the first occurrence of the current product_Name
    const firstIndex = array.findIndex(
      (elem) => elem.product_Name.trim() === trimmedName
    );

    // Determine if the current object is the first occurrence
    const isFirstOccurrence = index === firstIndex;

    // return isFiltered && isFirstOccurrence;
    return isFirstOccurrence;
  });

  const SortBy = [
    "Popularity",
    "Newness",
    "Featured",
    "Rating",
    "Low to High",
    "High to Low",
  ];
  let allImages = "";
  // let newArray2 = newArray.filter((x) => x.Product_id != 4);
  // console.log(isFilterOpen);
  const filterIsOpen = (x) => {
    setTitle(x);
    setMobileMenu1(true);
  };
  window.scrollTo(0, 0);
  const searchProducts = async () => {
    await fetch(a16)
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
        if (response.status === "No Data available") {
          setData("NoData");
          setLoading(false);
          // console.log(data);
        } else {
          setData(response.data);
          setLoading(false);
          // console.log(data.map((x) => x.images));
        }
        // setOlddata(response);
        // console.log(response.data);
      });
  };
  useEffect(() => {
    searchProducts();
  }, []);

  const updatedetails = (entry) => {
    setPopup(true);
    if (entry === "image") {
      setPopup("imageRequested");
    }
  };
  const getProductRate = async () => {
    const formData = new FormData();
    formData.append("Category_id", 5);
    try {
      const response = await fetch(a13, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      console.log(data);
      if (data.status == "Success") {
        // const productRateToday = data.data[0];
        const todaysRate = data.data.pop().todaysrate;
        setTodaysCategoryRate(todaysRate);
        // setFinalPrice(calculateFinalPrice(mrp));
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(todaysCategoryRate);
  // getProductRate();
  // console.log(todaysCategoryRate);
  // const [mainImage, setMainImage] = useState(imagesArray[0]);
  // useEffect(() => setMainImage(imagesArray[0]));
  // const [count, setCount] = useState(1);
  // console.log(label);

  // console.log(myObject);
  // let Metal = "Metal";
  // if (categoryId <= 5) {
  //   Metal = "Gold";
  // } else if (categoryId <= 8) {
  //   Metal = "Silver";
  // } else if ((categoryId = 9)) {
  //   Metal = "MRP";
  // } else {
  //   Metal = "Metal";
  // }

  // console.log(data);
  // console.log(todaysCategoryRate);
  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    purityRate,
    id
  ) => {
    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;
    let grossTotalRate = 1;

    if (mrp !== "" && mrp !== 0) {
      grossTotalRate = mrp;
    }

    grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount;

    let GSTAdded = GST * grossTotalRate;
    let finalPrice = grossTotalRate + GSTAdded;

    if (mrp !== "" && mrp !== 0) {
      if (mrp !== "" && mrp !== 0) {
        GSTAdded = GST * mrp;
        finalPrice = mrp + GSTAdded;
      }
    }
    // console.log(id, finalPrice);

    return finalPrice.toFixed(0);
  };

  // console.log(grossTotalRate);
  // else if (making_per_gram !== "" || making_per_gram !== 0) {
  //   netMakingCharges = netWt * making_per_gram;
  // } else if (making_Percentage !== "" || making_Percentage !== 0) {
  //   netMakingCharges = (netGoldRate * making_Percentage) / 100;
  // } else if (making_Fixed_Amt !== "" || making_Fixed_Amt !== 0) {
  //   netMakingCharges = making_Fixed_Amt;
  // } else {
  //   netMakingCharges = 0;
  // }
  // const myObject = {
  //   Product_id: id,
  //   productNo: product_No,
  //   title: product_Name,
  //   image1: imageList1,
  //   price: finalPrice,
  //   count: count,
  //   size: size,
  //   finalPrice: finalPrice,
  // };
  console.log(data);
  const addToWishList = () => {};
  const allProducts = () =>
    data.map((x) => {
      return (
        <div className="container" data-aos="zoom-in">
          <h1>None found</h1>
          <Link to={`/productpage/${x.Product_id}`}>
            <img
              className="productsImages"
              src={`${s1}${x.ImageList1}`}
              key={x.Product_id}
              alt="Products Images"
            />
            {/* {console.log(x)} */}
          </Link>
          <hr />
          {/* <h3>{x.Product_Name.substr(0, 25)}</h3> */}
          <div className="shoppageProductDescriptionBox">
            {/* <p>{x.description.substr(0, 72)}</p> */}
            {calculateFinalPrice(
              x.netWt,
              x.making_per_gram,
              x.making_Percentage,
              x.making_Fixed_Amt,
              x.making_Fixed_Wastage,
              x.stoneAmount,
              x.mrp,
              x.purityRate
            )}
            {/* {console.log(x.netWt)} */}
            <h1>{x.finalPrice.toFixed(0)}</h1>
            {/* <h1>{x.finalPrice.toFixed(0)}</h1> */}
          </div>
        </div>
      );
    });
  useEffect(() => {
    AOS.init({ duration: 1000 });
    // window.scrollTo(0, 500);
  }, []);
  // console.log(data);

  return (
    <div className="shopPageMain">
      <div
      // className={isFilterOpen === true ? "blackBox" : "blackBox hide"}
      ></div>
      <div className="headingBox">
        <Heading />
      </div>
      {/* <Caraousal /> */}
      <div className="filterBox" style={{ marginTop: "5rem" }}>
        {/* <FilterItems /> */}
      </div>

      {/* <div className="mobileFilter">
        <button onClick={() => setMobileMenu1(!mobileMenu1)}>Filter</button>
        {console.log(mobileMenu1)}
        <button onClick={() => setMobileMenu1(!mobileMenu1)}>Sort By:</button>
      </div>
      <div className="mobileFilterList">
        {mobileMenu1 && (
          <div className="mobileDropdownFilters">
            {SortBy.map((option) => (
              <li onClick={() => setMobileMenu1(!mobileMenu1)}>{option}</li>
            ))}
          </div>
        )}
      </div> */}
      <div className={loading == true ? "loading" : "none"}>
        {/* <h1>Loading...</h1> */}
        <InfinitySpin width="200" color="#4fa94d" />
      </div>
      {/* {data.map((x, index) => {
          return (
            <div
            className="container"
            data-aos="zoom-in"
            // onClick={() => takeMe()}
            >
              <Link to={`/productpage/${x.Product_id}`}>
              <img
              className="productsImages"
              src={`${s1}${x.ImageList1}`}
              key={x.Product_id}
              alt="Products Images"
              />
              </Link>
              <hr></hr>
              <h3>{x.Product_Name.substr(0, 25)}</h3>
              <div className="shoppageProductDescriptionBox">
              <p>{x.description.substr(0, 72)}</p>
              </div>
              </div>
              );
            })} */}
      <div className="shopPage">
        {isFilterOpen === [] ? (
          data.map((x, index) => (
            <div className="container" data-aos="zoom-in">
              <Link to={`/productpage/${x.id}`}>
                <img
                  className="productsImages"
                  src={`${s1}${x.imageList1}`}
                  key={x.id}
                  alt="Products Images"
                />
                <hr />
                {/* <h3>{x.Product_Name.substr(0, 25)}</h3> */}
              </Link>
              <div className="shoppageProductDescriptionBox">
                {/* <p>{x.description.substr(0, 72)}</p> */}
              </div>
            </div>
          ))
        ) : data === "NoData" ? (
          <h1>No Data Available</h1>
        ) : (
          newArray2.map((x) => {
            {
              allImages = x.images.split(",");
            }

            const netWt =
              x.netWt === "" || isNaN(x.netWt) ? 0 : parseFloat(x.netWt);
            const makingPerGram =
              x.making_per_gram === "" || isNaN(x.making_per_gram)
                ? 0
                : parseInt(x.making_per_gram);
            const makingPercentage =
              x.making_Percentage === "" || isNaN(x.making_Percentage)
                ? 0
                : parseInt(x.making_Percentage);
            const makingFixedAmt =
              x.making_Fixed_Amt === "" || isNaN(x.making_Fixed_Amt)
                ? 0
                : parseInt(x.making_Fixed_Amt);
            const makingFixedWastage =
              x.making_Fixed_Wastage === "" || isNaN(x.making_Fixed_Wastage)
                ? 0
                : parseFloat(x.making_Fixed_Wastage);
            const stoneAmount =
              x.stoneAmount === "" || isNaN(x.stoneAmount)
                ? 0
                : parseInt(x.stoneAmount);

            const mrp = x.mrp === "" || isNaN(x.mrp) ? 0 : parseInt(x.mrp);
            const purityRate =
              x.purityRate === "" || isNaN(x.tblPurity.todaysRate)
                ? 0
                : parseInt(x.tblPurity.todaysRate);

            return (
              <div className="container" data-aos="zoom-in">
                <Link to={`/productpage/${x.id}`}>
                  <img
                    className="productsImages"
                    // src={`${s1}${x.imageList1}`}
                    src={`${s3}/${allImages[0]}`}
                    key={x.id}
                    alt="Products Images"
                  />
                  {/* {getProductRate(x.category_id)} */}
                  {/* {console.log(x)} */}
                  <hr style={{ border: "1px solid rgba(0,0,0,0.3)" }} />
                  <div
                    className="shoppageProductDescriptionBox"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <h3 style={{ alignSelf: "flex-start" }}>
                      {/* {x.product_Name.substr(0, 25)} */}
                      {x.product_Name}
                    </h3>
                    <p>{`${x.description.substr(0, 80)} ...`}</p>

                    {/* <em> */}
                    <strong>
                      <h3
                        style={{
                          color: "green",
                          fontWeight: "bold",
                        }}
                      >
                        {/* {console.log(
                          x.netWt,
                          x.making_per_gram,
                          x.making_Percentage,
                          x.making_Fixed_Amt,
                          x.making_Fixed_Wastage,
                          x.mrp,
                          x.tblPurity.todaysRate
                        )} */}
                        ₹
                        {calculateFinalPrice(
                          netWt,
                          makingPerGram,
                          makingPercentage,
                          makingFixedAmt,
                          makingFixedWastage,
                          stoneAmount,
                          mrp,
                          purityRate,
                          x.id
                        )}
                      </h3>
                    </strong>
                    {/* {console.log(x.id, purityRate)} */}
                    {/* {console.log(makingPerGram)} */}
                  </div>
                </Link>
              </div>
            );
          })
        )}
      </div>
      {/* {like === false ? (
                          <div
                          className="like"
                          onClick={() => {
                            console.log(x);
                            // dispatch(addToFavList(x.Product_id));
                          }}
                          >
                          <AiOutlineHeart
                          size={"1.3rem"}
                          className="productsHeartIcon"
                          onClick={() => setLike(true)}
                          />
                          </div>
                          ) : (
                            <div
                            className="like"
                            onClick={() => {
                              console.log(x);
                              // dispatch(removeFromFavList(x));
                            }}
                            >
                  <AiFillHeart
                  size={"1.3rem"}
                    color="red"
                    className="productsHeartIcon"
                    onClick={() => setLike(false)}
                    />
                    </div>
                  )} */}
      {/* Need to add this
      <p>
      <em>₹ {x.price}</em>
    </p> */}
      {/* <img className="productsImages" src={x.product_Image} />; */}
      {/* <h3>{x.product_Name}</h3> */}
      {/* {console.log(x)} */}
      {/* <div className="overlay">
      <div className="content">
      <AiOutlineShoppingCart
      size={20}
      className="overlayContentItems"
      />
      <IoShuffleSharp size={20} className="overlayContentItems" />
      <HiOutlineMagnifyingGlassPlus
      size={20}
      className="overlayContentItems"
      />
      <AiOutlineHeart size={20} className="overlayContentItems" />
      </div>
    </div> */}

      <div className={mobileMenu1 === true ? "new" : "new2"}></div>
      <Footer />
    </div>
  );
}
