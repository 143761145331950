import React, { useState, useEffect } from "react";
import { a19, a22 } from "../../../Api/RootApiPath";

export default function AdminDailyRatesTable() {
  const [rates, setRates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [purityData, setPurityData] = useState([]);
  const [inputValues, setInputValues] = useState({});
  useEffect(() => {
    fetchAllPurity();
  }, [isLoading]);

  const fetchAllPurity = async () => {
    await fetch(a22)
      .then((res) => res.json())
      .then((data) => setPurityData(data.data));
    setIsLoading(false);
    console.log(purityData);
  };

  return (
    <div className="adminDailyRateTableBox">
      <div className="adminDailyRateTableBox">
        <table>
          <thead>
            <tr>
              <th>Category Label</th>
              <th>Purity</th>
              <th>Todays Rate</th>
            </tr>
          </thead>
          <tbody>
            {purityData.map((x) => {
              return (
                <tr>
                  <td style={{ padding: "5px" }}>{x.category}</td>
                  <td>{x.label}</td>
                  <td>{`₹ ${x.todaysRate}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
