import React from "react";
import { Link, useParams } from "react-router-dom";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { useState, useEffect } from "react";
import { a16, s3 } from "../../Api/RootApiPath";
import { InfinitySpin } from "react-loader-spinner";

export default function CategoryPage() {
  const { category } = useParams();
  const [loading, setLoading] = useState(true);
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllProductsData();
  }, [category]);

  console.log(allProducts);

  let allImages = "";

  const fetchAllProductsData = async () => {
    try {
      const response = await fetch(a16);
      const data = await response.json();
      setAllProducts(data.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching products data: ", error);
    }
  };
  const newArray = allProducts.filter((item, index, array) => {
    // Trim leading and trailing spaces from the product_Name
    const trimmedName = item.product_Name.trim();

    // Check if the trimmedName matches the corresponding filterName
    // const isFiltered = filterType.every(
    //   (type, i) => trimmedName === filterName[i]
    // );

    // Find the index of the first occurrence of the current product_Name
    const firstIndex = array.findIndex(
      (elem) => elem.product_Name.trim() === trimmedName
    );

    // Determine if the current object is the first occurrence
    const isFirstOccurrence = index === firstIndex;

    // return isFiltered && isFirstOccurrence;
    return isFirstOccurrence;
  });

  const filteredProducts = newArray.filter((product) => {
    // const categoryName = product.collection.split(" ").pop().toLowerCase();
    const categoryName = product.product_Name.split(" ").pop().toLowerCase();
    console.log("Category:", category);
    console.log("Category Name:", categoryName);
    console.log("Matching:", categoryName === category.toLowerCase());
    if (category.toLowerCase() === "gift") {
      return categoryName === "gift" || categoryName === "coin";
    }

    return categoryName.toLowerCase() === category.toLowerCase();
  });
  if (category.toLowerCase() === "coin" || category.toLowerCase() === "gift") {
    filteredProducts.reverse();
  }

  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    purityRate,
    id
  ) => {
    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;
    let grossTotalRate = 1;

    if (mrp !== "" && mrp !== 0) {
      grossTotalRate = mrp;
    }

    grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount;

    let GSTAdded = GST * grossTotalRate;
    let finalPrice = grossTotalRate + GSTAdded;

    if (mrp !== "" && mrp !== 0) {
      finalPrice = mrp;
    }

    return finalPrice.toFixed(0);
  };

  console.log(category);

  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      {loading ? (
        <div style={{ height: "150px", margin: "100px 0" }}>
          <InfinitySpin width={200} color="#4fa94d" />
        </div>
      ) : (
        <div className="shopPage">
          {filteredProducts.length === 0 ? (
            <h1 style={{ margin: "100px" }}>Coming Soon...</h1>
          ) : (
            filteredProducts.map((x) => {
              allImages = x.images.split(",");
              const netWt = parseFloat(x.netWt) || 0;
              const makingPerGram = parseInt(x.making_per_gram) || 0;
              const makingPercentage = parseInt(x.making_Percentage) || 0;
              const makingFixedAmt = parseInt(x.making_Fixed_Amt) || 0;
              const makingFixedWastage =
                parseFloat(x.making_Fixed_Wastage) || 0;
              const stoneAmount = parseInt(x.stoneAmount) || 0;
              const mrp = parseInt(x.mrp) || 0;
              const purityRate = parseInt(x.tblPurity.todaysRate) || 0;

              return (
                <div className="container" data-aos="zoom-in" key={x.id}>
                  <Link to={`/productpage/${x.id}`}>
                    <img
                      className="productsImages"
                      src={`${s3}/${allImages[0]}`}
                      alt="Products Images"
                    />
                    {/* <hr style={{ border: "1px solid rgba(0,0,0,0.3)" }} /> */}
                    <div
                      className="shoppageProductDescriptionBox"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <h3 style={{ alignSelf: "flex-start" }}>
                        {x.product_Name}
                      </h3>
                      <p>{`${x.description.substr(0, 80)} ...`}</p>
                      <strong>
                        <h3
                          style={{
                            color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          ₹
                          {calculateFinalPrice(
                            netWt,
                            makingPerGram,
                            makingPercentage,
                            makingFixedAmt,
                            makingFixedWastage,
                            stoneAmount,
                            mrp,
                            purityRate,
                            x.id
                          )}
                        </h3>
                      </strong>
                    </div>
                  </Link>
                </div>
              );
            })
          )}
        </div>
      )}

      <Footer />
    </div>
  );
}
