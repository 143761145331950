import {
  ADD_TO_CART,
  ADD_TO_FAV,
  REMOVE_FROM_CART,
  REMOVE_FROM_FAV,
  FILTER_OPENED,
  FILTER_CLOSED,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  ADMIN_LOGGED_IN,
  ADMIN_LOGGED_OUT,
  ADMIN_SIDEBAR_OPENED,
  ADMIN_SIDEBAR_CLOSED,
  FETCH_ALL_PRODUCTS,
} from "../ActionTypes";

export const addToFavList = (data) => ({
  type: ADD_TO_FAV,
  payload: data,
});

export const removeFromFavList = (index) => ({
  type: REMOVE_FROM_FAV,
  payload: index,
});

export const addToCart = (data) => ({
  type: ADD_TO_CART,
  payload: data,
});

export const removeFromCart = (index) => ({
  type: REMOVE_FROM_CART,
  payload: index,
});
export const filterOpened = (index) => ({
  type: FILTER_OPENED,
  payload: index,
});
export const filterClosed = (index) => ({
  type: FILTER_CLOSED,
  payload: index,
});
export const userLoggedIn = (index) => ({
  type: USER_LOGGED_IN,
  payload: index,
});
export const userLoggedOut = (index) => ({
  type: USER_LOGGED_OUT,
  payload: index,
});
export const adminLoggedIn = (index) => ({
  type: ADMIN_LOGGED_IN,
  payload: index,
});
export const adminLoggedOut = (index) => ({
  type: ADMIN_LOGGED_OUT,
  payload: index,
});
export const adminSidebarOpened = (index) => ({
  type: ADMIN_SIDEBAR_OPENED,
  payload: index,
});
export const adminSidebarClosed = (index) => ({
  type: ADMIN_SIDEBAR_CLOSED,
  payload: index,
});
export const fetchAllProducts = (data) => ({
  type: FETCH_ALL_PRODUCTS,
  payload: data,
});
