import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useRazorpay from "react-razorpay"; // eslint-disable-next-line

// before we start where is the code for order id ???

const RazorpayPage = () => {
  const { orderId } = useParams();
  const [paymentError, setPaymentError] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const razorpay = useRazorpay();

  const displayPaymentError = () => {
    if (paymentError) {
      return (
        <div className="error-message">Payment Failed. Please try again.</div>
      );
    }
    return null;
  };

  const handlePaymentSuccess = (response) => {
    console.log("Payment success", response);
    // Handle successful payment here
  };

  const handlePaymentFailure = (error) => {
    console.error("Error while processing payment", error);
    setPaymentError(true);
    setPaymentInProgress(false);
  };

  const handlePayment = async () => {
    try {
      setPaymentInProgress(true);
      const response = await fetch(`/api/orders/${orderId}/pay`, {
        // const response = await fetch(`/api/orders/order_LZbWw0ODN5vRsP/pay`, {
        method: "POST",
      });
      const {
        amount,
        currency,
        orderId: razorpayOrderId,
        key: rzp_live_jn0S7ZelnBE85s,
        // key: rzp_test_r923LdFmdnbv3Y,
      } = await response.json();
      const options = {
        key: "rzp_live_jn0S7ZelnBE85s",
        // key: "rzp_test_r923LdFmdnbv3Y",
        amount: 120,
        currency: "INR",
        name: "Ghare's Jewellers",
        description: "Payment for your order",
        order_id: "order_LZbWw0ODN5vRsP",
        handler: handlePaymentSuccess,
        prefill: {
          name: "Saajan",
          email: "customer-email@example.com",
          contact: "1234567890",
        },
        notes: {
          address: "Customer Address",
        },
        theme: {
          color: "#F37254",
        },
      };
      razorpay.open(options);
    } catch (error) {
      handlePaymentFailure(error);
    }
  };

  return (
    <div className="payment-page">
      {displayPaymentError()}
      <button
        className="payment-button"
        onClick={handlePayment}
        disabled={paymentInProgress}
      >
        {paymentInProgress ? "Processing Payment..." : "Pay Now"}
      </button>
    </div>
  );
};

export default RazorpayPage;

// import { useCallback } from "react";
// import useRazorpay from "react-razorpay";

// export default function RazorpayPage() {
//   const Razorpay = useRazorpay();

//   const handlePayment = useCallback(async () => {
//     const order = await createOrder(params);

//     const options = {
//       key: "YOUR_KEY_ID",
//       amount: "3000",
//       currency: "INR",
//       name: "Acme Corp",
//       description: "Test Transaction",
//       image: "https://example.com/your_logo",
//       order_id: order.id,
//       handler: (res) => {
//         console.log(res);
//       },
//       prefill: {
//         name: "Piyush Garg",
//         email: "youremail@example.com",
//         contact: "9999999999",
//       },
//       notes: {
//         address: "Razorpay Corporate Office",
//       },
//       theme: {
//         color: "#3399cc",
//       },
//     };

//     const rzpay = new Razorpay(options);
//     rzpay.open();
//   }, [Razorpay]);

//   return (
//     <div className="App">
//       <button onClick={handlePayment}>Click</button>
//     </div>
//   );
// }
