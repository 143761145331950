import { ADD_TO_CART, REMOVE_FROM_CART } from "../ActionTypes";

export const reducer2 = (state = [], action) => {
    switch (action.type) {
        case ADD_TO_CART:
            {
                return [...state, action.payload]
            }
        case REMOVE_FROM_CART:
            {
                const newArray = state.filter((index) => {
                    return index !== action.payload
                })
                return newArray
            }

        default:
            return state;
    }
}



















