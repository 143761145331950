import React, { useEffect, useRef, useState } from "react";
import Heading from "../../Heading/Heading";
import ProductDetails from "./ProductDetails";
import { a17, a13 } from "../../Api/RootApiPath";
import Footer from "../../Footer/Footer";
import PriceBreakup from "./PriceBreakup";
import { useParams } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
export default function ProductPage() {
  const { id } = useParams();
  const [noloading, setNoLoading] = useState(false);
  const [priceloading, setPriceLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [product_Id, setProduct_Id] = useState("");
  const [product_No, setProduct_No] = useState("");
  const [product_Name, setProduct_Name] = useState(" ");
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [huid, setHUID] = useState("");
  const [netWt, setNetWt] = useState("");
  const [material, setMaterial] = useState("");
  const [gram, setGram] = useState("");
  const [size, setSize] = useState("");
  const [grosswt, setGrosswt] = useState("");
  const [purity, setPurity] = useState("");
  const [purityRate, setPurityRate] = useState("");
  const [collection, setCollection] = useState("");
  const [occasion, setOccasion] = useState("");
  const [gender, setGender] = useState("");
  const [description, setDescription] = useState("");
  const [product_Type, setProduct_Type] = useState("");
  const [itemType, setItemType] = useState("");
  const [making_per_gram, setMaking_per_gram] = useState("");
  const [making_Percentage, setMaking_Percentage] = useState("");
  const [making_Fixed_Amt, setMaking_Fixed_Amt] = useState("");
  const [making_Fixed_Wastage, setMaking_Fixed_Wastage] = useState("");
  const [stoneWeight, setStoneWeight] = useState("");
  const [stoneAmount, setStoneAmount] = useState("");
  const [featured, setFeatured] = useState("");
  const [productCode, setProductCode] = useState("");
  const [mrp, setMRP] = useState("");
  const [images, setImages] = useState(null);
  const [imageList1, setImageList1] = useState(null);
  const [imageList2, setImageList2] = useState(null);
  const [imageList3, setImageList3] = useState(null);
  const [imageList4, setImageList4] = useState(null);
  const [imageList5, setImageList5] = useState(null);
  const [todaysCategoryRate, setTodaysCategoryRate] = useState(null);
  const [statusType, setStatusType] = useState("");
  const [finalPrice, setFinalPrice] = useState("");

  const csStates = useSelector((state) => state);
  let userDetails = csStates.reducer2;

  const [scrollToPriceBreakup, setScrollToPriceBreakup] = useState(false);
  const [scrollToProductDescription, setScrollToProductDescription] =
    useState(false);

  const handleScrollToPriceBreakup = () => {
    setScrollToPriceBreakup(true);
  };
  const handleScrollToProductDescription = () => {
    setScrollToPriceBreakup(true);
  };

  useEffect(() => {
    if (scrollToPriceBreakup) {
      const priceBreakupSection = document.getElementById(
        "priceBreakupSection"
      );
      if (priceBreakupSection) {
        priceBreakupSection.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "end",
        });
        setScrollToPriceBreakup(false);
      }
    }
  }, [scrollToPriceBreakup]);
  useEffect(() => {
    if (scrollToProductDescription) {
      const productDescriptionSection = document.getElementById(
        "productDescriptionSection"
      );
      if (productDescriptionSection) {
        productDescriptionSection.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "end",
        });
        setScrollToProductDescription(false);
      }
    }
  }, [scrollToProductDescription]);

  const getProduct = async () => {
    const formData = {
      Id: id,
    };
    // const formData = new FormData();
    // formData.append("Product_id", id);
    try {
      const response = await fetch(a17, {
        method: "POST",
        //   body: formData,
        // });
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data.data);
      const product = data.data;
      setProduct(product);
      setProduct_Id(product.id);
      setProduct_No(product.product_No);
      setProduct_Name(product.product_Name);
      setCategoryId(product.category_id);
      setCategoryName(product.category_Name);
      setHUID(product.HUIDCode);
      setSize(product.size);
      setGrosswt(product.grosswt);
      setPurity(product.purity);
      setCollection(product.collection);
      setOccasion(product.occasion);
      setGender(product.gender);
      setDescription(product.description);
      setProduct_Type(product.product_Type);
      setItemType(product.itemType);
      setStoneWeight(product.stoneWeight);
      setFeatured(product.featured);
      setProductCode(product.product_Code);
      setImages(product.images);
      setImageList1(product.imageList1);
      setImageList2(product.imageList2);
      setImageList3(product.imageList3);
      setImageList4(product.imageList4);
      setImageList5(product.imageList5);
      setStatusType(product.statusType);

      const netWt =
        product.netWt == "" || isNaN(product.netWt)
          ? 0
          : parseFloat(product.netWt);
      setNetWt(netWt);
      const makingPerGram =
        product.making_per_gram === "" || isNaN(product.making_per_gram)
          ? 0
          : parseFloat(product.making_per_gram);
      setMaking_per_gram(makingPerGram);
      const makingPercentage =
        product.making_Percentage === "" || isNaN(product.making_Percentage)
          ? 0
          : parseFloat(product.making_Percentage);
      setMaking_Percentage(makingPercentage);
      const makingFixedAmt =
        product.making_Fixed_Amt === "" || isNaN(product.making_Fixed_Amt)
          ? 0
          : parseFloat(product.making_Fixed_Amt);
      setMaking_Fixed_Amt(makingFixedAmt);
      const makingFixedWastage =
        product.making_Fixed_Wastage === "" ||
        isNaN(product.making_Fixed_Wastage)
          ? 0
          : parseFloat(product.making_Fixed_Wastage);
      setMaking_Fixed_Wastage(makingFixedWastage);
      if (product.making_Fixed_Wastage.startsWith(".")) {
        setMaking_Fixed_Wastage("0" + product.making_Fixed_Wastage);
      }
      const stoneAmount =
        product.stoneAmount == "" || isNaN(product.stoneAmount)
          ? 0
          : parseFloat(product.stoneAmount);
      setStoneAmount(stoneAmount);

      const mrp =
        product.mrp == "" || isNaN(product.mrp) ? 0 : parseInt(product.mrp);
      setMRP(mrp);
      const purityRate =
        product.purityRate == "" || isNaN(product.tblPurity.todaysRate)
          ? 0
          : parseInt(product.tblPurity.todaysRate);
      setPurityRate(purityRate);

      calculateFinalPrice(
        parseFloat(netWt),
        parseFloat(making_per_gram),
        parseFloat(making_Percentage),
        parseFloat(making_Fixed_Amt),
        parseFloat(making_Fixed_Wastage),
        parseFloat(stoneAmount),
        parseFloat(mrp),
        parseFloat(purityRate),
        parseInt(id)
      );

      window.scrollTo(0, 0);
      setPriceLoading(true);
    } catch (error) {
      console.error(error);
    }
  };
  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    purityRate,
    id
  ) => {
    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;
    let grossTotalRate = 1;

    if (mrp !== "" && mrp !== 0) {
      grossTotalRate = mrp;
    }

    grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount;

    let GSTAdded = GST * grossTotalRate;
    let finalPrice = grossTotalRate + GSTAdded;

    if (mrp !== "" && mrp !== 0) {
      finalPrice = parseFloat(mrp);
    }

    setFinalPrice(finalPrice); // Convert to string before setting state
    return finalPrice; // Convert to string before returning
  };
  // console.log(making_Fixed_Wastage);

  useEffect(() => {
    getProduct();
  }, [id]);

  return (
    <div className="ProductPageMain">
      <div className="headingBox">
        <Heading />
      </div>
      {priceloading == false ? (
        <InfinitySpin width="200" color="#4fa94d" />
      ) : (
        <div>
          <div className={product === null ? "new" : "new2"}></div>

          <ProductDetails
            key={product_No}
            id={product_No}
            product_Id={product_Id}
            product_No={product_No}
            product_Name={product_Name}
            categoryId={categoryId}
            huid={huid}
            netWt={netWt}
            size={size}
            grosswt={grosswt}
            purity={purity}
            collection={collection}
            occasion={occasion}
            gender={gender}
            description={description}
            product_Type={product_Type}
            purityRate={purityRate}
            stoneAmount={stoneAmount}
            making_per_gram={making_per_gram}
            making_Percentage={making_Percentage}
            making_Fixed_Amt={making_Fixed_Amt}
            making_Fixed_Wastage={making_Fixed_Wastage}
            stoneWeight={stoneWeight}
            featured={featured}
            productCode={productCode}
            mrp={mrp}
            images={images}
            imageList1={imageList1}
            imageList2={imageList2}
            imageList3={imageList3}
            imageList4={imageList4}
            imageList5={imageList5}
            statusType={statusType}
            onScrollToPriceBreakup={handleScrollToPriceBreakup}
            onScrollToProductDescription={handleScrollToProductDescription}
          />

          <PriceBreakup
            id={product_Id}
            key={product_Id}
            product_Id={product_Id}
            product_No={product_No}
            product_Name={product_Name}
            categoryId={categoryId}
            categoryName={categoryName}
            huid={huid}
            netWt={netWt}
            size={size}
            grosswt={grosswt}
            purity={purity}
            collection={collection}
            occasion={occasion}
            gender={gender}
            description={description}
            product_Type={product_Type}
            itemType={itemType}
            purityRate={purityRate}
            stoneAmount={stoneAmount}
            making_per_gram={making_per_gram}
            making_Percentage={making_Percentage}
            making_Fixed_Amt={making_Fixed_Amt}
            making_Fixed_Wastage={making_Fixed_Wastage}
            stoneWeight={stoneWeight}
            featured={featured}
            productCode={productCode}
            mrp={mrp}
            images={images}
            imageList1={imageList1}
            imageList2={imageList2}
            imageList3={imageList3}
            imageList4={imageList4}
            imageList5={imageList5}
            statusType={statusType}
          />
        </div>
      )}
      <Footer />
    </div>
  );
}
