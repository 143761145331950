import { ADD_TO_FAV, REMOVE_FROM_FAV } from "../ActionTypes";

export const reducer1 = (state = [], action) => {
  switch (action.type) {
    case ADD_TO_FAV: {
      return [...state, action.payload];
      // return console.log("state");
    }
    case REMOVE_FROM_FAV: {
      const deleteArray = state.filter((item, index) => {
        return item !== action.payload;
      });
      return deleteArray;
    }

    default:
      return state;
  }
};
