import React, { useState } from "react";
import "../Components Styles/MobileNavbar.css";
import featured from "../Images/featuredImge.jpg";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { BiChevronRight, BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MobileNavbar({ goldList, silverList }) {
  const [allCategories, setCategories] = useState(false);
  const bagItems = useSelector((state) => state);
  // console.log(bagItems);
  const uniqueItem = bagItems.reducer2;
  const userDetails = bagItems.reducer4;
  const totalItems = bagItems.reducer2.length;
  return (
    <div className="mobileNavbarMainBox">
      <div className="mobileNavbarTop">
        <div className="mobileNavbarTopOffer"></div>
      </div>
      <div className="mobileNavbarBody">
        <h3 onClick={() => setCategories(!allCategories)}>
          ALL CATEGORIES
          {allCategories === true ? (
            <BiChevronRight
              size={"1.5rem"}
              className="mobileNavbarDropdownIcon"
            />
          ) : (
            <BiChevronDown
              size={"1.5rem"}
              className="mobileNavbarDropdownIcon"
            />
          )}
        </h3>
        <div
          className={
            allCategories === true
              ? "allCategoriesList hide"
              : "allCategoriesList"
          }
        >
          {goldList.map((x, y) => (
            <Link to="/shoppage" key={y}>
              <p>{x.name}</p>
            </Link>
          ))}
        </div>
        <h3>
          <Link to="/category/coin">GOLD</Link>
        </h3>
        <h3>SILVER</h3>
        <h3>
          {" "}
          <Link to="/shoppage">WORLD OF GHARE'S</Link>
        </h3>

        <h3>
          <Link to="/category/coin">BOOK A GOLD COIN </Link>
        </h3>

        <h3>GIFTS</h3>
      </div>
      <div className="mobileNavbarBottom">
        {userDetails.length === 0 ? (
          <div className="mobileNavbarBottomButtons">
            <Link to="/customerloginpage">
              <button className="addToCartBtn">Login</button>
            </Link>
            {/* <Link to="/checkoutpage2">
              <button className="addToCartBtn">Sign Up</button>
            </Link> */}
          </div>
        ) : (
          <div className="mobileNavbarBottomButtons">
            <Link to="/deliverypage">
              <button className="addToCartBtn">Login</button>
            </Link>
            {/* <Link to="/deliverypage">
              <button className="addToCartBtn">Sign Up</button>
            </Link> */}
          </div>
        )}
        <h3>My Orders</h3>
        <h3>CONTACT US</h3>
        {/* <Link to="/contactdetails">
        </Link> */}
      </div>
    </div>
  );
}
