import React from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import { useState } from "react";
import { a16, a18, a20, a29, a30 } from "../../Api/RootApiPath";
import { useEffect } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { InfinitySpin } from "react-loader-spinner";
import { AiOutlineClose } from "react-icons/ai";

export default function AdminAddBox() {
  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const [metalName, setMetalName] = useState("");
  const [boxName, setBoxName] = useState("");
  const [productName, setProductName] = useState("");
  const [emptyWeight, setEmptyWeight] = useState("");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [boxesData, setBoxesData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [productInUpdate, setProductInUpdate] = useState([]);

  useEffect(() => {
    fetch(a18)
      .then((res) => res.json())
      .then((data) => setCategories(data.data));
  }, []);
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, [showForm]);
  const fetchAllBoxes = async () => {
    await fetch(a30)
      .then((res) => res.json())
      .then((data) => setBoxesData(data.data));
    console.log(boxesData);
  };

  useEffect(() => {
    fetchAllBoxes();
  }, [showForm]);

  useEffect(() => {
    fetch(a20)
      .then((res) => res.json())
      .then((data) => setProducts(data.data));
    // console.log(products);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // const formData = new FormData();

    // formData.append("MetalName", metalName);
    // formData.append("BoxName", boxName);
    // formData.append("ProductName", productName);
    // formData.append("EmptyWeight", parseInt(emptyWeight));
    // setCategory_id(parseInt(category_id));
    const formData = {
      MetalName: metalName,
      BoxName: boxName,
      ProductName: productName,
      //   EmptyWeight: parseInt(emptyWeight),
      EmptyWeight: emptyWeight,
    };

    try {
      const response = await fetch(a29, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: formData,
        body: JSON.stringify(formData),
      });
      console.log(formData);
      const data = await response.json();
      if (response.status === "Success") {
        setShowForm(false);
      } else {
        setShowForm(false);
        // alert("Box Type Added");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, property) => {
    const updatedProduct = {
      ...productInUpdate,
      [property]: e.target.value,
    };

    setProductInUpdate(updatedProduct);
  };
  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        {showForm ? (
          <div
            style={{ flexDirection: "column" }}
            className="adminAddCategoryMainbox"
          >
            <button
              style={{
                width: "200px",
                alignSelf: "flex-start",
                margin: "2rem",
                marginBottom: "0",
              }}
              onClick={() => {
                setShowForm(!showForm);
              }}
            >
              Back To Boxes List
            </button>
            <div className="adminAddCategoryMainbox">
              <form onSubmit={handleSubmit}>
                <h3>ADD BOX</h3>
                <label htmlFor="metalName">Category:</label>
                <select
                  required="required"
                  id="metalName"
                  value={metalName}
                  onChange={(e) => setMetalName(e.target.value)}
                >
                  <option value="">Select an option</option>
                  {categories.map((x) => {
                    return <option value={x.name}>{x.name}</option>;
                  })}
                </select>
                <label htmlFor="metalName">Product:</label>
                <select
                  required="required"
                  id="metalName"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                >
                  <option value="">Select an option</option>
                  {products.map((x) => {
                    return (
                      <option value={x.productTitle}>{x.productTitle}</option>
                    );
                  })}
                </select>
                <label htmlFor="boxName">Box Name:</label>
                <input
                  required="required"
                  type="text"
                  id="boxName"
                  value={boxName}
                  onChange={(e) => setBoxName(e.target.value)}
                />
                <label htmlFor="emptyWeight">Empty Weight:</label>
                <input
                  required="required"
                  type="text"
                  id="emptyWeight"
                  value={emptyWeight}
                  onChange={(e) => setEmptyWeight(e.target.value)}
                />
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        ) : (
          <div
            style={{ flexDirection: "column" }}
            className="adminAddCategoryMainbox"
          >
            <button
              style={{
                width: "200px",
                alignSelf: "flex-end",
                margin: "2rem",
                marginBottom: "0",
              }}
              onClick={() => {
                setShowForm(!showForm);
                // setProductTypeData("");
              }}
            >
              Add New Box
            </button>
            <div className="adminAddCategoryMainbox">
              <form style={{ width: "100%" }}>
                <h3 style={{ width: "100%" }}>Boxes</h3>
                <table
                  className="allCustomersTable"
                  style={{ width: "90%", margin: "1rem 0rem" }}
                >
                  <thead>
                    <tr style={{ paddingBottom: "1rem" }}>
                      <th>Id</th>
                      <th>Box Name</th>
                      <th>Category</th>
                      <th>Product Name</th>
                      <th>Empty Weight</th>
                    </tr>
                  </thead>

                  <tbody data-aos="zoom-in">
                    {boxesData.map((x) => (
                      <tr key={x.id}>
                        <td>{x.id}</td>
                        <td>{x.boxName}</td>
                        <td>{x.metalName}</td>
                        <td>{x.productName}</td>
                        <td>{x.emptyWeight}</td>
                        <td>
                          <AiOutlineEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setPopup(true);
                              setProductInUpdate(x);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
