import React from "react";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { RxCross2, RxCheck } from "react-icons/rx";
import { AiOutlineEdit } from "react-icons/ai";
import { renderToString } from "react-dom/server";
import { useEffect } from "react";
import { userLoggedIn } from "../../../redux/action/Actions";
import { useNavigate, useParams } from "react-router-dom";
import { allStateList } from "../../Api/StateList";
import { a4, a40, a5 } from "../../Api/RootApiPath";
import CheckOutPageNewSummaryCard from "./CheckOutPageNewSummaryCard";
import visaLogo from "../../Images/paymentOptions/visaLogo.png";
import masterCardLogo from "../../Images/paymentOptions/mastercardLogo.jpg";
import emiLogo from "../../Images/paymentOptions/emiLogo.png";
import rupayLogo from "../../Images/paymentOptions/rupayLogo.png";
import upiLogo from "../../Images/paymentOptions/UpiLogo.jpg";

export default function CheckOutPageNew() {
  const allStates = useSelector((state) => state);
  const userDetails = allStates.reducer4;
  const { type } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [perAdd, setPerAdd] = useState("");
  const [town, setTown] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [state, setState] = useState("");
  const [currAdd, setCurrAdd] = useState("");
  const [currTown, setCurrTown] = useState("");
  const [currPinCode, setCurrPinCode] = useState("");
  const [currState, setCurrState] = useState("");
  const [mobileChanged, setMobileChanged] = useState(false);
  const [firstNameChanged, setFirstNameChanged] = useState(false);
  const [lastNameChanged, setLastNameChanged] = useState(false);
  const [perAddChanged, setPerAddChanged] = useState(false);
  const [townChanged, setTownChanged] = useState(false);
  const [pinCodeChanged, setPinCodeChanged] = useState(false);
  const [stateChanged, setStateChanged] = useState(false);
  const [currAddChanged, setCurrAddChanged] = useState(false);
  const [currTownChanged, setCurrTownChanged] = useState(false);
  const [currPinCodeChanged, setCurrPinCodeChanged] = useState(false);
  const [currStateChanged, setCurrStateChanged] = useState(false);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [dayChanged, setDayChanged] = useState(false);
  const [monthChanged, setMonthChanged] = useState(false);
  const [yearChanged, setYearChanged] = useState(false);
  const [allUserDetails, setAllUserDetails] = useState(false);
  const [allBillingDetails, setAllBillingDetails] = useState(false);
  const [copyBillingAddress, setCopyBillingAddress] = useState(false);
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");

  // const [openBox, setOpenBox] = useState("MyInfo");
  // const [openBox, setOpenBox] = useState("BillingAddress");
  const [openBox, setOpenBox] = useState("MyInfo");

  console.log(userDetails, "state");
  const navigate = useNavigate();

  useEffect(() => {
    if (type === "n0") {
      setAllUserDetails(false);
    } else if (type === "o2") {
      const { firstName, lastName, mobile, perAddStreet, perAddPinCode } =
        userDetails[0];
      if (
        firstName &&
        firstName.length > 2 &&
        lastName &&
        lastName.length > 2 &&
        mobile &&
        mobile.length > 12 &&
        perAddStreet &&
        perAddStreet.length > 7 &&
        perAddPinCode &&
        perAddPinCode.length >= 5
      ) {
        setOpenBox("DeliveryAddress");
        setAllUserDetails(true);
        setAllBillingDetails(true);
      }
    }
  }, [type, userDetails]);

  // useEffect(() => {
  //   if (type === "n0") {
  //     setAllUserDetails(false);
  //   } else if (type === "o2") {
  //     if (
  //       userDetails[0].firstName.length > 2 &&
  //       userDetails[0].lastName.length > 2 &&
  //       userDetails[0].mobile.length > 8 &&
  //       userDetails[0].perAdd.length > 7 &&
  //       userDetails[0].pinCode.length >= 5
  //     ) {
  //       setOpenBox("DeliveryAddress");
  //       setAllUserDetails(true);
  //       setAllBillingDetails(true);
  //     }
  //   }
  // }, [type]);
  const dispatch = useDispatch();
  const handleDayChange = (e) => {
    const input = e.target.value;
    // Validate day input
    if (
      input === "" ||
      (input.match(/^[0-9]{1,2}$/) && parseInt(input) <= 31)
    ) {
      setDay(input);
      handleBirthDayChange(input, month, year);
      setDayChanged(true);
    }
  };
  // const changeOpenBox = () => {
  //   if (userDetails[0].password !== "" && type === "o2") {
  //     setOpenBox("DeliveryAddress");
  //   } else null;
  // };
  // useEffect(() => {
  //   changeOpenBox();
  // }, []);
  const handleMonthChange = (e) => {
    const input = e.target.value;
    // Validate month input
    if (
      input === "" ||
      (input.match(/^[0-9]{1,2}$/) && parseInt(input) <= 12)
    ) {
      setMonth(input);
      handleBirthDayChange(day, input, year);
      setMonthChanged(true);
    }
  };
  const handleYearChange = (e) => {
    const input = e.target.value;
    // Validate year input
    if (
      input === "" ||
      (input.match(/^[0-9]{1,4}$/) &&
        parseInt(input) <= new Date().getFullYear())
    ) {
      setYear(input);
      setYearChanged(true);
      handleBirthDayChange(day, month, input);
    }
  };

  // isDateOfBirthIncomplete = !day || !month || !year;
  let isDateOfBirthIncomplete = !day || !month || !year;
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = () => {
    // Your password validation logic here
    // Return true if the password is acceptable, false otherwise
    // For example, you can use the regex pattern as mentioned in the previous answer
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()]{8,}$/;
    return regex.test(password);
  };
  const validateMobile = () => {
    // Your mobile number validation logic here
    // Return true if the mobile number is acceptable, false otherwise
    // For example, you can use the following regex pattern
    const regex = /^[0-9]{10}$/;
    return regex.test(mobile);
  };

  const iconString = encodeURIComponent(renderToString(<RxCheck />));
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };

  const editPincode = async (parameter, formValue) => {
    let updatedUserDetails = [];
    console.log(userDetails[0].id, parameter, parseInt(formValue));
    const formData = {
      Id: userDetails[0].id,
      [parameter]: parseInt(formValue),
    };
    console.log(formData);
    try {
      const response = await fetch(a5, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      if (data.status == "success") {
        console.log(data);
        checkBillingClass();
        // updatedUserDetails = [data.data];
        // loggedIn(updatedUserDetails);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e, property) => {
    const updatedUserDetails = userDetails.map((user) => {
      return {
        ...user,
        [property]: e.target.value,
      };
    });

    loggedIn(updatedUserDetails);
  };
  const handleBirthDayChange = (day, month, year) => {
    const updatedUserDetails = userDetails.map((user) => {
      return {
        ...user,
        dob: `${day}/${month}/${year}`,
      };
    });

    loggedIn(updatedUserDetails);
  };
  // const handlePerAdd = (e) => {
  //   const input = e.target.value;
  //   handlePerAddChange(input, town, state);
  // };
  // const handleTown = (e) => {
  //   const input = e.target.value;
  //   handlePerAddChange(perAdd, input, state);
  // };
  // const handleState = (e) => {
  //   const input = e.target.value;
  //   handlePerAddChange(perAdd, town, input);
  // };
  // const handlePerAddChange = (perAdd, town, state) => {
  //   const updatedUserDetails = userDetails.map((user) => {
  //     return {
  //       ...user,
  //       perAdd: `${perAdd}, ${town}, ${state}`,
  //     };
  //   });

  //   loggedIn(updatedUserDetails);
  // };

  // const handleCurrAdd = (e) => {
  //   const input = e.target.value;
  //   handleCurrAddChange(input, currTown, currState, currPinCode);
  // };
  // const handleCurrTown = (e) => {
  //   const input = e.target.value;
  //   handleCurrAddChange(currAdd, input, currState, currPinCode);
  // };
  // const handleCurrState = (e) => {
  //   const input = e.target.value;
  //   handleCurrAddChange(currAdd, currTown, input, currPinCode);
  // };
  // const handleCurrPinCode = (e) => {
  //   const input = e.target.value;
  //   handleCurrAddChange(currAdd, currTown, currState, input);
  // };
  // const handleCurrAddChange = (currAdd, currTown, currState, currPinCode) => {
  //   const updatedUserDetails = userDetails.map((user) => {
  //     return {
  //       ...user,
  //       currAdd: `${currAdd}, ${currTown}, ${currState}, ${currPinCode}`,
  //     };
  //   });

  //   loggedIn(updatedUserDetails);
  // };

  const editMyInfoOld = async () => {
    let updatedUserDetails = [];
    const formData = {
      firstName: userDetails[0].firstName,
      lastName: userDetails[0].lastName,
      dob: userDetails[0].dob,
    };
    try {
      const response = await fetch(a40, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userDetails[0]),
      });
      const data = await response.json();
      console.log(data);
      updatedUserDetails = [data.data];
      loggedIn(updatedUserDetails);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  const addMyInfoNew = async () => {
    let updatedUserDetails = [];
    const formData = {
      firstName: userDetails[0].firstName,
      lastName: userDetails[0].lastName,
      email: userDetails[0].email,
      password: userDetails[0].password,
      dob: userDetails[0].dob,
      mobile: userDetails[0].mobile,
    };
    try {
      const response = await fetch(a4, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userDetails[0]),
      });
      const data = await response.json();
      console.log(data);
      updatedUserDetails = [data.data];
      loggedIn(updatedUserDetails);
      navigate("/checkoutpagenew/o2");
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  const checkClass = () => {
    let checkPasswordInput = document.getElementById("passwordInput");
    let firstNameInput = document.getElementById("firstNameInput");
    let lastNameInput = document.getElementById("lastNameInput");
    let dobInput = document.getElementById("dobInput");
    let mobileInput = document.getElementById("mobileInput");
    if (type === "n2") {
      if (
        checkPasswordInput.classList.contains("valid") &&
        firstNameInput.classList.contains("validBorder") &&
        lastNameInput.classList.contains("validBorder") &&
        dobInput.classList.contains("validBorder") &&
        mobileInput.classList.contains("validBorder")
      ) {
        console.log("all Correct");
        addMyInfoNew();
        setAllUserDetails(true);
        setOpenBox("BillingAddress");
      } else {
        if (!checkPasswordInput.classList.contains("valid")) {
          checkPasswordInput.classList.add("invalid");
        } else if (!firstNameInput.classList.contains("validBorder")) {
          firstNameInput.classList.add("invalidBorder");
          setFirstNameChanged(true);
        } else if (!lastNameInput.classList.contains("validBorder")) {
          lastNameInput.classList.add("invalidBorder");
          setLastNameChanged(true);
        } else if (!dobInput.classList.contains("validBorder")) {
          dobInput.classList.add("invalidBorder");
          setDayChanged(true);
        } else if (!mobileInput.classList.contains("validBorder")) {
          mobileInput.classList.add("invalidBorder");
          setMobileChanged(true);
        }
        console.log("something Missing");
      }
    } else if (type === "o2") {
      if (
        userDetails[0].firstName &&
        userDetails[0].lastName &&
        userDetails[0].mobile.length >= 8
      ) {
        editMyInfoOld();
        console.log("all OK");
        setAllUserDetails(true);
        setOpenBox("BillingAddress");
      } else {
        if (mobileInput.classList.contains("validBorder" || "")) {
          console.log("all not OK");
        } else {
          mobileInput.classList.add("invalid");
          console.log("all not ");
        }
      }
    }
  };
  const checkBillingClass = () => {
    let perAddInput = document.getElementById("perAddInput");
    let townInput = document.getElementById("townInput");
    let pinCodeInput = document.getElementById("pinCodeInput");
    let stateInput = document.getElementById("stateInput");
    if (type === "o2") {
      if (
        userDetails[0].perAddStreet.length >= 3 &&
        userDetails[0].perAddTown.length >= 2 &&
        userDetails[0].perAddState.length >= 2
        // userDetails[0].perAddPinCode.length >= 5
        // perAddInput.classList.contains("validBorder") &&
        // townInput.classList.contains("validBorder") &&
        // pinCodeInput.classList.contains("validBorder") &&
        // stateInput.classList.contains("validBorder")
      ) {
        setAllBillingDetails(true);
        setOpenBox("DeliveryAddress");

        editMyInfoOld();
      } else if (userDetails[0].perAddStreet.length <= 3) {
      }
    }
  };
  const checkDeliveryClass = () => {
    let currAddCheckBox = document.getElementById("currAddCheckBox").checked;
    let currAddInput = document.getElementById("currAddInput");
    let currTownInput = document.getElementById("currTownInput");
    let currPinCodeInput = document.getElementById("currPinCodeInput");
    let currStateInput = document.getElementById("currStateInput");
    if (type === "o2") {
      if (currAddCheckBox && userDetails[0].perAddStreet.length >= 3) {
        setOpenBox("PaymentInfo");
        editMyInfoOld();
        setShowDeliveryAddress(true);
      } else if (!currAddCheckBox && userDetails[0].currAddStreet < 3) {
        currAddInput.classList.add("invalid");
        setCurrAddChanged(true);
      } else if (!currAddCheckBox && userDetails[0].currAddTown < 3) {
        currTownInput.classList.add("invalid");
        setCurrTownChanged(true);
      } else if (!currAddCheckBox && userDetails[0].currAddPinCode < 5) {
        currPinCodeInput.classList.add("invalid");
        setCurrPinCodeChanged(true);
      } else if (!currAddCheckBox && userDetails[0].currAddState < 3) {
        currStateInput.classList.add("invalid");
        setCurrStateChanged(true);
      } else {
        setOpenBox("PaymentInfo");
        editMyInfoOld();
        setShowDeliveryAddress(true);
      }
    }
  };
  const copyAddress = () => {
    const updatedUserDetails = userDetails.map((user) => {
      return {
        ...user,
        currAddStreet: userDetails[0].perAddStreet,
        currAddTown: userDetails[0].perAddTown,
        currAddState: userDetails[0].perAddState,
        currAddPinCode: userDetails[0].perAddPinCode,
      };
    });

    loggedIn(updatedUserDetails);
  };
  useEffect(() => {
    if (copyBillingAddress) {
      setCurrAdd("");
      setCurrTown("");
      setCurrPinCode("");
      setCurrState("");
      copyAddress();
    }
  }, [copyBillingAddress]);

  // {handleInputChange(`${day / month / year}`, "dob")}
  let customerObject = {
    firstName: firstName,
    lastName: lastName,
    password: password,
    dob: `${day}/${month}/${year}`,
  };
  const radioButton = document.getElementById("radioButton");
  const handlePaymentOption = (e) => {
    const input = e.target.value;
    if (openBox === "PaymentInfo") {
      setSelectedPaymentOption(input);
    }
  };
  return (
    <div className="checkOutMainBox">
      <div className="headingBox">
        <Heading />
      </div>
      <div className="checkoutNewMainOuterBox">
        <h1 style={{ margin: "0" }}>Checkout</h1>
        <div
          className="checkoutNewMainInnerBox"
          style={{ position: "relative" }}
        >
          <div className="checkoutNewMainOuterLeftBox">
            <div className="checkoutNewMainDropdownButton">
              <div className="checkOutNewMainEditOptionBox">
                <h4>My Information</h4>
                {openBox === "MyInfo" ? null : (
                  <AiOutlineEdit
                    className={allUserDetails ? "valid" : "invalid"}
                    style={{ cursor: "pointer" }}
                    size={"25px"}
                    onClick={() => {
                      setOpenBox("MyInfo");
                    }}
                  />
                )}
              </div>
              <div className="checkoutNewAccountDetailsMainBtn">
                <label>Email</label>
                <p>{userDetails[0].email}</p>
                {openBox === "MyInfo" ? (
                  <>
                    {type === "n2" ? <label>Password</label> : null}
                    {type === "n2" ? (
                      <div
                        id="passwordInput"
                        className={`password-input ${
                          password && !validatePassword() ? "invalid" : ""
                        } ${password && validatePassword() ? "valid" : ""} ${
                          !password ? "blank" : ""
                        }`}
                      >
                        <input
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value),
                              handleInputChange(e, "password");
                          }}
                        />
                        <button
                          className="toggle-password-visibility"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? "HIDE" : "SHOW"}
                        </button>
                        <div className="password-requirements">
                          {password && password.length < 8 ? (
                            <p className="invalid">
                              8 characters <RxCross2 />
                            </p>
                          ) : (
                            <p className={password ? "valid" : ""}>
                              8 characters {password && <RxCheck />}
                            </p>
                          )}
                          {password && !/[a-z]/.test(password) ? (
                            <p className="invalid">
                              1 lowercase <RxCross2 />
                            </p>
                          ) : (
                            <p className={password ? "valid" : ""}>
                              1 lowercase {password && <RxCheck />}
                            </p>
                          )}
                          {password && !/[A-Z]/.test(password) ? (
                            <p className="invalid">
                              1 uppercase <RxCross2 />
                            </p>
                          ) : (
                            <p className={password ? "valid" : ""}>
                              1 uppercase {password && <RxCheck />}
                            </p>
                          )}
                          {password && !/\d/.test(password) ? (
                            <p className="invalid">
                              1 number <RxCross2 />
                            </p>
                          ) : (
                            <p className={password ? "valid" : ""}>
                              1 number {password && <RxCheck />}
                            </p>
                          )}
                        </div>
                      </div>
                    ) : null}
                    <div className="checkOutNameMainBox">
                      <label>First name</label>
                      <label>Last name</label>
                      <div className="checkOutInputBox">
                        <input
                          id="firstNameInput"
                          className={`checkOutInput ${
                            firstNameChanged && firstName === ""
                              ? "invalid"
                              : ""
                          } ${firstName !== "" ? "validBorder" : ""}`}
                          type="text"
                          value={userDetails[0].firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            handleInputChange(e, "firstName");
                            setFirstNameChanged(true);
                          }}
                        />
                        {firstName !== "" && (
                          <RxCheck className="checkMark" size="20px" />
                        )}
                        {firstName === "" && firstNameChanged && (
                          <p style={{ margin: "5px 0px" }} className="invalid">
                            Please enter a First name
                          </p>
                        )}
                      </div>
                      <div className="checkOutInputBox">
                        <input
                          id="lastNameInput"
                          className={`checkOutInput ${
                            lastName !== "" ? "validBorder" : ""
                          } ${
                            lastNameChanged && lastName === "" ? "invalid" : ""
                          }`}
                          type="text"
                          // value={lastName}
                          value={userDetails[0].lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                            handleInputChange(e, "lastName");
                            setLastNameChanged(true);
                          }}
                        />
                        {lastName !== "" && (
                          <RxCheck className="checkMark" size="20px" />
                        )}
                        {lastName === "" && lastNameChanged && (
                          <p style={{ margin: "5px 0px" }} className="invalid">
                            Please enter a Last name
                          </p>
                        )}
                      </div>
                    </div>
                    <label>Date of Birth</label>
                    <div
                      id="dobInput"
                      className={`dobInputContainer ${
                        !(dayChanged && monthChanged && yearChanged)
                          ? day === "" && month === "" && year === ""
                            ? ""
                            : "invalidBorder"
                          : isDateOfBirthIncomplete
                          ? "invalidBorder"
                          : "validBorder"
                      }`}
                    >
                      <input
                        type="text"
                        maxLength={2}
                        value={day}
                        onChange={handleDayChange}
                        placeholder="DD"
                      />
                      <span>/</span>
                      <input
                        type="text"
                        maxLength={2}
                        value={month}
                        onChange={handleMonthChange}
                        placeholder="MM"
                      />
                      <span>/</span>
                      <input
                        type="text"
                        maxLength={4}
                        value={year}
                        onChange={handleYearChange}
                        placeholder="YYYY"
                      />
                    </div>
                    {(!(dayChanged && monthChanged && yearChanged) ||
                      isDateOfBirthIncomplete) &&
                      (day !== "" || month !== "" || year !== "") && (
                        <p style={{ margin: "5px 0px" }} className="invalid">
                          Please enter a valid date of birth
                        </p>
                      )}
                    {day && month && year && (
                      <>
                        <p style={{ margin: "5px 0px" }}>
                          Date of Birth: {day}/{month}/{year}
                        </p>
                      </>
                    )}

                    <div className="checkOutInputBox">
                      <label>Phone number</label>
                      <input
                        id="mobileInput"
                        className={`checkOutInput ${
                          mobile && mobile.length >= 9 ? "validBorder" : ""
                        } ${
                          mobileChanged && mobile.length < 8
                            ? "invalidBorder"
                            : ""
                        }`}
                        maxLength={12}
                        value={userDetails[0].mobile}
                        onChange={(e) => {
                          setMobile(e.target.value);
                          handleInputChange(e, "mobile");
                          setMobileChanged(true);
                        }}
                        type="number"
                      />
                      {mobile !== "" && mobile.length >= 9 ? (
                        <RxCheck
                          style={{ top: "65%" }}
                          className="checkMark"
                          size="20px"
                        />
                      ) : null}
                      {mobile === "" && mobile.length < 8 && mobileChanged ? (
                        <p style={{ margin: "5px 0px" }} className="invalid">
                          Please enter valid number
                        </p>
                      ) : null}
                    </div>

                    <div className="checkoutNewButtonBox">
                      <button onClick={() => checkClass()}>Save</button>
                    </div>
                    <p
                      onClick={() => {
                        checkClass();
                      }}
                      style={{
                        textAlign: "center",
                        margin: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <RxCross2 style={{ marginRight: "10px" }} size={"20px"} />
                      Cancel
                    </p>
                  </>
                ) : null}
              </div>
            </div>
            <div className="checkoutNewMainDropdownButton">
              <div className="checkoutNewShippingAddressMainBtn">
                <div className="checkOutNewMainEditOptionBox">
                  <h4>Billing Address</h4>
                  {openBox === "BillingAddress" ? null : (
                    <AiOutlineEdit
                      className={allBillingDetails ? "valid" : "invalid"}
                      style={{ cursor: "pointer" }}
                      size={"25px"}
                      onClick={() => {
                        setOpenBox("BillingAddress");
                      }}
                    />
                  )}
                </div>
                {openBox !== "BillingAddress" ? (
                  <div style={{ marginTop: "20px" }}>
                    <p
                      style={{ margin: "5px 0" }}
                    >{`${userDetails[0].firstName} ${userDetails[0].lastName}`}</p>
                    <p>{userDetails[0].perAddStreet}</p>
                    <p style={{ margin: "5px 0" }}>
                      {userDetails[0].perAddTown}
                    </p>
                    <p style={{ margin: "5px 0" }}>
                      {userDetails[0].perAddState}
                    </p>
                    <p style={{ margin: "5px 0" }}>
                      {userDetails[0].perAddPinCode}
                    </p>
                  </div>
                ) : null}
                {openBox === "BillingAddress" ? (
                  <div>
                    <label style={{ fontWeight: "bold", margin: "20px 0" }}>
                      Enter your billing address
                    </label>
                    <p>Name</p>
                    <p>{`${userDetails[0].firstName} ${userDetails[0].lastName}`}</p>
                    <label>
                      Address <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <div className="checkOutInputBox">
                      <input
                        id="perAddInput"
                        className={`checkOutInput ${
                          perAddChanged && perAdd === "" ? "invalid" : ""
                        } ${perAdd !== "" ? "validBorder" : ""}`}
                        type="text"
                        value={userDetails[0].perAddStreet}
                        placeholder={userDetails[0].perAddStreet}
                        onChange={(e) => {
                          setPerAdd(e.target.value);
                          handleInputChange(e, "perAddStreet");
                          setPerAddChanged(true);
                        }}
                      />
                      {perAdd !== "" && (
                        <RxCheck
                          style={{ top: "35%" }}
                          className="checkMark"
                          size="20px"
                        />
                      )}
                      {perAdd === "" && perAddChanged && (
                        <p style={{ margin: "5px 0px" }} className="invalid">
                          Please enter an Address
                        </p>
                      )}
                      {!perAddChanged || perAdd !== "" ? (
                        <p style={{ margin: "5px 0px" }}>Street Address</p>
                      ) : null}
                    </div>
                    <div className="checkOutNameMainBox">
                      <label>
                        Town/City <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <label>
                        Pincode <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <div className="checkOutInputBox">
                        <input
                          id="townInput"
                          className={`checkOutInput ${
                            townChanged && town === "" ? "invalid" : ""
                          } ${town !== "" ? "validBorder" : ""}`}
                          type="text"
                          value={userDetails[0].perAddTown}
                          placeholder={userDetails[0].perAddTown}
                          onChange={(e) => {
                            setTown(e.target.value);
                            handleInputChange(e, "perAddTown");
                            setTownChanged(true);
                          }}
                        />
                        {town !== "" && (
                          <RxCheck
                            style={{ top: "35%" }}
                            className="checkMark"
                            size="20px"
                          />
                        )}
                        {town === "" && townChanged && (
                          <p style={{ margin: "5px 0px" }} className="invalid">
                            Enter a town/city
                          </p>
                        )}
                        {!townChanged || town !== "" ? (
                          <p style={{ margin: "5px 0px" }}>City/Town</p>
                        ) : null}
                      </div>
                      <div className="checkOutInputBox">
                        <input
                          id="pinCodeInput"
                          className={`checkOutInput ${
                            pinCode !== "" ? "validBorder" : ""
                          } ${
                            pinCodeChanged && pinCode === "" ? "invalid" : ""
                          }`}
                          type="number"
                          placeholder={userDetails[0].perAddPinCode}
                          value={userDetails[0].perAddPinCode}
                          onChange={(e) => {
                            setPinCode(e.target.value);
                            handleInputChange(e, "perAddPinCode");
                            setPinCodeChanged(true);
                          }}
                        />
                        {pinCode !== "" && (
                          <RxCheck
                            style={{ top: "35%" }}
                            className="checkMark"
                            size="20px"
                          />
                        )}
                        {pinCode === "" && pinCodeChanged && (
                          <p style={{ margin: "5px 0px" }} className="invalid">
                            Please enter a pincode (400001)
                          </p>
                        )}
                        {!pinCodeChanged || pinCode !== "" ? (
                          <p style={{ margin: "5px 0px" }}>
                            Enter your postcode. E.g. 400070
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "20px" }}
                      className="checkOutInputBox"
                    >
                      <label>
                        State <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <select
                        id="stateInput"
                        className={`checkOutInput ${
                          stateChanged && state === "" ? "invalid" : ""
                        } ${state !== "" ? "validBorder" : ""}`}
                        placeholder={userDetails[0].perAddState}
                        value={userDetails[0].perAddState}
                        onChange={(e) => {
                          setState(e.target.value);
                          handleInputChange(e, "perAddState");
                          setStateChanged(true);
                        }}
                      >
                        <option value="">Select a state</option>
                        {/* {allStateList.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))} */}
                        <option key={state} value="Maharashtra">
                          Maharashtra
                        </option>
                      </select>
                      {state !== "" && (
                        <RxCheck
                          style={{ top: "65%", right: "20px" }}
                          className="checkMark"
                          size="20px"
                        />
                      )}
                      {state === "" && stateChanged && (
                        <p style={{ margin: "5px 0px" }} className="invalid">
                          Please select your State
                        </p>
                      )}
                    </div>
                    <div className="checkoutNewButtonBox">
                      <button
                        onClick={() => {
                          checkBillingClass();
                        }}
                      >
                        Save
                      </button>
                    </div>
                    <p
                      onClick={() => {
                        checkBillingClass();
                      }}
                      style={{
                        textAlign: "center",
                        margin: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <RxCross2 style={{ marginRight: "10px" }} size={"20px"} />
                      Cancel
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="checkoutNewMainDropdownButton">
              <div className="checkoutNewPaymentInformationMainBtn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h4>Delivery Address</h4>
                  {showDeliveryAddress ? (
                    <AiOutlineEdit
                      className={showDeliveryAddress ? "valid" : "invalid"}
                      style={{ cursor: "pointer" }}
                      size={"25px"}
                      onClick={() => {
                        setOpenBox("DeliveryAddress"),
                          setShowDeliveryAddress(false);
                        setCopyBillingAddress(false);
                      }}
                    />
                  ) : null}
                </div>
                {openBox === "DeliveryAddress" ? (
                  <div>
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <input
                        id="currAddCheckBox"
                        checked={copyBillingAddress}
                        style={{ width: "20px", marginRight: "10px" }}
                        type="checkbox"
                        onChange={(e) =>
                          setCopyBillingAddress(e.target.checked)
                        }
                      />
                      Same as my billing address
                    </label>
                    {!copyBillingAddress ? (
                      <div>
                        <div className="checkOutInputBox">
                          <input
                            id="currAddInput"
                            className={`checkOutInput ${
                              currAddChanged && currAdd === "" ? "invalid" : ""
                            } ${currAdd !== "" ? "validBorder" : ""}`}
                            type="text"
                            placeholder={userDetails[0].currAddStreet}
                            value={userDetails[0].currAddStreet}
                            onChange={(e) => {
                              setCurrAdd(e.target.value);
                              handleInputChange(e, "currAddStreet");
                              setCurrAddChanged(true);
                            }}
                          />
                          {currAdd !== "" && (
                            <RxCheck
                              style={{ top: "35%" }}
                              className="checkMark"
                              size="20px"
                            />
                          )}
                          {currAdd === "" && currAddChanged && (
                            <p
                              style={{ margin: "5px 0px" }}
                              className="invalid"
                            >
                              Please enter an Address
                            </p>
                          )}
                          {!currAddChanged || currAdd !== "" ? (
                            <p style={{ margin: "5px 0px" }}>Street Address</p>
                          ) : null}
                        </div>
                        <div className="checkOutNameMainBox">
                          <label>
                            Town/City <sup style={{ color: "red" }}>*</sup>
                          </label>
                          <label>
                            Pincode <sup style={{ color: "red" }}>*</sup>
                          </label>
                          <div className="checkOutInputBox">
                            <input
                              id="currTownInput"
                              className={`checkOutInput ${
                                currTownChanged && currTown === ""
                                  ? "invalid"
                                  : ""
                              } ${currTown !== "" ? "validBorder" : ""}`}
                              type="text"
                              value={userDetails[0].currAddTown}
                              placeholder={userDetails[0].currAddTown}
                              onChange={(e) => {
                                setCurrTown(e.target.value);
                                handleInputChange(e, "currAddTown");
                                setCurrTownChanged(true);
                              }}
                            />
                            {currTown !== "" && (
                              <RxCheck
                                style={{ top: "35%" }}
                                className="checkMark"
                                size="20px"
                              />
                            )}
                            {currTown === "" && currTownChanged && (
                              <p
                                style={{ margin: "5px 0px" }}
                                className="invalid"
                              >
                                Enter a town/city
                              </p>
                            )}
                            {!currTownChanged || currTown !== "" ? (
                              <p style={{ margin: "5px 0px" }}>City/Town</p>
                            ) : null}
                          </div>
                          <div className="checkOutInputBox">
                            <input
                              id="currPinCodeInput"
                              className={`checkOutInput ${
                                currPinCode !== "" ? "validBorder" : ""
                              } ${
                                currPinCodeChanged && currPinCode === ""
                                  ? "invalid"
                                  : ""
                              }`}
                              type="number"
                              // value={lastName}
                              value={userDetails[0].currAddPinCode}
                              placeholder={userDetails[0].currAddPinCode}
                              onChange={(e) => {
                                setCurrPinCode(e.target.value);
                                handleInputChange(e, "currAddPinCode");
                                setCurrPinCodeChanged(true);
                              }}
                            />
                            {currPinCode !== "" && (
                              <RxCheck
                                style={{ top: "35%" }}
                                className="checkMark"
                                size="20px"
                              />
                            )}
                            {currPinCode === "" && currPinCodeChanged && (
                              <p
                                style={{ margin: "5px 0px" }}
                                className="invalid"
                              >
                                Please enter a pincode (400001)
                              </p>
                            )}
                            {!currPinCodeChanged || currPinCode !== "" ? (
                              <p style={{ margin: "5px 0px" }}>
                                Enter your postcode. E.g. 400070
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div
                          style={{ marginTop: "20px" }}
                          className="checkOutInputBox"
                        >
                          <label>
                            State <sup style={{ color: "red" }}>*</sup>
                          </label>
                          <select
                            id="currStateInput"
                            className={`checkOutInput ${
                              currStateChanged && currState === ""
                                ? "invalid"
                                : ""
                            } ${currState !== "" ? "validBorder" : ""}`}
                            value={userDetails[0].currAddState}
                            onChange={(e) => {
                              setCurrState(e.target.value);
                              handleInputChange(e, "currAddState");
                              setCurrStateChanged(true);
                            }}
                          >
                            <option value="">Select a state</option>
                            {/* {allStateList.map((state) => (
                              <option key={state} value={state}>
                                {state}
                              </option>
                            ))} */}
                            <option key={state} value="Maharashtra">
                              Maharashtra
                            </option>
                          </select>
                          {currState !== "" && (
                            <RxCheck
                              style={{ top: "65%", right: "20px" }}
                              className="checkMark"
                              size="20px"
                            />
                          )}
                          {currState === "" && currStateChanged && (
                            <p
                              style={{ margin: "5px 0px" }}
                              className="invalid"
                            >
                              Please select your State
                            </p>
                          )}
                        </div>
                      </div>
                    ) : null}
                    <div className="checkoutNewButtonBox">
                      <button onClick={() => checkDeliveryClass()}>Save</button>
                    </div>
                    <p
                      onClick={() => {
                        checkDeliveryClass();
                      }}
                      style={{
                        textAlign: "center",
                        margin: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <RxCross2 style={{ marginRight: "10px" }} size={"20px"} />
                      Cancel
                    </p>
                  </div>
                ) : null}
                {showDeliveryAddress ? (
                  <div>
                    <p
                      style={{ marginTop: "20px" }}
                    >{`${userDetails[0].firstName} ${userDetails[0].lastName}`}</p>
                    <p style={{ marginTop: "5px" }}>{userDetails[0].mobile}</p>
                    <p style={{ marginTop: "20px" }}>Standard Delivery</p>
                    <p style={{ marginTop: "5px" }}>
                      {userDetails[0].currAddStreet}
                    </p>
                    <p style={{ marginTop: "5px" }}>
                      {userDetails[0].currAddTown}
                    </p>
                    <p style={{ marginTop: "5px" }}>
                      {userDetails[0].currAddState}
                    </p>
                    <p style={{ marginTop: "5px" }}>
                      {userDetails[0].currAddPinCode}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="checkoutNewMainDropdownButton">
              <div className="checkoutNewPaymentInformationMainBtn">
                <h4>Payment</h4>
                {openBox === "PaymentInfo" ? (
                  <div>
                    <label style={{ marginTop: "20px" }}>
                      How would you like to pay?
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        style={{ width: "15px", marginRight: "10px" }}
                        type="radio"
                        id="radioButton"
                        value="online"
                        checked={selectedPaymentOption === "online"}
                        onChange={handlePaymentOption}
                      />
                      <p>Online Payment</p>
                    </div>
                    <div className="checkoutNewMainPaymentOptionsBox">
                      <img
                        className="checkoutNewMainPaymentOption"
                        src={visaLogo}
                        alt="Visa"
                      />
                      <img
                        className="checkoutNewMainPaymentOption"
                        src={masterCardLogo}
                        alt="Visa"
                      />
                      <img
                        className="checkoutNewMainPaymentOption"
                        src={emiLogo}
                        alt="Visa"
                      />
                      <img
                        className="checkoutNewMainPaymentOption"
                        src={rupayLogo}
                        alt="Visa"
                      />
                      <img
                        className="checkoutNewMainPaymentOption"
                        src={upiLogo}
                        alt="Visa"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            // style={{ position: "sticky", top: "200px" }}
            className="checkoutNewMainOuterRightBox"
          >
            <CheckOutPageNewSummaryCard paymentStatus={selectedPaymentOption} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
