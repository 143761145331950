import { useNavigate } from "react-router-dom";

//   Customer List All (GET)
export const a1 =
  // "https://sunshineteam.in/loyalstring/api_ls_customerall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/GetAllCustomer";
  "https://bhawani.loyalstring.co.in/api/CustomerDetails/GetAllCustomer";

//   Customer List As per Customer Login Id (POST)
export const a2 =
  // "https://sunshineteam.in/loyalstring/api_ls_customerasperloginid_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/CustomerByLoginId";
  "https://bhawani.loyalstring.co.in/api/CustomerDetails/CustomerByLoginId";
//   Customer Details as per Customer id (POST)
export const a3 =
  // "https://sunshineteam.in/loyalstring/api_ls_customerasperuserid_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/GetCustomerById";
  "https://bhawani.loyalstring.co.in/api/CustomerDetails/GetCustomerById";

//   Add Customer (POST)
export const a4 =
  // "https://sunshineteam.in/loyalstring/api_ls_customer_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/AddCustomer";
  "https://bhawani.loyalstring.co.in/api/CustomerDetails/AddCustomer";

//   Update Customer with AnyField except Customer_id and Data (POST)
export const a5 =
  // "https://sunshineteam.in/loyalstring/api_ls_customer_update.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/UpdateCustomer";
  "https://bhawani.loyalstring.co.in/api/CustomerDetails/UpdateCustomer";
//   Add Staff (POST)
export const a6 =
  // "https://sunshineteam.in/loyalstring/api_ls_staff_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Staff/AddStaff";
  "https://bhawani.loyalstring.co.in/api/Staff/AddStaff";
//   Add Category (POST)
export const a7 =
  // "https://sunshineteam.in/loyalstring/api_ls_category_insert.php  ";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertCategory";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/InsertCategory";
//   Add Product (POST)
export const a8 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertProduct";
  // "https://sunshineteam.in/loyalstring/api_ls_product_insert.php";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/InsertProduct";
// "https://bhawani.loyalstring.co.in/api/ProductMaster/InsertBulkProduct";

//   Add Wishlist (POST)
export const a9 =
  "https://sunshineteam.in/loyalstring/api_ls_wishlist_insert.php";

//   Add Order (POST)
export const a10 =
  // "https://sunshineteam.in/loyalstring/api_ls_order_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/InsertOrders";
  "https://bhawani.loyalstring.co.in/api/Orders/InsertOrders";

export const a11 =
  "https://sunshineteam.in/loyalstring/api_ls_rating_insert.php";

// Add Rating (POST)

export const a12 =
  // "https://sunshineteam.in/loyalstring/api_ls_rates_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/InsertRates";
  "https://bhawani.loyalstring.co.in/api/Orders/InsertRates";
// Add Rates to Category (POST)

export const a13 =
  // "https://sunshineteam.in/loyalstring/api_ls_ratestoday_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/ratestodaybycategory";
  "https://bhawani.loyalstring.co.in/api/Orders/ratestodaybycategory";

// Get Todays Rate as per Category (POST)
export const a14 =
  "https://sunshineteam.in/loyalstring/api_ls_ratesalldays_data.php";
// Get All Rates as per Category (POST)

export const a15 = "https://sunshineteam.in/loyalstring/api_ls_rating_data.php";
// Get Rating as per Product id (POST)

export const a16 =
  // "https://sunshineteam.in/loyalstring/api_ls_productall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetfetchAllProduct";
  // "https://bhawani.loyalstring.co.in/api/ProductMaster/GetfetchAllProduct";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/GetuniqiueAllProduct";
// Get All Product Information (GET)

export const a17 =
  // "https://sunshineteam.in/loyalstring/api_ls_productbyproductid_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchProductById";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/fetchProductById";
// Get Product Inforation as per Product Id (POST)

export const a18 =
  // "https://sunshineteam.in/loyalstring/api_ls_categoryall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchAllCategory";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/fetchAllCategory";
// Get All Categories (GET)

export const a19 =
  // "https://sunshineteam.in/loyalstring/api_ls_ratestodayallcategories_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/fetchTodayRates";
  "https://bhawani.loyalstring.co.in/api/Orders/fetchTodayRates";
// Get All Rates of today of all categories (GET)

export const a20 =
  // "https://sunshineteam.in/loyalstring/api_ls_producttypeall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchAllProductType";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/fetchAllProductType";
// Get All ProductTypes (GET)

export const a21 =
  // "https://sunshineteam.in/loyalstring/api_ls_producttype_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertProductType";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/InsertProductType";
// Insert ProductType (POST)

export const a22 =
  // "https://sunshineteam.in/loyalstring/api_ls_purityall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchAllPurity";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/fetchAllPurity";
// Get All Purity data (GET)

export const a23 =
  // "https://sunshineteam.in/loyalstring/api_ls_purity_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertPurity";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/InsertPurity";
// Insert Purity data (POST)

export const a24 =
  // "https://sunshineteam.in/loyalstring/api_ls_product_update.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/UpdateProduct";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/UpdateProduct";
// Update Product data (POST)

export const a25 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/UpdatePassword";
  "https://bhawani.loyalstring.co.in/api/CustomerDetails/UpdatePassword";
// Update Password

export const a26 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/UpdateImage";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/UpdateImage";
// Update Images

export const a27 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/AddPartyMaster";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/AddPartyMaster";
// Add Supplier Details

export const a28 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetAllPartyMaster";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/GetAllPartyMaster";
// Get Supplier Details

export const a29 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/AddBoxMaster";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/AddBoxMaster";
// Add Box Details

export const a30 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetAllBoxMaster";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/GetAllBoxMaster";
// Get Box Details

export const a31 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/BulkUpdateProduct";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/BulkUpdateProduct";
// "https://bhawani.loyalstring.co.in/api/ProductMaster/BulkEditProduct";
// Update Bulk Products

export const a32 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/AddCollection";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/AddCollection";
// Insert Collection

export const a33 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetAllCollection";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/GetAllCollection";
// Get Collection

export const a34 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/UpdateCollection";
  "https://bhawani.loyalstring.co.in/api/ProductMaster/UpdateCollection";
// Update Collection

export const a35 =
  "https://bhawani.loyalstring.co.in/api/ProductMaster/UpdateCategory";
// Update Category

export const a36 =
  "https://bhawani.loyalstring.co.in/api/ProductMaster/UpdateProductType";
// Update ProductType

export const a37 =
  "https://bhawani.loyalstring.co.in/api/ProductMaster/UpdatePurity";
// Update ProductType

export const a38 =
  "https://bhawani.loyalstring.co.in/api/Orders/fetchAllOrders";
// Update ProductType

export const a39 = "https://bhawani.loyalstring.co.in/api/Orders/UpdateOrders";
// Update ProductType

export const a40 =
  // "https://sunshineteam.in/loyalstring/api_ls_customer_update.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/UpdateCustomer";
  "https://bhawani.loyalstring.co.in/api/CustomerDetails/UpdateCustomers";
// Update Customer all fields

export const a41 =
  "https://bhawani.loyalstring.co.in/api/ProductMaster/GetfetchAllProduct";
// Get All Products

export const a42 =
  "https://bhawani.loyalstring.co.in/api/CustomerDetails/GetOtp";
// Get All Products

export const a43 = "https://bhawani.loyalstring.co.in/api/Device/GetAllRFID";
// Get All Barcode and Tid

export const a44 = "https://bhawani.loyalstring.co.in/api/Device/GetAllProduct";
// Get All Data from external

export const a45 = "https://bhawani.loyalstring.co.in/api/Orders/DownloadPDF";
// Download pdf with Img

export const a46 = "https://bhawani.loyalstring.co.in/api/Orders/PrintPDF";
//  Download pdf without Img

export const a47 =
  "https://bhawani.loyalstring.co.in/api/ProductMaster/deleteProduct";
// Delete Product with itemcode

export const a48 = "https://bhawani.loyalstring.co.in/api//Orders/CreateOrder";
// Create Order Multiple

export const a49 =
  "https://bhawani.loyalstring.co.in/api/Orders/CreateOrderItem";
// Create Order Items Multiple

export const a50 =
  "https://bhawani.loyalstring.co.in/api/Orders/AllOrderItemsByOrderId";
// Create Order Items Multiple

export const a51 =
  "https://bhawani.loyalstring.co.in/api/Orders/DownloadInvoice";
// Download Invoice

export const a52 =
  "https://bhawani.loyalstring.co.in/api/Orders/AllOrderItemsByOrderId";
// Get All Order Items

// export const s1 = `http://localhost:3000/demo/Images/`;
// export const s1 = `https://mkgharejewellers.com/demo/Images/`;
export const s1 = `https://mkgharejewellers.com/Images/`;
// export const s1 = `https://goldkarizma.com/demo/Images/`;
//For Local Images
// export const navigates = useNavigate();
// export const s2 = `http://localhost:3000/contactdetails`;
export const s2 = `https://mkgharejewellers.com/contactdetails`;
// export const s2 = `https://mgharejewellers.com/demo/contactdetails`;
// export const s2 = `https://goldkarizma.com/demo/contactdetails`;
// export const s3 = "https://product123.blob.core.windows.net/images";
export const s3 =
  // "https://product123.blob.core.windows.net/images";
  "https://bhawani.loyalstring.co.in/images";

export const paymentApi = "http://localhost:5000";
// export const paymentApi = "https://goldkarizma.com";
// export const paymentApi = "https://goldkarizma.com/nodjs";
