import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
} from "chart.js";

Chart.register(CategoryScale, LinearScale, BarController, BarElement);
export default function AdminBarChart() {
  const chartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Sales",
        data: [12, 19, 8, 15, 12, 9, 5, 14, 10, 9, 12, 14, 18],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Expenses",
        data: [5, 7, 9, 12, 8, 8, 9, 10, 12, 14, 13, 10],
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Define chart options
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Sales vs Expenses",
      },
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
        },
      },
      x: {
        type: "category",
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ], // your category labels
      },
    },
  };
  return (
    <div>
      <h2>Sales/Expenses</h2>
      <Bar
        style={{ width: "50rem", height: "5rem" }}
        data={chartData}
        options={chartOptions}
      />
    </div>
  );
}
