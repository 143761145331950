import React from "react";
import Heading from "../../Heading/Heading";
import { RxCross2, RxCheck } from "react-icons/rx";
import { FcGoogle } from "react-icons/fc";
import { GrFacebook } from "react-icons/gr";
import { useState } from "react";
import Footer from "../../Footer/Footer";
import { a4 } from "../../Api/RootApiPath";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLoggedIn } from "../../../redux/action/Actions";

export default function CustomerSignUpPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password2, setPassword2] = useState("");
  const [showPassword2, setShowPassword2] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameChanged, setFirstNameChanged] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameChanged, setLastNameChanged] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileChanged, setMobileChanged] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const validatePassword = () => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()]{8,}$/;
    return regex.test(password);
  };

  const signUp = () => {
    let checkPasswordInput = document.getElementById("passwordInput");
    let firstNameInput = document.getElementById("firstNameInput");
    let lastNameInput = document.getElementById("lastNameInput");
    let mobileInput = document.getElementById("mobileInput");
    if (
      password !== password2 &&
      checkPasswordInput.classList.contains("valid") &&
      firstNameInput.classList.contains("validBorder") &&
      lastNameInput.classList.contains("validBorder") &&
      mobileInput.classList.contains("validBorder")
    ) {
      setPasswordNotMatch(true);
    } else if (
      checkPasswordInput.classList.contains("valid") &&
      firstNameInput.classList.contains("validBorder") &&
      lastNameInput.classList.contains("validBorder") &&
      mobile.length < 8 &&
      password === password2
    ) {
      alert("Please enter a valid number");
      setMobile("");
    } else if (
      checkPasswordInput.classList.contains("valid") &&
      firstNameInput.classList.contains("validBorder") &&
      lastNameInput.classList.contains("validBorder") &&
      mobile.length > 8 &&
      password === password2
    ) {
      addNewUser();
    } else {
      alert("Please Complete the form");
    }
  };

  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  const addNewUser = async () => {
    let updatedUserDetails = [];
    const formData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      mobile: mobile,
    };
    try {
      const response = await fetch(a4, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.message === "email already exist") {
        alert("Email Already Exists, Try Login or use another email");
      } else if (orderItems.length === 0) {
        console.log(data);
        updatedUserDetails = [data.data];
        loggedIn(updatedUserDetails);
        navigate("/customerprofile");
      } else {
        updatedUserDetails = [data.data];
        loggedIn(updatedUserDetails);
        navigate("/checkoutpagenew/o2");
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  return (
    <div className="checkOutMainBox">
      <div className="headingBox">
        <Heading />
      </div>
      <div
        style={{ marginTop: 0, paddingTop: "50px" }}
        className="checkoutNewLoginMainBox"
      >
        <h2 style={{ marginBottom: "20px" }}>Register To Ghare's</h2>
        <div className="checkoutNewLoginFormBox" style={{ paddingTop: "0" }}>
          {/* <div className="loginSocialMediaOptionsBox">
            <button className="socialMediaOption">
              <FcGoogle size={"1.5rem"} style={{ marginRight: "8px" }} />
              <li style={{ color: "grey", listStyle: "none" }}>Google</li>
            </button>
            <button className="socialMediaOption">
              <GrFacebook size={"1.5rem"} style={{ marginRight: "8px" }} />
              <li style={{ color: "grey", listStyle: "none" }}>Facebook</li>
            </button>
          </div>
          <h4
            style={{
              textAlign: "center",
              marginTop: "2rem",
              marginBottom: "1rem",
              color: "grey",
            }}
          >
          Or
        </h4> */}

          <div className="customerSignUpBoxMain">
            <p>
              Mobile Number <sup style={{ color: "red" }}>*</sup>
            </p>
            <div className="checkOutInputBox">
              <input
                id="mobileInput"
                className={`checkOutInput ${
                  mobile && mobile.length >= 9 ? "validBorder" : ""
                } ${mobileChanged && mobile.length < 8 ? "invalidBorder" : ""}`}
                maxLength={12}
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                  setMobileChanged(true);
                }}
                type="number"
              />
              {mobile !== "" && mobile.length >= 9 ? (
                <RxCheck
                  style={{ top: "50%" }}
                  className="checkMark"
                  size="20px"
                />
              ) : null}
              {mobile === "" && mobile.length < 8 && mobileChanged ? (
                <p className="invalid">Please enter valid number</p>
              ) : null}
            </div>
            <p>
              Enter Email <sup style={{ color: "red" }}>*</sup>
            </p>
            <div
              id="emailInput"
              className={`password-input ${
                email && !validateEmail(email) ? "invalid" : ""
              } ${email && validateEmail(email) ? "valid" : ""} ${
                !email ? "blank" : ""
              }`}
            >
              <input
                id="emailInputField"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
              />
              {email && !validateEmail(email) && (
                <p style={{ margin: "0" }} className="invalid">
                  Please enter a valid email
                </p>
              )}
              {email && validateEmail(email) && (
                <RxCheck className="checkMark" size="20px" />
              )}
              {email && !validateEmail(email) && (
                <RxCross2
                  color="red"
                  style={{ top: "40%" }}
                  className="checkMark"
                  size="20px"
                />
              )}
            </div>
            <div className="customerSignUpNameBox">
              <p>
                First Name <sup style={{ color: "red" }}>*</sup>
              </p>
              <p>
                Last Name <sup style={{ color: "red" }}>*</sup>
              </p>
              <div className="checkOutInputBox">
                <input
                  id="firstNameInput"
                  className={`checkOutInput ${
                    firstNameChanged && firstName === "" ? "invalid" : ""
                  } ${firstName !== "" ? "validBorder" : ""}`}
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setFirstNameChanged(true);
                  }}
                />
                {firstName !== "" && (
                  <RxCheck className="checkMark" size="20px" />
                )}
                {firstName === "" && firstNameChanged && (
                  <p className="invalid">Please enter a First name</p>
                )}
              </div>
              <div className="checkOutInputBox">
                <input
                  id="lastNameInput"
                  className={`checkOutInput ${
                    lastName !== "" ? "validBorder" : ""
                  } ${lastNameChanged && lastName === "" ? "invalid" : ""}`}
                  type="text"
                  // value={lastName}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setLastNameChanged(true);
                  }}
                />
                {lastName !== "" && (
                  <RxCheck
                    style={firstNameChanged ? "" : { display: "none" }}
                    className="checkMark"
                    size="20px"
                  />
                )}
                {lastName === "" && lastNameChanged && (
                  <p className="invalid">Please enter a Last name</p>
                )}
              </div>
            </div>

            <p>
              Password <sup style={{ color: "red" }}>*</sup>
            </p>
            <div
              id="passwordInput"
              className={`password-input ${
                password && !validatePassword() ? "invalid" : ""
              } ${password && validatePassword() ? "valid" : ""} ${
                !password ? "blank" : ""
              }`}
            >
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value), setPasswordNotMatch(false);
                }}
              />
              <button
                className="toggle-password-visibility"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </button>
              <div className="password-requirements">
                {password && password.length < 8 ? (
                  <p style={{ marginInline: "5px" }} className="invalid">
                    8 characters <RxCross2 />
                  </p>
                ) : (
                  <p
                    style={{ marginInline: "5px" }}
                    className={password ? "valid" : ""}
                  >
                    8 characters {password && <RxCheck />}
                  </p>
                )}
                {password && !/[a-z]/.test(password) ? (
                  <p style={{ marginInline: "5px" }} className="invalid">
                    1 lowercase <RxCross2 />
                  </p>
                ) : (
                  <p
                    style={{ marginInline: "5px" }}
                    className={password ? "valid" : ""}
                  >
                    1 lowercase {password && <RxCheck />}
                  </p>
                )}
                {password && !/[A-Z]/.test(password) ? (
                  <p style={{ marginInline: "5px" }} className="invalid">
                    1 uppercase <RxCross2 />
                  </p>
                ) : (
                  <p
                    style={{ marginInline: "5px" }}
                    className={password ? "valid" : ""}
                  >
                    1 uppercase {password && <RxCheck />}
                  </p>
                )}
                {password && !/\d/.test(password) ? (
                  <p style={{ marginInline: "5px" }} className="invalid">
                    1 number <RxCross2 />
                  </p>
                ) : (
                  <p
                    style={{ marginInline: "5px" }}
                    className={password ? "valid" : ""}
                  >
                    1 number {password && <RxCheck />}
                  </p>
                )}
              </div>
            </div>
            <p>
              Confirm Password <sup style={{ color: "red" }}>*</sup>
            </p>
            <div className="password-input">
              <input
                style={{ border: "1px solid rgba(128, 128, 128, 0.5)" }}
                type={showPassword2 ? "text" : "password"}
                value={password2}
                onChange={(e) => {
                  setPassword2(e.target.value);
                  setPasswordNotMatch(false);
                }}
              />
              {passwordNotMatch ? (
                <p style={{ color: "red" }}>Sorry, the passwords dont match</p>
              ) : null}
              <button
                className="toggle-password-visibility"
                onClick={togglePasswordVisibility2}
              >
                {showPassword2 ? "HIDE" : "SHOW"}
              </button>
            </div>
          </div>
          <button
            className="checkoutNewLoginFormBoxButton"
            onClick={() => {
              signUp();
            }}
          >
            Sign Up
          </button>

          <p
            id="newToGhareLine"
            onClick={() => {
              navigate("/customerloginpage");
              // setPageFor("Register");
              // setOldUser("RegisterPage");
            }}
            style={{ textAlign: "center" }}
          >
            Already have an account, <span>Login Here</span>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
