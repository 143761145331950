import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Legend, Title } from "chart.js";

Chart.register(ArcElement, Legend, Title);

export default function AdminPieChart() {
  const chartData = {
    labels: ["Gold", "Silver", "Diamond"],
    datasets: [
      {
        data: [55, 30, 15],
        // backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
        backgroundColor: ["#FFCE56", "#36A2EB", "#FF6384"],
        hoverBackgroundColor: [
          "rgba(244, 252, 0,0.8)",
          "rgba(0, 202, 252,0.8)",
          "rgba(252, 0, 0,0.8)",
        ],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Pie Chart",
      },
    },
  };

  return (
    <div>
      <h2>Pie Chart</h2>
      <Pie data={chartData} options={chartOptions} />
    </div>
  );
}
