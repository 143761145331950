import React from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { a18, a20, a32, a33, a34 } from "../../Api/RootApiPath";
import { AiOutlineEdit } from "react-icons/ai";
import { InfinitySpin } from "react-loader-spinner";
import { AiOutlineClose } from "react-icons/ai";

export default function AdminAddCollection() {
  const [categories, setCategories] = useState([]);
  const [productTypeData, setProductTypeData] = useState([]);
  const [productName, setProductName] = useState([]);
  const [collectionName, setCollectionName] = useState("");
  const [slug, setSlug] = useState("");
  const [label, setLabel] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [collectionTypeData, setCollectionTypeData] = useState([]);
  const [category_id, setCategory_id] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [popUp, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productInUpdate, setProductInUpdate] = useState([]);

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const adminLoggedIn = allStates.reducer5;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, [showForm]);

  const fetchAllCollections = async () => {
    await fetch(a33)
      .then((res) => res.json())
      .then((data) => setCollectionTypeData(data.data));
  };

  useEffect(() => {
    fetchAllCollections();
  }, [showForm]);
  useEffect(() => {
    fetch(a18)
      .then((res) => res.json())
      .then((data) => setCategories(data.data));
  }, [showForm]);
  useEffect(() => {
    fetch(a20)
      .then((res) => res.json())
      .then((data) => setProductTypeData(data.data));
  }, [showForm]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      Category_id: category_id,
      ProductType: productName,
      Collection_Name: collectionName,
      Slug: slug,
      Label: label,
    };

    try {
      const response = await fetch(a32, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      setShowForm(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, property) => {
    const updatedProduct = {
      ...productInUpdate,
      [property]: e.target.value,
    };

    setProductInUpdate(updatedProduct);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      Id: productInUpdate.id,

      Category_id: productInUpdate.category_id,
      ProductType: productInUpdate.productType,
      Collection_Name: productInUpdate.collection_Name,
      // HSNCode: hsnCode,
      Slug: productInUpdate.slug,
      Label: productInUpdate.label,
      // HsnCode: productInUpdate.hsnCode,
      // Description: productInUpdate.description,
      // Entryby_Staff_id: Entryby_Staff_id,
      // Id: productInUpdate.id,
      // Name: productInUpdate.name,
      // ParentsCategory: productInUpdate.parentsCategory,
      // Description: productInUpdate.description,
      // Slug: productInUpdate.slug,
      // Label: productInUpdate.label,
      // Entryby_Staff_id: Entryby_Staff_id,
    };

    try {
      const response = await fetch(a34, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      // alert("updated");
      setLoading(false);
      fetchAllCollections();
      setProductInUpdate([]);
      setPopup(false);
    } catch (error) {
      console.error(error);
    }
  };
  const filteredProducts = productTypeData.filter(
    (product) => product.category_id == category_id
  );
  const filteredProducts2 = productTypeData.filter(
    (product) => product.category_id == productInUpdate.category_id
  );

  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        {showForm ? (
          <div
            style={{ flexDirection: "column" }}
            className="adminAddCategoryMainbox"
          >
            <button
              style={{
                width: "200px",
                alignSelf: "flex-start",
                margin: "2rem",
                marginBottom: "0",
              }}
              onClick={() => {
                setShowForm(!showForm);
              }}
            >
              Back To Collection List
            </button>
            <div className="adminAddCategoryMainbox">
              <form onSubmit={handleSubmit}>
                <h3>ADD COLLECTION</h3>
                <label htmlFor="name">Categories:</label>
                <select
                  required="required"
                  value={category_id}
                  onChange={(e) => setCategory_id(e.target.value)}
                >
                  <option value="">Select an option</option>
                  {categories.map((x) => {
                    return <option value={parseInt(x.id)}>{x.name}</option>;
                  })}
                </select>

                <label htmlFor="product">Product:</label>
                <select
                  id="product"
                  required="required"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                >
                  <option value="">Select an option</option>
                  {filteredProducts.map((product) => (
                    <option
                      key={product.productTitle}
                      value={product.productTitle}
                    >
                      {product.productTitle}
                    </option>
                  ))}
                </select>

                <label htmlFor="slug">Collection Name:</label>
                <input
                  required="required"
                  type="text"
                  id="slug"
                  value={collectionName}
                  onChange={(e) => setCollectionName(e.target.value)}
                />

                <label htmlFor="slug">Slug:</label>
                <input
                  type="text"
                  id="slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />

                <label htmlFor="hsn">Label:</label>
                <input
                  required="required"
                  type="text"
                  id="hsn"
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                />

                <button type="submit">Submit</button>
              </form>
            </div>

            {/* <input type="submit" name="feessubmit" value="Submit" /> */}
          </div>
        ) : (
          <div
            style={{ flexDirection: "column" }}
            className="adminAddCategoryMainbox"
          >
            <button
              style={{
                width: "200px",
                alignSelf: "flex-end",
                margin: "2rem",
                marginBottom: "0",
              }}
              onClick={() => {
                setShowForm(!showForm);
                // setCollectionTypeData("");
              }}
            >
              Add New Collection
            </button>
            <div className="adminAddCategoryMainbox">
              <form style={{ width: "100%" }}>
                <h3 style={{ width: "100%" }}>Collections</h3>
                <table
                  className="allCustomersTable"
                  style={{ width: "90%", margin: "1rem 0rem" }}
                >
                  <thead>
                    <tr style={{ paddingBottom: "1rem" }}>
                      <th>Id</th>
                      <th>Category</th>
                      <th>Product Name</th>
                      <th>Collection Name</th>
                      <th>Slug</th>
                      <th>Label</th>
                    </tr>
                  </thead>

                  <tbody data-aos="zoom-in">
                    {collectionTypeData.map((x) => (
                      <tr key={x.id}>
                        <td>{x.id}</td>
                        <td>{x.category_id}</td>
                        <td>{x.productType}</td>
                        <td>{x.collection_Name}</td>
                        <td>{x.slug}</td>
                        <td>{x.label}</td>
                        <td>
                          <AiOutlineEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setPopup(true);
                              setProductInUpdate(x);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>
              <div
                style={{
                  height: "auto",
                }}
                className={
                  popUp == true
                    ? "updateAccountDetailsPopupMainBox editCategoryPopBox"
                    : "none"
                }
              >
                <div
                  className="updateAccountDetailsPopupCloseBtn"
                  style={{ margin: "0.5rem" }}
                >
                  <AiOutlineClose
                    size={"3rem"}
                    onClick={() => {
                      setPopup(false);
                      // setShowEdit(!showEdit);
                    }}
                  />
                </div>
                <div
                  style={{ height: "100px", marginBottom: "1rem" }}
                  className={loading == true ? "loading" : "none"}
                >
                  <InfinitySpin
                    className={loading == true ? "loading" : "none"}
                    width="150"
                    color="#4fa94d"
                  />
                </div>
                <div
                  className="editCategoryFormBox"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <form
                    style={{ marginTop: "0", paddingTop: 0 }}
                    onSubmit={handleEdit}
                  >
                    <label htmlFor="category">Category:</label>
                    <select
                      id="category_id"
                      onChange={(e) => handleInputChange(e, "category_id")}
                      placeholder={productInUpdate.category_id}
                    >
                      <option value="">Select an option</option>
                      {categories.map((x) => {
                        return <option value={parseInt(x.id)}>{x.name}</option>;
                      })}
                    </select>
                    <label htmlFor="product">Product:</label>
                    <select
                      id="product"
                      placeholder={productInUpdate.productType}
                      onChange={(e) => handleInputChange(e, "productType")}
                    >
                      <option value="">Select an option</option>
                      {filteredProducts2.map((product) => (
                        <option
                          key={product.productTitle}
                          value={product.productTitle}
                        >
                          {product.productTitle}
                        </option>
                      ))}
                    </select>

                    <label htmlFor="collection_Name">Collection Name:</label>
                    <input
                      type="text"
                      id="collection_Name"
                      placeholder={productInUpdate.collection_Name}
                      onChange={(e) => handleInputChange(e, "collection_Name")}
                    />
                    <label htmlFor="slug">Slug</label>
                    <input
                      type="text"
                      id="slug"
                      placeholder={productInUpdate.slug}
                      onChange={(e) => handleInputChange(e, "slug")}
                    />
                    <label htmlFor="label">Label</label>
                    <input
                      type="text"
                      id="label"
                      placeholder={productInUpdate.label}
                      onChange={(e) => handleInputChange(e, "label")}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "2fr",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button type="submit" style={{ width: "100%" }}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              onClick={() => setPopup(false)}
              className={popUp === true ? "new" : "new2"}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
}
