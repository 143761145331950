import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BiChevronRight, BiChevronDown } from "react-icons/bi";
import { useSelector } from "react-redux";
const Dropdown = ({ categories }) => {
  const [activeCategory, setActiveCategory] = useState(null);

  const toggleCategory = (category) => {
    setActiveCategory((prevCategory) =>
      prevCategory === category ? null : category
    );
  };
  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  return (
    <div className="dropdown-container sidebar">
      {categories.map((category) => (
        <div key={category.id} className="category-item">
          <li
            className={`category-button ${
              activeCategory === category ? "active" : ""
            }`}
            onClick={() => toggleCategory(category)}
          >
            {category.name}
            {/* changes */}

            {activeCategory === category ? (
              <BiChevronDown size={"1.2rem"} className="sidebarDropdownIcon" />
            ) : (
              <BiChevronRight size={"1.2rem"} className="sidebarDropdownIcon" />
            )}
            {/* changes */}
          </li>

          <div
            className={`category-list-container ${
              activeCategory === category ? "open" : ""
            }`}
          >
            <ul className="category-list">
              {category.items.map((item) => (
                <Link to={item.linkto}>
                  <li key={item.id}>{item.name}</li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Dropdown;
