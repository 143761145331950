import React from "react";
import "../PagesStyles/ProductPage.css";
import { useState, useEffect, useRef } from "react";
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { IoShuffleSharp } from "react-icons/io5";
import { HiOutlineMagnifyingGlassPlus } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { a16, s1, s3 } from "../../Api/RootApiPath";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper";

export default function CustomerFavourites({ Product_id }) {
  const [data, setData] = useState([]);
  // const allProducts = "https://fakestoreapi.com/products";
  useEffect(() => {
    fetch(a16)
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
        setData(response.data);
        // setOlddata(response);
        // console.log(response.data);
      });
  }, []);
  let allImages = [];
  allImages = data.map((x) => x.images.split(","));
  // let newArray = data.filter((x) => x.Product_id != 4);
  return (
    <div className="ProductDetailsBottomBox">
      <h1>Customer Favourites</h1>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        // pagination={{
        //   clickable: true,
        // }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination, Autoplay]}
      >
        <div className="comments">
          {/* {data.map((x, index) => { */}
          {data.map((x) => {
            {
              allImages = x.images.split(",");
            }
            return (
              <SwiperSlide className="commentsSlide">
                <div className="container">
                  <Link to={`/productpage/${x.id}`}>
                    <img
                      className="productsImages"
                      // src={`${s1}${x.imageList1}`}
                      src={`${s3}/${allImages[0]}`}
                      alt="Products Images"
                      onClick={() => window.scrollTo(0, 0)}
                    />
                  </Link>
                  {/* <img className="productsImages" src={x.product_Image} />; */}
                  {/* <h3>{x.product_Name}</h3> */}
                  {/* {console.log(x)} */}
                  {/* <div className="overlay">
                    <div className="content">
                      <AiOutlineShoppingCart
                        size={20}
                        className="overlayContentItems"
                      />
                      <IoShuffleSharp
                        size={20}
                        className="overlayContentItems"
                      />
                      <HiOutlineMagnifyingGlassPlus
                        size={20}
                        className="overlayContentItems"
                      />
                      <AiOutlineHeart
                        size={20}
                        className="overlayContentItems"
                      />
                    </div>
                  </div> */}
                </div>
              </SwiperSlide>
            );
          })}
        </div>
      </Swiper>
    </div>
  );
}
