import React, { useState, useEffect } from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { a12, a14, a22, a37 } from "../../Api/RootApiPath";
import { a19 } from "../../Api/RootApiPath";
import { InfinitySpin } from "react-loader-spinner";
import { useSelector } from "react-redux";

export default function AdminDailyRates() {
  const [categoryLabel, setCategoryLabel] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [purity, setPurity] = useState("");
  const [inputValues, setInputValues] = useState({});

  const [purityData, setPurityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  useEffect(() => {
    fetchAllPurity();
  }, []);
  const fetchAllPurity = async () => {
    await fetch(a22)
      .then((res) => res.json())
      .then((data) => setPurityData(data.data));
    console.log(purityData);
  };
  console.log(purityData);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleUpdate = (id, name, purity, label, value) => {
    setLoading(true);
    const payload = {
      Id: id,
      Category: name,
      Purity: purity,
      Label: label,
      TodaysRate: value,
    };

    // Make the API request to update the data
    // Replace 'updateApiUrl' with the actual API endpoint for updating the data
    fetch(a37, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the API if needed
        console.log(data);
        setInputValues({ ...inputValues, [id]: value });
        setLoading(false);
      })
      .catch((error) => {
        // Handle any errors that occur during the API request
        console.error(error);
      });
  };

  return (
    <div>
      <div className="adminHomeMainBox">
        <div className="navBox">
          <AdminNavbar />
        </div>
        <div
          className={
            sidebarState
              ? "adminContentbox"
              : "adminContentbox adminContentbox2"
          }
        >
          <div
            style={{ height: "100px", marginBottom: "1rem" }}
            className={loading == true ? "loading" : "none"}
          >
            <InfinitySpin
              className={loading == true ? "loading" : "none"}
              width="150"
              color="#4fa94d"
            />
          </div>
          <h3 style={{ margin: "0.5rem 0" }}>Add Todays Rates</h3>
          <div className="dailyRateAddMainBox">
            <div className="adminAddCategoryMainbox dailyRateFormTableBody">
              <div className="adminDailyRateTableBox">
                <table>
                  <thead>
                    <tr>
                      <th>Category Label</th>
                      <th>Purity</th>
                      <th>Todays Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purityData.map((x) => {
                      return (
                        <tr>
                          <td>{x.category}</td>
                          <td>{x.label}</td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0.5rem 0",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "100px" }}
                              type="number"
                              value={inputValues[x.id] || ""}
                              placeholder={x.todaysRate}
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  [x.id]: e.target.value,
                                })
                              }
                            />
                            <button
                              style={{
                                marginLeft: "0",
                                height: "30px",
                                fontSize: "12px",
                                width: "56px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={() =>
                                handleUpdate(
                                  x.id,
                                  x.category,
                                  x.purity,
                                  x.label,
                                  inputValues[x.id]
                                )
                              }
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
