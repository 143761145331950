import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { a2 } from "../../Api/RootApiPath";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import { userLoggedIn } from "../../../redux/action/Actions";
import { useDispatch } from "react-redux";

export default function LoginPage() {
  const [customer, setCustomer] = useState("");
  const [username, setUsername] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  const navigate = useNavigate();

  return function LoginSignup() {
    useEffect(() => {
      handleSubmit();
    }, [customerId]);
    const handleSubmit = async (e) => {
      e.preventDefault();

      if (password1 !== password2) {
        alert("Sorry, the passwords do not match.");
        return;
      }

      setPassword(password1);
      const formData = new FormData();
      formData.append("Customer_login_id", username);
      try {
        const response = await fetch(a2, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        if (data.status == "success") {
          const passwordRcvd = data.data.pop().Password;
          alert(passwordRcvd);

          // const customerUpdate = data.data[0];
          // setCustomer(customerUpdate);
          // alert("Successfully Logged In..");
          // window.scrollTo(0, 0);
          // navigate(`/paymentpage?customerId=${customerId}`);
        } else {
          console.error(data.error);
        }
      } catch (error) {
        console.error(error);
      }
    };
    console.log(username);
    return (
      <div>
        <div className="headingBox">
          <Heading />
        </div>
        <div className="accountDetailsMainBox">
          <div className="accountDetailsFormBox">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
              {/* <label>Set Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            /> */}
              <label>UserName:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label>Password:</label>
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="addToCartBtn accountDetailsBtn"
                //   onClick={() => check()}
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}
