import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { a5 } from "../../Api/RootApiPath";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
export default function SetPasswordPage() {
  const [customer, setCustomer] = useState("");
  const [username, setUsername] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  const navigate = useNavigate();

  useEffect(() => {
    handleSubmit();
  }, [customerId]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password1 !== password2) {
      alert("Sorry, the passwords do not match.");
      return;
    }

    setPassword(password1);
    const formData = new FormData();
    formData.append("Customer_id", customerId);
    // formData.append("FieldName", "Customer_login_id");
    // formData.append("FieldValue", username);
    formData.append("FieldName", "Password");
    formData.append("FieldValue", password1);
    try {
      const response = await fetch(a5, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status == "success") {
        // const customerUpdate = data.data[0];
        // setCustomer(customerUpdate);
        alert("Successfully Logged In..");
        window.scrollTo(0, 0);
        navigate(`/paymentpage?customerId=${customerId}`);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="accountDetailsMainBox">
        <div className="accountDetailsFormBox">
          <h2>Set Password</h2>
          <h3>** Your Username is your email address **</h3>
          <form onSubmit={handleSubmit}>
            {/* <label>Set Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          /> */}
            <label>Set Password:</label>
            <input
              type="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />
            <label>Confirm Password:</label>
            <input
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            <button
              className="addToCartBtn accountDetailsBtn"
              //   onClick={() => check()}
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
