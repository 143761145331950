import React from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { a38, a46, s3 } from "../../Api/RootApiPath";
import { TbTruckDelivery } from "react-icons/tb";

export default function AdminOrderDetails() {
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  const { id } = useParams();
  console.log(id);

  const navigate = useNavigate();
  const orderId = parseInt(id);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(a38)
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
        setAllOrders(response.data);
        setLoading(false);
      });
  }, [orderId]);

  const requiredOrder = allOrders.filter((x) => x.id === orderId);
  console.log(requiredOrder);
  useEffect(() => {
    if (!loading) {
      orderTracker(requiredOrder[0].orderStatus);
    }
  }, [orderId]);

  let progress = 0;
  const orderTracker = (status) => {
    if (status === "Processing Payment") {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${10}%`,
                backgroundColor: "rgb(219, 153, 30)",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Pending</p>
            <p>Paid</p>
            <p>Shipped</p>
            <p>Delivered</p>
          </div>
        </div>
      );
    } else if (status === "Paid") {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${40}%`,
                backgroundColor: "rgba(0, 128, 0, 0.7)",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Pending</p>
            <p>Paid</p>
            <p>Shipped</p>
            <p>Delivered</p>
          </div>
        </div>
      );
    } else if (status === "Shipped") {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${70}%`,
                backgroundColor: "rgb(77, 155, 228)",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Pending</p>
            <p>Paid</p>
            <p>Shipped</p>
            <p>Delivered</p>
          </div>
        </div>
      );
    } else if (status === "Delivered") {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${100}%`,
                backgroundColor: "rgb(159, 77, 206)",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Pending</p>
            <p>Paid</p>
            <p>Shipped</p>
            <p>Delivered</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${10}%`,
                backgroundColor: "rgb(231, 30, 60)",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Failed</p>
            {/* <p>Paid</p> */}
            {/* <p>Shipped</p> */}
            {/* <p>Delivered</p> */}
          </div>
        </div>
      );
    }
  };

  function showPDFWithId(id) {
    // Make the API POST request with the ID
    fetch(a46, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob()) // Convert response to Blob
      .then((blob) => {
        // Create a URL for the Blob object
        setPdfLoading(false);
        const pdfUrl = URL.createObjectURL(blob);
        // Open the PDF in a new window or tab
        window.open(pdfUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  return (
    <div className="allCustomersTableMain">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState
            ? "adminContentbox allCustomersTable"
            : "adminContentbox adminContentbox2 allCustomersTable"
        }
      >
        <h2 style={{ marginBottom: "3rem", marginTop: "1rem" }}>
          Order Details
        </h2>
        {!loading ? (
          <div>
            <div className="adminOrderDetailsOuterBox1">
              <div className="adminOrderDetailsTrackingBox">
                <h4 style={{ marginBottom: "30px" }}>Track Order</h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 0",
                  }}
                >
                  <h4>S.no</h4>
                  <h4>Order Number</h4>
                  <h4>Tracking Id</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>{requiredOrder[0].id}</p>
                  <p>{requiredOrder[0].orderNumber}</p>
                  <p>Tracking Id</p>
                </div>
                {orderTracker(requiredOrder[0].orderStatus)}
              </div>
              <div className="adminOrderDetailsItemsMainBox">
                <h4 style={{ marginBottom: "10px" }}>Items from Order</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>ItemCode</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        onClick={() =>
                          navigate(
                            `/admin-productdetails?productId=${requiredOrder[0].tblProduct.id}`
                          )
                        }
                      >
                        <p>{requiredOrder[0].tblProduct.product_Name}</p>
                      </td>
                      <td>
                        <img
                          onClick={() =>
                            navigate(
                              `/admin-productdetails?productId=${requiredOrder[0].tblProduct.id}`
                            )
                          }
                          className="adminOrderDetailsItemsproductImage"
                          src={`${s3}/${requiredOrder[0].tblProduct.images
                            .split(",")
                            .pop()}`}
                          alt="Product Image"
                        />
                      </td>
                      <td>
                        <p>{requiredOrder[0].qty}</p>
                      </td>
                      <td>
                        <p>{requiredOrder[0].tblProduct.itemCode}</p>
                      </td>
                      <td>
                        <p>₹{requiredOrder[0].price}</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="4">
                        <p>Sub Total</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p>₹{requiredOrder[0].price}</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <p>Shipping Charge</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p>₹0</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <p>Total</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p>₹{requiredOrder[0].price}</p>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="adminOrderDetailsOuterBox2">
              <div className="adminOrderDetailsInfoBox">
                <h4>Shipping Information</h4>
                <h4>{`${requiredOrder[0].tblCustomerDetails.firstName}  ${requiredOrder[0].tblCustomerDetails.lastName}`}</h4>
                <p>
                  Delivery Address:{" "}
                  {`${requiredOrder[0].tblCustomerDetails.currAddStreet}  ${requiredOrder[0].tblCustomerDetails.currAddTown} ${requiredOrder[0].tblCustomerDetails.currAddState}  ${requiredOrder[0].tblCustomerDetails.currAddPinCode}`}
                </p>
                <p>Mobile: {requiredOrder[0].tblCustomerDetails.mobile}</p>
                <p>Email: {requiredOrder[0].tblCustomerDetails.email}</p>
              </div>
              <div className="adminOrderDetailsInfoBox">
                <h4>Billing Information</h4>
                <p>Payment type: {requiredOrder[0].paymentMode}</p>
                <p>
                  Billing Address:{" "}
                  {`${requiredOrder[0].tblCustomerDetails.perAddStreet}  ${requiredOrder[0].tblCustomerDetails.perAddTown} ${requiredOrder[0].tblCustomerDetails.perAddState}  ${requiredOrder[0].tblCustomerDetails.perAddPinCode}`}
                </p>
                <p>Invoice no: {requiredOrder[0].invoiceNo}</p>
                <button
                  className={
                    pdfLoading
                      ? "adminOrderDetailsPdfButton2"
                      : "adminOrderDetailsPdfButton"
                  }
                  onClick={() => {
                    showPDFWithId(requiredOrder[0].id), setPdfLoading(true);
                  }}
                >
                  Invoice pdf
                </button>
              </div>
              <div className="adminOrderDetailsInfoBox">
                <h4>Delivery Information</h4>
                <div className="adminOrderDetailsInfoDeliveryBox">
                  <TbTruckDelivery style={{ color: "grey" }} size={"30px"} />
                  <h4>Blue Dart</h4>
                  <p>OrderId: {requiredOrder[0].orderNumber}</p>
                  <p>Payment Mode: {requiredOrder[0].paymentMode}</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

{
  /* {requiredOrder.map((x) => {
  return (
    <div>
      <p>{x.id}</p>
      <p>{x.price}</p>
    </div>
  );
})} */
}
