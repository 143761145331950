import React, { useEffect } from "react";
import Heading from "../Heading/Heading";
import Footer from "./Footer";
import "../Components Styles/Footer.css";

export default function TermsAndConditions() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="ourContactMainBox termsMain">
        <h1>Terms & Conditions</h1>
        <div className="terms-items">
          <li>
            All information displayed, transmitted or carried on MK Ghare
            Jewellers is protected by copyright and other intellectual property
            laws.
          </li>
          <li>
            This site is designed, updated and maintained independently by MK
            Ghare Jewellers The content is owned by MK Ghare Jewellers You may
            not modify, publish, transmit, transfer, sell, reproduce, create
            derivative work from, distribute, repost, perform, display or in any
            way commercially exploit any of the content.
          </li>
          <li>
            MK Ghare Jewellers disclaims all warranties or conditions, whether
            expressed or implied, (including without limitation implied,
            warranties or conditions of information and context). We consider
            ourselves and intend to be subject to the jurisdiction only of the
            courts of Mumbai, Maharashtra.
          </li>
          <li>
            MK Ghare Jewellers reserves the right, in its sole discretion, to
            suspend or cancel the service at any time if a computer virus, bug,
            or other technical problem corrupts the security, or proper
            administration of the service.
          </li>
          <li>
            MK Ghare Jewellers Values the privacy of information pertaining to
            its associates. We do not use or disclose information about your
            individual visits to our website or any information that you may
            give us, such as your name, address, email address or telephone
            number, to any outside sources.
          </li>
          <li>
            MK Ghare Jewellers reserves the right to refuse service to anyone at
            any time.
          </li>
          <li>
            MK Ghare Jewellers will not use information about you without your
            permission and will provide the means for you to manage and control
            the information that you have provided. We will enable you to
            communicate your privacy concerns to us and that we will respond to
            them appropriately.
          </li>
          <li>
            MK Ghare Jewellers does not disclose any personal information to
            advertisers and for other marketing and promotional purposes that
            could be used to personally identify you, such as your password,
            credit card number and bank account number.
          </li>
        </div>
      </div>
      <Footer />
    </div>
  );
}
