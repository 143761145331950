import React from "react";
import "../Components Styles/Heading.css";

export default function UpperTagLine() {
  return (
    <div className="subscribeNow">
      <span>
        Monthly Installment Scheme (Pay 10 installments, get 100% off on the
        11th installments <span />
        <span className="coming-soon" id="subscribeNowLink">
          {" "}
          SUBSCRIBE NOW)
        </span>
      </span>
    </div>
  );
}
