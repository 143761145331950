import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
const LabelledListPDF = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Labelled List</Text>
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Collection Name</th>
              <th>Gross Wt</th>
              <th>Stone WT</th>
              <th>Net Wt</th>
              <th>MRP</th>
              <th>Item Code</th>
              <th>Barcode Number</th>
            </tr>
          </thead>
          <tbody>
            {data.map((x) => (
              <tr key={x.id}>
                <td>{x.id}</td>
                <td>{x.collection}</td>
                <td>{parseFloat(x.grosswt).toFixed(3)}</td>
                <td>{parseFloat(x.stoneWeight).toFixed(3)}</td>
                <td>{parseFloat(x.netWt).toFixed(3)}</td>
                <td>₹ {x.mrp}</td>
                <td>{x.itemCode}</td>
                <td>{x.barcodeNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </View>
    </Page>
  </Document>
);

export default LabelledListPDF;
