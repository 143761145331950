// import { USER_LOGGED_IN } from "../ActionTypes";
// import { USER_LOGGED_OUT } from "../ActionTypes";

// export const reducer4 = (state = [], action) => {
//   switch (action.type) {
//     case USER_LOGGED_IN: {
//       return [...state, action.payload];
//     }
//     case USER_LOGGED_OUT: {
//       const newArray = state.filter((index) => {
//         return index !== action.payload;
//       });
//       return newArray;
//     }

//     default:
//       return state;
//   }
// };

import { USER_LOGGED_IN } from "../ActionTypes";
import { USER_LOGGED_OUT } from "../ActionTypes";
export const reducer4 = (state = [], action) => {
  switch (action.type) {
    case USER_LOGGED_IN: {
      return action.payload;
    }
    case USER_LOGGED_OUT: {
      return null;
    }
    default:
      return state;
  }
};
