import React from "react";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import "../PagesStyles/CheckOut.css";
import { Link } from "react-router-dom";

export default function PaymentInformation() {
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <h1 style={{ marginTop: "2rem" }}>3.Payment Information</h1>
      <div className="accountDetailsMainBox">
        <span>
          <h2>NETBANKING</h2>
          {/* <input type="text" /> */}
        </span>
        <span>
          <h2>PAYTM</h2>
          {/* <input type="text" /> */}
        </span>
        <span>
          <h2>PHONE PAY</h2>
          {/* <input type="text" /> */}
        </span>

        <Link to="/checkout/accountdetails/shippingaddress">
          <button>BACK</button>
        </Link>
        {/* <Link to="shippingAddress">/ */}
        <button>SUBMIT ORDER</button>
        {/* </Link> */}
      </div>
      <Footer />
    </div>
  );
}
