import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore, { Navigation, Pagination } from "swiper";
import blankImage from "../../Images/blankProduct.jpg";

SwiperCore.use([Navigation, Pagination]);

export default function AboutUsCarousal() {
  const slidePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const slideNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  let swiper;

  return (
    <div className="mySwiperOuter">
      <Swiper
        slidesPerView={4}
        spaceBetween={10}
        navigation={false}
        className="mySwiper3"
        onSwiper={(swiperInstance) => (swiper = swiperInstance)}
      >
        <SwiperSlide className="aboutUsTeamImageSlide">
          <img
            className="aboutUsTeamImage"
            src={blankImage}
            alt="Blank Image"
          />
        </SwiperSlide>
        <SwiperSlide className="aboutUsTeamImageSlide">
          <img
            className="aboutUsTeamImage"
            src={blankImage}
            alt="Blank Image"
          />
        </SwiperSlide>
        <SwiperSlide className="aboutUsTeamImageSlide">
          <img
            className="aboutUsTeamImage"
            src={blankImage}
            alt="Blank Image"
          />
        </SwiperSlide>
        <SwiperSlide className="aboutUsTeamImageSlide">
          <img
            className="aboutUsTeamImage"
            src={blankImage}
            alt="Blank Image"
          />
        </SwiperSlide>
        <SwiperSlide className="aboutUsTeamImageSlide">
          <img
            className="aboutUsTeamImage"
            src={blankImage}
            alt="Blank Image"
          />
        </SwiperSlide>
        <SwiperSlide className="aboutUsTeamImageSlide">
          <img
            className="aboutUsTeamImage"
            src={blankImage}
            alt="Blank Image"
          />
        </SwiperSlide>
        <SwiperSlide className="aboutUsTeamImageSlide">
          <img
            className="aboutUsTeamImage"
            src={blankImage}
            alt="Blank Image"
          />
        </SwiperSlide>
        <SwiperSlide className="aboutUsTeamImageSlide">
          <img
            className="aboutUsTeamImage"
            src={blankImage}
            alt="Blank Image"
          />
        </SwiperSlide>
        {/* Rest of the slides */}
      </Swiper>
      <div className="swiper-button-prev" onClick={slidePrev}></div>
      <div className="swiper-button-next" onClick={slideNext}></div>
    </div>
  );
}
