import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { removeFromCart } from "../../../redux/action/Actions";
import { a2, a4, s1 } from "../../Api/RootApiPath";
import Heading from "../../Heading/Heading";
import HomePage from "../HomePage/HomePage";
import CheckoutFullPageOrderSummary from "./CheckoutFullPageOrderSummary";
import LoginPage from "./LoginPage";
import PaymentPage from "./PaymentPage";
import ShippingAddress from "./ShippingAddress";
import { FcGoogle } from "react-icons/fc";
import { GrFacebook } from "react-icons/gr";
import Footer from "../../Footer/Footer";
// eslint-disable-next-line
export default function CheckOutFullPage() {
  const [step, setStep] = useState(1);
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [customerId, setCustomerId] = useState("");

  // register
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [mobile, setMobile] = useState("");
  const [perAdd, setPerAdd] = useState("");
  const [currAdd, setCurrAdd] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [custId, setCustId] = useState("");

  // setPassword
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRemoveFromBag = (index) => {
    dispatch(removeFromCart(index));
  };
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };
  let totalPrice = 0;

  const handleLogin = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Customer_login_id", email);

    try {
      const response = await fetch(a2, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      console.log("Data:", data);
      paswordChecking(data);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (password1 !== password2) {
      alert("Sorry, the passwords do not match.");
      return;
    }

    setPassword(password1);
    const formData = new FormData();
    formData.append("Customer_id", customerId);
    formData.append("FieldName", "Password");
    formData.append("FieldValue", password1);
    try {
      const response = await fetch(a5, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status == "success") {
        // const customerUpdate = data.data[0];
        // setCustomer(customerUpdate);
        alert("Successfully Logged In..");
        window.scrollTo(0, 0);
        navigate(`/paymentpage?customerId=${customerId}`);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Mobile", mobile);
    formData.append("Email", email);
    formData.append("FirstName", firstName);
    formData.append("LastName", lastName);
    formData.append("Gender", gender);
    formData.append("DOB", dob);
    formData.append("PinCode", pinCode);
    formData.append("PerAdd", perAdd);
    formData.append("CurrAdd", currAdd);

    try {
      const response = await fetch(a4, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      setRegister("setPassword");
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const paswordChecking = async (data) => {
    if (data.status === "success") {
      const customerDetails = data.data[0];
      console.log("Data:", customerDetails);

      const PasswordChecker = data.data.find(
        (x) => x.Password === loginPassword
      );

      if (PasswordChecker) {
        if (loginPassword === PasswordChecker.Password) {
          setCustomerId(data.data[0].Customer_id);
          let customerId = data.data[0].Customer_id;
          if (orderItems.length === 0) {
            navigate(`/customerprofile?customerId=${customerId}`);
          } else {
            navigate(`/paymentpage?customerId=${customerId}`);
          }
          alert(
            `Successfully Logged In.. Welcome ${customerDetails.FirstName} ${customerDetails.LastName}`
          );
        } else {
          alert("Incorrect Password");
        }
      } else {
        alert("Incorrect Email");
      }
    } else {
      console.error(data.error);
      alert("Sorry either Username or Password is Incorrect");
    }
  };

  const loginForm = () => {
    return (
      <div className="checkoutFullPageFormSideBox" data-aos="fade-down">
        <h3 style={{ paddingLeft: "5rem" }}>1. Login to Ghare's</h3>
        <div className="loginPageBox">
          <p style={{ color: "black" }}>Login to Ghare's</p>
          <div className="loginSocialMediaOptionsBox">
            <button className="socialMediaOption">
              <li style={{ color: "grey", listStyle: "none" }}>Google</li>
              <FcGoogle size={"1.5rem"} />
            </button>
            <button className="socialMediaOption">
              <li style={{ color: "grey", listStyle: "none" }}>Facebook</li>
              <GrFacebook size={"1.5rem"} />
            </button>
          </div>
          <h4
            style={{ marginTop: "2rem", marginBottom: "1rem", color: "grey" }}
          >
            Or
          </h4>
          <form onSubmit={handleLogin}>
            {/* <hr></hr> */}
            <p style={{ alignSelf: "left" }}>Enter Username Or Email</p>
            <input
              type={"text"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p style={{ alignSelf: "left" }}>Enter Password</p>
            <input
              type={"text"}
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
            />
            <button
              type="submit"
              className="checkoutSecurelyBtn checkoutLoginBtn"
            >
              CONTINUE
            </button>
          </form>
          <h4 style={{ color: "grey", marginBottom: "1rem" }}>Or</h4>
          <p>
            <span>New to Ghare's? </span>
            <span
              className="createAccountLink"
              onClick={() => {
                setRegister("Register"), window.scrollTo(0, 0);
              }}
            >
              Create an Account
            </span>
          </p>
        </div>
      </div>
    );
  };

  const registerForm = () => {
    return (
      <div className="checkoutFullPageFormSideBox" data-aos="fade-down">
        <h3 style={{ paddingLeft: "5rem" }}>1. Login to Ghare's</h3>
        <div className="loginPageBox registerBoxMain">
          <p style={{ color: "black" }}>Sign Up with Ghare's</p>
          {/* <h4>Become a Member Today - It's quick and free!</h4>
          <h4>100 Bonus points when you join</h4>
          <h4>Free standard delivery</h4>
          <h4> Access to brand partnerships and offers</h4> */}
          <div className="loginSocialMediaOptionsBox">
            {/* <button className="socialMediaOption">
              <li style={{ color: "grey", listStyle: "none" }}>Google</li>{" "}
              <FcGoogle size={"1.5rem"} />
            </button>
            <button className="socialMediaOption">
              <li style={{ color: "grey", listStyle: "none" }}>Facebook</li>{" "}
              <GrFacebook size={"1.5rem"} />
            </button> */}
            <FcGoogle size={"3rem"} />
            <GrFacebook size={"3rem"} />
          </div>
          <h4
            style={{ marginTop: "2rem", marginBottom: "1rem", color: "grey" }}
          >
            Or
          </h4>
          <form onSubmit={handleRegister}>
            <div className="registerBox">
              {/* <hr></hr> */}
              <p style={{ alignSelf: "left" }}>Enter Mobile Number</p>
              <p style={{ alignSelf: "left" }}>Enter Email</p>
              <input
                type="number"
                required="required"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
              <input
                type="email"
                required="required"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p style={{ alignSelf: "left" }}>First Name</p>
              <p style={{ alignSelf: "left" }}>Last Name</p>
              <input
                type="text"
                required="required"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                type="text"
                required="required"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <p style={{ alignSelf: "left" }}>Gender</p>
              <div
                className="genderRadioBox"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  id="male"
                  type="radio"
                  name="gender"
                  value="male"
                  onChange={handleGenderChange}
                  checked={gender === "male"}
                />
                <label htmlFor="male" style={{ marginInline: "0.5rem" }}>
                  Male
                </label>
                <input
                  id="female"
                  type="radio"
                  name="gender"
                  value="female"
                  onChange={handleGenderChange}
                  checked={gender === "female"}
                />
                <label htmlFor="female" style={{ marginInline: "0.5rem" }}>
                  Female
                </label>
                <input
                  id="other"
                  type="radio"
                  name="gender"
                  value="other"
                  onChange={handleGenderChange}
                  checked={gender === "other"}
                />
                <label htmlFor="other" style={{ marginInline: "0.5rem" }}>
                  Other
                </label>
              </div>
              <p style={{ alignSelf: "left" }}>Date of Birth</p>
              <p style={{ alignSelf: "left" }}>Pin Code for Delivery</p>
              <input
                type={"date"}
                required="required"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
              <input
                type={"number"}
                required="required"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
              />
              <p style={{ alignSelf: "left" }}>Permanent Address</p>
              <p style={{ alignSelf: "left" }}>Delivery Address</p>
              <textarea
                type={"text"}
                required="required"
                value={perAdd}
                onChange={(e) => setPerAdd(e.target.value)}
              />
              <textarea
                type={"text"}
                required="required"
                value={currAdd}
                onChange={(e) => setCurrAdd(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="checkoutSecurelyBtn checkoutLoginBtn"
            >
              REGISTER TO CONTINUE
            </button>
          </form>
          <h4 style={{ color: "grey", marginBottom: "1rem" }}>Or</h4>
          <p>
            <span>Already have an account? </span>

            <span
              className="createAccountLink"
              onClick={() => {
                setRegister("Login"), window.scrollTo(0, 0);
              }}
            >
              Log in
            </span>
          </p>
        </div>
      </div>
    );
  };
  const setPassword = () => {
    return (
      <div className="checkoutFullPageFormSideBox" data-aos="fade-down">
        <h3 style={{ paddingLeft: "5rem" }}>1. Login to Ghare's</h3>
        <form onSubmit={handleChangePassword}>
          <div className="loginPageBox setPasswordMain">
            <p style={{ color: "black", marginBottom: "2rem" }}>
              **Your Username is your Email Address**
            </p>

            <p style={{ alignSelf: "left" }}>Set Password</p>
            <input
              type="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />
            <p style={{ alignSelf: "left" }}>Confirm Password</p>
            <input
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            {/* <Link to="/razorpaypage"> */}
            <button
              className="checkoutSecurelyBtn checkoutLoginBtn"
              type="submit"
              // onClick={() => {
              //   setRegister("paymentPage"), window.scrollTo(0, 0);
              // }}
            >
              CONTINUE
            </button>
            {/* </Link> */}
          </div>
        </form>
      </div>
    );
  };
  const paymentPage = () => {
    return (
      <div className="checkoutFullPageFormSideBox" data-aos="fade-down">
        <h3 style={{ paddingLeft: "5rem" }}>2. Payment Details</h3>
        <div className="loginPageBox setPasswordMain">
          <p style={{ color: "black", marginBottom: "2rem" }}>
            **Your Username is your Email Address**
          </p>

          <p style={{ alignSelf: "left" }}>Set Password</p>
          <input type={"text"} />
          <p style={{ alignSelf: "left" }}>Confirm Password</p>
          <input type={"text"} />
          <button
            className="checkoutSecurelyBtn checkoutLoginBtn"
            onClick={() => {
              setRegister("paymentPage"), window.scrollTo(0, 0);
            }}
          >
            CONTINUE
          </button>
        </div>
      </div>
    );
  };
  const [register, setRegister] = useState("Login");
  useEffect(() => {
    window.scrollTo(0, 0), AOS.init({ duration: 1200 });
  }, []);
  return (
    <div className="checkOutMainBox">
      <div className="headingBox orderSummaryHeadingBox">
        <Heading />
      </div>
      <div className="checkoutFullPageFormSideMainBox" data-aos="fade-down">
        {/* {loginForm()} */}
        {register == "Login" ? (
          loginForm()
        ) : register == "Register" ? (
          registerForm()
        ) : register == "setPassword" ? (
          setPassword()
        ) : register == "paymentPage" ? (
          paymentPage()
        ) : (
          <h1>payment done</h1>
        )}

        <CheckoutFullPageOrderSummary />
      </div>

      {/* <div>
        {step === 1 && <LoginSignup nextStep={nextStep} />}
        {step === 2 && <DeliveryDetails nextStep={nextStep} />}
        {step === 3 && <PaymentInformation />}
    </div> */}
      <Footer />
    </div>
  );
}
