import React, { useEffect } from "react";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { RxCross2, RxCheck } from "react-icons/rx";
import { a1 } from "../../Api/RootApiPath";
import { userLoggedIn } from "../../../redux/action/Actions";
import { Link, useNavigate } from "react-router-dom";

export default function CheckOutPageNewLogin() {
  const allStates = useSelector((state) => state);
  const userDetails = allStates.reducer4;

  const [email, setEmail] = useState("");
  const [allCsData, setAllCsData] = useState([]);
  const [csData, setCsData] = useState([]);
  const [oldUser, setOldUser] = useState("EmailPage");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [dataRcvd, setDataRcvd] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserExist();
  }, []);
  const checkUserExist = async () => {
    fetch(a1)
      .then((res) => res.json())
      .then((result) => setAllCsData(result.data));
    setDataRcvd(true);
  };
  // console.log(allCsData);
  console.log(userDetails);
  const ifNewUser = (email) => {
    let checkEmailInput = document.getElementById("emailInput");

    if (emailInput.classList.contains("valid")) {
      // console.log("Email Accepted");
      const filteredData = allCsData.filter(
        (x) => x.customer_login_id === email
      );
      if (filteredData.length > 0) {
        console.log("exist"),
          setCsData(filteredData),
          setOldUser("OldUser"),
          window.scrollTo(0, 0);
      } else {
        console.log("new user"),
          setCsData(filteredData),
          // setOldUser("NewUser"),
          addNewUser();
        window.scrollTo(0, 0);
      }
    } else {
      setEmail(" ");
      validateEmail(email), window.scrollTo(0, 0);
    }
  };

  const checkLogin = () => {
    if (password === csData[0].password) {
      return loggedIn(csData), navigate("/checkoutpagenew/o2");
    } else setIncorrectPassword(true);
  };
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  const addNewUser = () => {
    const newUser = [
      {
        currAddStreet: "",
        currAddTown: "",
        currAddState: "",
        currAddPinCode: "",
        dob: "",
        email: email,
        firstName: "Firstname",
        gender: "",
        lastName: "Lastname",
        middleName: "",
        mobile: "",
        password: "",
        perAddStreet: "",
        perAddTown: "",
        perAddState: "",
        perAddPinCode: "",
      },
    ];
    // loggedIn(newUser);
    // navigate("/checkoutpagenew/n2");
    navigate("/customersignuppage");
  };

  return (
    <div className="checkOutMainBox">
      <div className="headingBox">
        <Heading />
      </div>
      <div
        style={{ marginTop: 0, paddingTop: "50px" }}
        className="checkoutNewLoginMainBox"
      >
        <h2 style={{ marginBottom: "20px" }}>Login to Ghare's</h2>
        {oldUser === "EmailPage" ? (
          <div className="checkoutNewLoginFormBox">
            {/* <h3 style={{ color: "black" }}>
              Enter your email address to proceed
            </h3>
            <p>We need your email address to send updates about your order.</p> */}
            <label>
              Email<sup style={{ color: "red" }}>*</sup>
            </label>
            <div
              id="emailInput"
              className={`password-input ${
                email && !validateEmail(email) ? "invalid" : ""
              } ${email && validateEmail(email) ? "valid" : ""} ${
                !email ? "blank" : ""
              }`}
            >
              <input
                id="emailInputField"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
              />
              {email && !validateEmail(email) && (
                <p style={{ margin: "0" }} className="invalid">
                  Please enter a valid email
                </p>
              )}
              {email && validateEmail(email) && (
                <RxCheck className="checkMark" size="20px" />
              )}
              {email && !validateEmail(email) && (
                <RxCross2
                  color="red"
                  style={{ top: "40%" }}
                  className="checkMark"
                  size="20px"
                />
              )}
            </div>
            {/* <p>{email}</p> */}
            {dataRcvd ? (
              <button
                className="checkoutNewLoginFormBoxButton"
                onClick={() => ifNewUser(email)}
              >
                Continue
              </button>
            ) : null}
            <p
              id="newToGhareLine"
              onClick={() => {
                navigate("/customersignuppage");
                // setPageFor("Register");
                // setOldUser("RegisterPage");
              }}
              style={{ textAlign: "center" }}
            >
              New To Ghare's, <span>Create an Account</span>
            </p>
          </div>
        ) : oldUser === "OldUser" ? (
          <div className="checkoutNewLoginFormBox">
            <h3 style={{ color: "black" }}>Welcome !</h3>
            <p style={{ margin: "10px 0", marginBottom: "50px" }}>
              **********{email.substr(-10, 100)}
            </p>
            <p style={{ margin: "20px 0" }}>
              Please login to proceed to checkout.
            </p>
            <label>
              Password<sup style={{ color: "red" }}>*</sup>
            </label>
            {/* <div id="password-input" className={`password-input`}> */}
            <div
              className={`password-input ${incorrectPassword ? "invalid" : ""}`}
            >
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="toggle-password-visibility"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </button>
              {incorrectPassword ? (
                <p style={{ margin: "0" }} className="invalid">
                  Wrong email or password, please try again.
                </p>
              ) : null}
              {incorrectPassword ? (
                <RxCross2
                  color="red"
                  style={{ top: "40%" }}
                  className="checkMark"
                  size="20px"
                />
              ) : null}
              {/* {password && !checkPassword(password) && ( */}
            </div>
            {/* </div> */}
            {/* <p>{email}</p> */}

            <Link to="/changepassword">
              <p style={{ textAlign: "end", color: "black" }}>
                Forgot Password
              </p>
            </Link>
            {dataRcvd ? (
              <button
                className="checkoutNewLoginFormBoxButton"
                onClick={() => checkLogin()}
              >
                Continue
              </button>
            ) : null}
            <p
              onClick={() => {
                setOldUser("EmailPage"),
                  setEmail(""),
                  setPassword(""),
                  window.scrollTo(0, 0);
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              <u>Use another email address</u>
            </p>
          </div>
        ) : (
          <>
            <div
              style={{ marginBottom: "10px" }}
              className="checkoutNewLoginFormBox"
            >
              <h3 style={{ color: "black" }}>Welcome !</h3>
              <p style={{ margin: "10px 0", marginBottom: "20px" }}>{email}</p>
              <p>Become a member today – It’s quick and free!</p>
            </div>
            <div
              style={{ marginTop: "10px", marginBottom: "20px" }}
              className="checkoutNewLoginFormBox"
            >
              <h3 style={{ color: "black" }}>New customer checkout</h3>
              <p>Become a member today – It’s quick and free!</p>
              <p style={{ margin: "0", display: "flex", alignItems: "center" }}>
                <RxCheck
                  style={{ color: "grey", marginRight: "10px" }}
                  size={"20px"}
                />
                100 bonus points when you join
              </p>
              <p style={{ margin: "0", display: "flex", alignItems: "center" }}>
                <RxCheck
                  style={{ color: "grey", marginRight: "10px" }}
                  size={"20px"}
                />
                Free standard delivery
              </p>
              <p style={{ margin: "0", display: "flex", alignItems: "center" }}>
                <RxCheck
                  style={{ color: "grey", marginRight: "10px" }}
                  size={"20px"}
                />
                Access to brand partnerships and offers
              </p>
              {/* <div id="password-input" className={`password-input`}> */}
              <div className="password-input">
                {/* <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                /> */}
                <button
                  className="toggle-password-visibility"
                  onClick={togglePasswordVisibility}
                >
                  {/* {showPassword ? "HIDE" : "SHOW"} */}
                </button>
              </div>
              {/* </div> */}
              {/* <p>{email}</p> */}
              {dataRcvd ? (
                <button
                  className="checkoutNewLoginFormBoxButton"
                  onClick={() => addNewUser()}
                >
                  Continue to Checkout
                </button>
              ) : null}
            </div>
            <p
              onClick={() => {
                setOldUser("EmailPage"),
                  setEmail(""),
                  setPassword(""),
                  window.scrollTo(0, 0);
              }}
              style={{
                textAlign: "center",
                cursor: "pointer",
                fontSize: "13px",
                marginBottom: "100px",
              }}
            >
              <u>Use another email address</u>
            </p>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
}
