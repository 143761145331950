export const ADD_TO_FAV = "ADD_TO_FAV";
export const REMOVE_FROM_FAV = "REMOVE_FROM_FAV";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const FILTER_OPENED = "FILTER_OPENED";
export const FILTER_CLOSED = "FILTER_CLOSED";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const ADMIN_LOGGED_IN = "ADMIN_LOGGED_IN";
export const ADMIN_LOGGED_OUT = "ADMIN_LOGGED_OUT";
export const ADMIN_SIDEBAR_OPENED = "ADMIN_SIDEBAR_OPENED";
export const ADMIN_SIDEBAR_CLOSED = "ADMIN_SIDEBAR_CLOSED";
export const FETCH_ALL_PRODUCTS = "FETCH_ALL_PRODUCTS";
