import React, { useEffect } from "react";
import Heading from "../Heading/Heading";
import Footer from "./Footer";
import "../Components Styles/Footer.css";
export default function ShippingPolicy() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="ourContactMainBox termsMain">
        <h1>Shipping Policy</h1>
        <div className="terms-items">
          <p>
            <strong> MK Ghare Jewellers </strong> offers FREE shipping on every
            product to its customers residing within India through our tie ups
            with the top most logistics companies like Blue Dart etc. To ensure
            optimum safety and a hassle free online shopping experience, all our
            shipments are insured till it reaches your doorstep.
          </p>
          <p>
            All our products are specially packaged in travel-safe, tamper-proof
            packing and utmost care is taken at every stage to ensure that the
            product reaches your hands in perfect condition. We request our
            customers to thoroughly check the parcel before signing any receipt.
            Once receipt is signed by the Customer, the Customer cannot dispute
            and/or make claim about the quality, quantity and /or any other
            aspect of the product .
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
