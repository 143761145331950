import React from "react";
import "../Components Styles/AlertMessage.css";

const AlertMessage = ({ message, type }) => {
  return (
    <div className="alertMessageMainBox">
      <div className={`alert ${type === "success" ? "success" : "error"}`}>
        {message}
      </div>
    </div>
  );
};

export default AlertMessage;
