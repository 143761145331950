import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { a3, a5 } from "../../Api/RootApiPath";
import { useDispatch, useSelector } from "react-redux";
import { userLoggedIn, userLoggedOut } from "../../../redux/action/Actions";
import { useNavigate } from "react-router-dom";
import CustomerDetailsEditBox from "./CustomerDetailsEditBox";

export default function CustomerDetails({ csData, orderId }) {
  const [detailsType, setDetailsType] = useState("Dashboard");
  const [popUp, setPopup] = useState(false);
  const [parameter, setParameter] = useState("");
  const [formValue, setFormValue] = useState("");
  const [logOut, setLogOut] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedOut = (index) => {
    dispatch(userLoggedOut(index));
  };
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  const csStates = useSelector((state) => state);
  const userDetails = csStates.reducer4;

  const [customerData, setCustomerData] = useState(userDetails);
  console.log(csData);
  console.log(userDetails);
  const updatedetails = (entry) => {
    setPopup(true);
  };

  // console.log(csData);
  const updatedetailsBox = async (parameter2) => {
    // const formData = new FormData();
    // formData.append("Customer_id", csData.Customer_id);
    // formData.append("FieldName", parameter);
    // formData.append("FieldValue", formValue);
    const formData = {
      Id: userDetails[0].id,
      [parameter2]: formValue,
    };
    try {
      const response = await fetch(a5, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      if (data.status == "success") {
        setPopup(false);
        // alert(`${parameter} Changed Successfully`);
        // orderUpdated(csData.Customer_id, "Account Details");
        orderUpdated(userDetails[0].id, "Account Details");
        // setDetailsType("Account Details");
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(csData);
  const dashBoard = () => {
    return (
      <div className="detailsBox">
        <h3 style={{ marginBottom: "2rem" }}>
          Hello {csData.firstName} {csData.middleName} {csData.lastName}{" "}
        </h3>
        <h4 style={{ marginBottom: "1rem" }}>
          {/* Login Id : {csData.Customer_login_id} */}
          Login Id : {csData.customer_login_id}
        </h4>
        <p>
          From your account dashboard you can view your recent orders, manage
          your billing address, and edit your password and account details.
        </p>
        <div className="createdOnBox">
          <p style={{ marginBottom: "0.5rem" }}>
            Last Updated On : {csData.lastUpdated}
          </p>
          <p style={{ marginBottom: "0.5rem" }}>
            Created On : {csData.createdOn}
          </p>
        </div>
      </div>
    );
  };
  const orderUpdated = async (customerId, typeFor) => {
    const formData = {
      Id: customerId,
    };
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);

    try {
      const response = await fetch(a3, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // loggedOut(userDetails);
      // setCustomerData(data.data[0]);
      // navigate(`/customerprofile?customerId=${customerId}`);
      loggedIn([data.data]);
      console.log(data.data);
      setDetailsType(typeFor);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  const orders = () => {
    return (
      <div className="detailsBox">
        <h3 style={{ marginBottom: "2rem" }}>Your Orders</h3>
        <h4 style={{ marginBottom: "1rem" }}>
          Total Orders : {csData.orderCount}
        </h4>

        <h4 style={{ marginBottom: "1rem" }}>
          Total Orders Value :{csData.orderValue}
        </h4>
        <h4 style={{ marginBottom: "1rem" }}>
          Your Orders ID : {csData.orderId}
        </h4>
        <h4 style={{ marginBottom: "1rem" }}>
          Your Orders Status : {csData.orderStatus}
        </h4>

        <div className="createdOnBox">
          <p style={{ marginBottom: "0.5rem" }}>
            Last Updated On : {csData.lastUpdated}
          </p>
          <p style={{ marginBottom: "0.5rem" }}>
            Created On : {csData.createdOn}
          </p>
        </div>
      </div>
    );
  };
  const addresses = () => {
    return (
      <div className="detailsBox">
        <h3 style={{ marginBottom: "2rem" }}>Your Addresses</h3>
        <h3 style={{ marginBottom: "0.5rem" }}>Delivery Address :</h3>
        <p style={{ marginBottom: "0.5rem" }}>
          {csData.currAddStreet}, {csData.currAddTown}, {csData.currAddState}
        </p>
        <p style={{ marginBottom: "2rem" }}>{csData.currAddPinCode}</p>

        <h3 style={{ marginBottom: "0.5rem" }}>Permanent Address :</h3>
        <p style={{ marginBottom: "0.5rem" }}>
          {csData.perAddStreet}, {csData.perAddTown}, {csData.perAddState}
        </p>
        <p style={{ marginBottom: "2rem" }}>{csData.perAddPinCode}</p>

        <div className="createdOnBox">
          <p style={{ marginBottom: "0.5rem" }}>
            Last Updated On : {csData.lastUpdated}
          </p>
          <p style={{ marginBottom: "0.5rem" }}>
            Created On : {csData.createdOn}
          </p>
        </div>
      </div>
    );
  };
  const accountDetails = () => {
    return (
      <div className="detailsBox">
        <h3 style={{ marginBottom: "2rem" }}>Update Your Account Details</h3>
        <div className="updateAccountDetailsMainBox">
          <h3 style={{ marginBottom: "0.5rem" }}>First Name :</h3>
          <h4>{csData.firstName}</h4>
          <button
            onClick={() => {
              setParameter("FirstName");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Middle Name :</h3>
          <h4>{csData.middleName}</h4>
          <button
            onClick={() => {
              setParameter("MiddleName");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Last Name :</h3>
          <h4>{csData.lastName}</h4>
          <button
            onClick={() => {
              setParameter("LastName");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Mobile :</h3>
          <h4>{csData.mobile}</h4>
          <button
            onClick={() => {
              setParameter("Mobile");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Delivery Address :</h3>
          <h4>{csData.currAdd}</h4>
          <button
            onClick={() => {
              setParameter("CurrAdd");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Permanent Address :</h3>
          <h4>{csData.perAdd}</h4>
          <button
            onClick={() => {
              setParameter("PerAdd");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
        </div>

        <div className="createdOnBox"></div>
        {/* <CustomerDetailsEditBox csData={csData} /> */}
      </div>
    );
  };
  useEffect(() => {
    dashBoard();
  }, [popUp]);
  return (
    <>
      <h1 style={{ marginTop: "7rem", color: "#1a4c2d" }}>{detailsType}</h1>
      <div style={{ justifyContent: "center" }} className="profileBox2">
        <div className="detailsTypeBox">
          <h4
            id={detailsType == "DashBoard" ? "greenColouredBorder" : null}
            onClick={() => setDetailsType("DashBoard")}
          >
            DashBoard
          </h4>

          <h4
            id={detailsType == "Orders" ? "greenColouredBorder" : null}
            onClick={() => {
              orderUpdated(csData.id, "Orders");
            }}
          >
            Orders
          </h4>
          <h4
            id={detailsType == "Addresses" ? "greenColouredBorder" : null}
            onClick={() => setDetailsType("Addresses")}
          >
            Addresses
          </h4>
          <h4
            id={detailsType == "Account Details" ? "greenColouredBorder" : null}
            onClick={() => setDetailsType("Account Details")}
          >
            Account Details
          </h4>
          <h4
            id={detailsType == "Logout" ? "greenColouredBorder" : null}
            onClick={() => {
              setDetailsType("Logout"), setLogOut(true);
            }}
          >
            Logout
          </h4>
        </div>
        <div
          className={
            popUp == true ? "updateAccountDetailsPopupMainBox" : "none"
          }
        >
          <div className="updateAccountDetailsPopupCloseBtn">
            <AiOutlineClose size={"3rem"} onClick={() => setPopup(false)} />
          </div>
          <h1 style={{ color: "rgba(0,0,0,0.5)" }}>Change {parameter} </h1>
          <input type="text" onChange={(e) => setFormValue(e.target.value)} />
          <button onClick={() => updatedetailsBox(parameter)}>Submit</button>
        </div>
        <div
          className={
            logOut == true ? "updateAccountDetailsPopupMainBox" : "none"
          }
        >
          <div className="updateAccountDetailsPopupCloseBtn">
            <AiOutlineClose size={"3rem"} onClick={() => setLogOut(false)} />
          </div>
          <h1 style={{ color: "rgba(0,0,0,0.5)" }}> Are You Sure ?</h1>
          <h1> Log out </h1>
          <button
            onClick={() => {
              navigate("/"), loggedIn([]);
            }}
          >
            Submit
          </button>
        </div>
        {detailsType == "DashBoard"
          ? dashBoard()
          : detailsType == "Orders"
          ? orders()
          : detailsType == "Addresses"
          ? addresses()
          : detailsType == "Account Details"
          ? accountDetails()
          : dashBoard()}
      </div>
      <div className={popUp || logOut === true ? "new" : "new2"}></div>
    </>
  );
}
