import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { a5 } from "../../Api/RootApiPath";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import { useSelector } from "react-redux";

export default function DeliveryPage() {
  const [delivery, setDelivery] = useState("default");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const orderState = useSelector((state) => state);
  const userDetails = orderState.reducer4[0];
  const navigate = useNavigate();
  const customerId = userDetails.id;

  const handleDelivery = async (e) => {
    e.preventDefault();
    let deliverAddress = `${street}, ${city}, ${state}, ${pinCode}`;

    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "PerAdd");
    // formData.append("FieldValue", deliverAddress);
    const formData = {
      Id: customerId,
      perAdd: deliverAddress,
    };
    try {
      const response = await fetch(a5, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        alert("Changed Delivery Address");
        navigate(`/paymentpage?customerId=${customerId}`);
        console.log(customerId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeliveryPost = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Customer_id", customerId);
    formData.append("FieldName", "PinCode");
    formData.append("FieldValue", pinCode);
    try {
      const response = await fetch(a5, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status == "success") {
        navigate("/paymentpage");
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const defaultAddress = () => {
    return (
      <div className="accountDetailsMainBox">
        <div
          className="accountDetailsFormBox accountDetailsFormBox2"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            margin: 0,
            padding: "0.5rem 0",
            paddingBottom: "0.5rem",
            backgroundColor: "white",
          }}
        >
          <div
            style={{ marginBottom: "0rem", backgroundColor: "white" }}
            className="onlyDeliveryOptionMainBox"
          >
            {/* <div className="loginFormCheckoutGridBox2"> */}
            <h3 className={delivery === "delivery" ? "selected" : "unSelected"}>
              Default Address
            </h3>
            <h3 onClick={() => setDelivery("changeDelivery")}>
              Change Address
            </h3>
            {/* </div> */}
          </div>
          <div
            className="contentForDeliveryBox"
            style={{ textAlign: "left", paddingLeft: "1rem" }}
          >
            <h2
              style={{
                alignSelf: "flex-start",
                margin: "1rem 0",
                marginBottom: 0,
              }}
            >
              Deliver To{" "}
            </h2>

            <p
              style={{
                alignSelf: "flex-start",
                margin: "0.5rem 0",
                width: "70%",
                lineHeight: "2rem",
                color: "grey",
              }}
            >
              <strong>{userDetails.perAdd}</strong>
            </p>
            <p
              style={{
                alignSelf: "flex-start",
                margin: "0.5rem 0",
                color: "grey",
              }}
            >
              <strong>{userDetails.pinCode}</strong>
            </p>
            <button
              onClick={() => navigate(`/paymentpage?customerId=${customerId}`)}
              style={{ width: "100%", margin: "2rem 0", marginBottom: 0 }}
              className="shopNowBtn"
            >
              Proceed to Payment
            </button>
          </div>
        </div>
      </div>
    );
  };
  const changeDelivery = () => {
    return (
      <div className="accountDetailsMainBox">
        <form onSubmit={handleDelivery}>
          <div
            className="accountDetailsFormBox"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "400px",
              // height: "300px",
              margin: 0,
              padding: "0.5rem 0",
              paddingBottom: "0.5rem",
              backgroundColor: "white",
            }}
          >
            <div
              style={{ marginBottom: "0rem", backgroundColor: "white" }}
              className="onlyDeliveryOptionMainBox"
            >
              {/* <div className="loginFormCheckoutGridBox2"> */}
              <h3 onClick={() => setDelivery("default")}>Default Address</h3>
              <h3
                className={delivery === "delivery" ? "selected" : "unSelected"}
              >
                Change Address
              </h3>
              {/* </div> */}
            </div>
            <div className="contentForDeliveryBox">
              <div
                className="loginFormCheckoutGridBox"
                style={{
                  width: "100%",
                  padding: "0 0.5rem",
                  justifyContent: "flex-end",
                  margin: "1rem",
                }}
              >
                <p style={{ margin: "0.5rem 1rem", justifySelf: "flex-start" }}>
                  Street Address
                </p>
                <input
                  style={{ margin: "0.5rem 0" }}
                  required="required"
                  type={"text"}
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
                <p style={{ margin: "0.5rem 1rem", justifySelf: "flex-start" }}>
                  Town / City
                </p>
                <input
                  style={{ margin: "0.5rem 0" }}
                  required="required"
                  type={"text"}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <p style={{ margin: "0.5rem 1rem", justifySelf: "flex-start" }}>
                  Pincode / Zip Code
                </p>
                <input
                  style={{ margin: "0.5rem 0" }}
                  required="required"
                  type={"text"}
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                />
                <p style={{ margin: "0.5rem 1rem", justifySelf: "flex-start" }}>
                  State
                </p>
                <input
                  style={{ margin: "0.5rem 0" }}
                  required="required"
                  type={"text"}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <button
                type="submit"
                style={{ width: "95%", margin: "2rem 0", marginBottom: 0 }}
                className="shopNowBtn"
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>

      <div className="outerDeliveryBox"></div>
      {delivery === "default" ? defaultAddress() : changeDelivery()}
      <Footer />
    </div>
  );
}
