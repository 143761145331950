/* global AOS */

import React, { useEffect, useState } from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { a18, a35, a7 } from "../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { AiOutlineEdit } from "react-icons/ai";
import { InfinitySpin } from "react-loader-spinner";
import { AiOutlineClose } from "react-icons/ai";

export default function AdminAddCategory() {
  const [entryBy_Staff_Id, setEntryBy_Staff_Id] = useState(1);
  const [shortCode, setShortCode] = useState(1);
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [label, setLabel] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  // const [material, setMaterial] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [description, setDescription] = useState("");
  const [statusType, setStatusType] = useState("active");
  const [showForm, setShowForm] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [popUp, setPopup] = useState(false);

  const [loading, setLoading] = useState(false);
  const [productInUpdate, setProductInUpdate] = useState([]);

  //   Label: The name or label of the item.
  //   ItemType: The type or category of the item (e.g. clothing, accessories, home goods, etc.).
  //   Material: The material the item is made of (e.g. cotton, leather, metal, etc.).
  //   Gm: The weight of the item in grams.
  //   Entryby_Staff_id: The ID of the staff member who added the item to the database.
  //   StatusType: The current status of the item (e.g. available, out of stock, discontinued, etc.).
  //   CreatedOn: The date and time when the item was added to the database.
  //   LastUpdated: The date and time when the item's information was last updated in the database.
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, [showForm]);

  const fetchAllCategories = async () => {
    try {
      const response = await fetch(a18);
      const data = await response.json();
      setCategoriesData(data.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchAllCategories();
  }, [showForm]);

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const adminLoggedIn = allStates.reducer5;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      Name: name,
      ParentsCategory: parentCategory,
      Material: name,
      Description: description,
      Slug: slug,
      Label: label,
      Entryby_Staff_id: Entryby_Staff_id,
    };

    try {
      const response = await fetch(a7, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      setShowForm(false);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e, property) => {
    const updatedProduct = {
      ...productInUpdate,
      [property]: e.target.value,
    };

    setProductInUpdate(updatedProduct);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      Id: productInUpdate.id,
      Name: productInUpdate.name,
      ParentsCategory: productInUpdate.parentsCategory,
      Material: productInUpdate.name,
      // ShortCode: shortCode,
      Description: productInUpdate.description,
      // HSNCode: hsnCode,
      Slug: productInUpdate.slug,
      Label: productInUpdate.label,
      Entryby_Staff_id: Entryby_Staff_id,
      // Id: productInUpdate.id,
      // Name: productInUpdate.name,
      // ParentsCategory: productInUpdate.parentsCategory,
      // Description: productInUpdate.description,
      // Slug: productInUpdate.slug,
      // Label: productInUpdate.label,
      // Entryby_Staff_id: Entryby_Staff_id,
    };

    try {
      const response = await fetch(a35, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log(formData);
      // alert("updated");
      setLoading(false);
      fetchAllCategories();
      setPopup(false);
      setProductInUpdate([]);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(productInUpdate);
  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        {/* <h1>Add Category</h1> */}
        {showForm ? (
          <div
            style={{ flexDirection: "column" }}
            className="adminAddCategoryMainbox"
          >
            <button
              style={{
                width: "200px",
                alignSelf: "flex-start",
                margin: "2rem",
                marginBottom: "0",
              }}
              onClick={() => {
                setShowForm(false);
              }}
            >
              Back To Category List
            </button>
            <div className="adminAddCategoryMainbox">
              <form onSubmit={handleSubmit}>
                <h3>ADD CATEGORY</h3>
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  required="required"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />

                <label htmlFor="slug">Slug:</label>
                <input
                  type="text"
                  id="slug"
                  required="required"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
                <label htmlFor="slug">Label:</label>
                <input
                  maxLength={2}
                  type="text"
                  id="slug"
                  required="required"
                  value={label.toUpperCase()}
                  onChange={(e) => setLabel(e.target.value)}
                />

                <label htmlFor="parentCategory">Parent Category:</label>
                <select
                  required="required"
                  value={parentCategory}
                  onChange={(e) => setParentCategory(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="Metal">Metal</option>
                  <option value="Non-Metal">Non-Metal</option>
                </select>

                {/* <label htmlFor="hsn">HSN Code:</label>
                <input
                  type="text"
                  id="hsn"
                  value={hsnCode}
                  onChange={(e) => setHsnCode(e.target.value)}
                /> */}

                <label htmlFor="description">Description:</label>

                <input
                  type="text"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        ) : (
          <div
            style={{ flexDirection: "column" }}
            className="adminAddCategoryMainbox"
          >
            {/* <div className="adminEditCategoryButtonBox"> */}
            {/* <button
                style={{
                  width: "200px",
                  // alignSelf: "flex-start",
                  margin: "2rem",
                  marginBottom: "0",
                }}
                onClick={() => {
                  setShowEdit(!showEdit);
                  // setCategoriesData("");
                }}
              >
                Edit Any Category
              </button> */}
            <button
              style={{
                width: "200px",
                alignSelf: "flex-end",
                margin: "2rem",
                marginBottom: "0",
              }}
              onClick={() => {
                setShowForm(true);
                // setCategoriesData("");
              }}
            >
              Add New Category
            </button>
            {/* </div> */}
            <div className="adminAddCategoryMainbox">
              <form style={{ width: "100%" }}>
                <h3 style={{ width: "100%" }}>Categories</h3>
                <table
                  className="allCustomersTable"
                  style={{ width: "90%", margin: "1rem 0rem" }}
                >
                  <thead>
                    <tr style={{ paddingBottom: "1rem" }}>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Slug</th>
                      <th>Label</th>
                      <th>Parent Category</th>
                      {/* <th>HSN Code</th> */}
                      <th>Description</th>
                    </tr>
                  </thead>

                  <tbody data-aos="zoom-in">
                    {categoriesData.map((x) => (
                      <tr key={x.id}>
                        <td>{x.id}</td>
                        <td>{x.name}</td>
                        <td>{x.slug}</td>
                        <td>{x.label}</td>
                        <td>{x.parentsCategory}</td>
                        <td>{x.description}</td>
                        <td>
                          <AiOutlineEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setPopup(true);
                              setProductInUpdate(x);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>
              <div
                style={{
                  height: "auto",
                }}
                className={
                  popUp == true
                    ? "updateAccountDetailsPopupMainBox editCategoryPopBox"
                    : "none"
                }
              >
                <div
                  className="updateAccountDetailsPopupCloseBtn"
                  style={{ margin: "0.5rem" }}
                >
                  <AiOutlineClose
                    size={"3rem"}
                    onClick={() => {
                      setPopup(false);
                      // setShowEdit(!showEdit);
                    }}
                  />
                </div>
                <div
                  style={{ height: "100px", marginBottom: "1rem" }}
                  className={loading == true ? "loading" : "none"}
                >
                  <InfinitySpin
                    className={loading == true ? "loading" : "none"}
                    width="150"
                    color="#4fa94d"
                  />
                </div>
                <div
                  className="editCategoryFormBox"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <form
                    style={{ marginTop: "0", paddingTop: 0 }}
                    onSubmit={handleEdit}
                  >
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      onChange={(e) => handleInputChange(e, "name")}
                      placeholder={productInUpdate.name}
                    />
                    <label htmlFor="slug">Slug</label>
                    <input
                      type="text"
                      id="slug"
                      placeholder={productInUpdate.slug}
                      onChange={(e) => handleInputChange(e, "slug")}
                    />
                    <label htmlFor="label">Label</label>
                    <input
                      type="text"
                      id="label"
                      placeholder={productInUpdate.label}
                      onChange={(e) => handleInputChange(e, "label")}
                    />
                    <label htmlFor="parentCategory">Parents Category</label>
                    <select
                      id="parentsCategory"
                      value={productInUpdate.parentsCategory}
                      placeholder={productInUpdate.parentsCategory}
                      onChange={(e) => handleInputChange(e, "parentsCategory")}
                    >
                      <option value="">Select an option</option>
                      <option value="Metal">Metal</option>
                      <option value="Non-Metal">Non-Metal</option>
                    </select>

                    <label htmlFor="description">Description</label>
                    <input
                      type="text"
                      id="description"
                      onChange={(e) => handleInputChange(e, "description")}
                      placeholder={productInUpdate.description}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "2fr",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button type="submit" style={{ width: "100%" }}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              onClick={() => setPopup(false)}
              className={popUp === true ? "new" : "new2"}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
}
