import React from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import { useState } from "react";
import { a18, a27, a28 } from "../../Api/RootApiPath";
import { useEffect } from "react";
import { allStateList } from "../../Api/StateList";
export default function AdminAddSupplier() {
  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const [partyCode, setPartyCode] = useState("");
  const [supplierType, setSupplierType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [panNo, setPanNo] = useState("");
  const [aadharNo, setAadharNo] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [firmName, setFirmName] = useState("");
  const [firmDetails, setFirmDetails] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [cgstNo, setCgstNo] = useState("");
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [partiesData, setPartiesData] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, [showForm]);

  const fetchAllParties = async () => {
    await fetch(a28)
      .then((res) => res.json())
      .then((data) => setPartiesData(data.data.result));
  };
  console.log(partiesData);

  useEffect(() => {
    fetchAllParties();
  }, [showForm]);
  //   useEffect(() => {
  //     fetch(a18)
  //       .then((res) => res.json())
  //       .then((data) => setCategories(data.data));
  //   }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // const formData = new FormData();

    // formData.append("party_code", partyCode);
    // formData.append("party_first_name", firstName);
    // formData.append("party_pan_no", panNo);
    // formData.append("party_adhar_no", aadharNo);
    // formData.append("contact_no", contactNo);
    // formData.append("email_id", emailId);
    // formData.append("address", address);
    // formData.append("state", state);
    // formData.append("city", city);
    // formData.append("firm_name", firmName);
    // formData.append("firm_details", firmDetails);
    // formData.append("gst_no", gstNo);
    // formData.append("central_gst_no", cgstNo);
    // setCategory_id(parseInt(category_id));
    const formData = {
      //   party_code: parseInt(partyCode),
      supplier_code: partyCode,
      supplierType: supplierType,
      supplier_name: firstName,
      party_pan_no: panNo,
      party_adhar_no: aadharNo,
      //   party_adhar_no: parseInt(aadharNo),
      contact_no: contactNo,
      //   contact_no: parseInt(contactNo),
      email_id: emailId,
      address: address,
      state: state,
      city: city,
      firm_name: firmName,
      firm_details: firmDetails,
      gst_no: gstNo,
      //   gst_no: parseInt(gstNo),
      central_gst_no: gstNo,
    };

    try {
      const response = await fetch(a27, {
        // body: formData,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: formData,
        body: JSON.stringify(formData),
      });
      console.log(formData);
      const data = await response.json();
      if (response.status === "Success") {
        console.log(data);
        setShowForm(false);
        // alert("Supplier Added");
      } else {
        setShowForm(false);
        // alert("Supplier Added");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //   Below is the api for getting states
  //   useEffect(() => {
  //     findState();
  //   }, []);
  //   const findState = () => {
  //     var headers = new Headers();
  //     headers.append("X-CSCAPI-KEY", "API_KEY");

  //     var requestOptions = {
  //       method: "GET",
  //       headers: headers,
  //       redirect: "follow",
  //     };

  //     fetch("https://api.countrystatecity.in/v1/states", requestOptions)
  //       .then((response) => response.text())
  //       .then((result) => console.log(result))
  //       .catch((error) => console.log("error", error));
  //   };
  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        {showForm ? (
          <div
            style={{ flexDirection: "column" }}
            className="adminAddCategoryMainbox"
          >
            <button
              style={{
                width: "200px",
                alignSelf: "flex-start",
                margin: "2rem",
                marginBottom: "0",
              }}
              onClick={() => {
                setShowForm(!showForm);
              }}
            >
              Back To Supplier List
            </button>
            <div className="adminAddCategoryMainbox addProductMain">
              <form onSubmit={handleSubmit}>
                <h3 style={{ marginBottom: "3rem" }}>ADD SUPPLIER</h3>
                <div className="addProductDetailsBox">
                  <label htmlFor="partyCode">Supplier Code:</label>
                  <input
                    required="required"
                    type="text"
                    id="boxName"
                    value={partyCode}
                    onChange={(e) => setPartyCode(e.target.value)}
                  />
                  <label htmlFor="partyCode">Supplier Type:</label>
                  <select
                    value={supplierType}
                    onChange={(e) => {
                      setSupplierType(e.target.value);
                    }}
                  >
                    <option value="">Select an option</option>
                    <option value="Party">Party</option>
                    <option value="Karigar">Karigar</option>
                  </select>
                  <label htmlFor="firstName">Supplier Name:</label>
                  <input
                    required="required"
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <label htmlFor="firmName">Firm Name:</label>
                  <input
                    required="required"
                    type="text"
                    id="firmName"
                    value={firmName}
                    onChange={(e) => setFirmName(e.target.value)}
                  />
                  <label htmlFor="firmName">Firm Details:</label>
                  <textarea
                    // required="required"
                    type="text"
                    id="firmName"
                    value={firmDetails}
                    onChange={(e) => setFirmDetails(e.target.value)}
                  />
                  <label htmlFor="gstNo">GST Number:</label>
                  <input
                    // required="required"
                    type="text"
                    id="gstNo"
                    value={gstNo}
                    onChange={(e) => setGstNo(e.target.value)}
                  />
                  <label htmlFor="panNo">Pan Number:</label>
                  <input
                    // required="required"
                    type="text"
                    id="panNo"
                    value={panNo}
                    onChange={(e) => setPanNo(e.target.value)}
                  />
                  <label htmlFor="aadharNo">Aadhar Number:</label>
                  <input
                    // required="required"
                    type="text"
                    id="aadharNo"
                    maxLength="8"
                    value={aadharNo}
                    onChange={(e) => setAadharNo(e.target.value)}
                  />
                  <label htmlFor="contactNo">Conatct Number:</label>
                  <input
                    // required="required"
                    type="text"
                    id="contactNo"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                  />
                  <label htmlFor="emailId">Email Address:</label>
                  <input
                    // required="required"
                    type="text"
                    id="emailId"
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                  />

                  <label htmlFor="address">Address:</label>
                  <textarea
                    // required="required"
                    type="text"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <label htmlFor="state">State:</label>
                  <select
                    required="required"
                    type="text"
                    id="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="">Select a state</option>
                    {allStateList.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {/* <label htmlFor="state">State:</label>
                  <input
                    required="required"
                    type="text"
                    id="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  /> */}
                  <label htmlFor="city">City:</label>
                  <input
                    // required="required"
                    type="text"
                    id="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />

                  {/* <label htmlFor="cgstNo">CGST Number:</label>
              <input
                required="required"
                type="text"
                id="cgstNo"
                value={cgstNo}
                onChange={(e) => setCgstNo(e.target.value)}
              /> */}
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        ) : (
          <div
            style={{ flexDirection: "column" }}
            className="adminAddCategoryMainbox"
          >
            <button
              style={{
                width: "200px",
                alignSelf: "flex-end",
                margin: "2rem",
                marginBottom: "0",
              }}
              onClick={() => {
                setShowForm(!showForm);
                // setProductTypeData("");
              }}
            >
              Add New Supplier
            </button>
            <div
              className="adminAddCategoryMainbox"
              style={{ boxSizing: "border-box" }}
            >
              <form style={{ width: "100%", overflowX: "auto" }}>
                <h3 style={{ width: "100%" }}>Suppliers</h3>
                <table
                  className="adminPartyTable allCustomersTable"
                  style={{ width: "90%", margin: "1rem 0rem", padding: "2rem" }}
                >
                  {/* <div
              className="adminAddCategoryMainbox"
             
            >
              <form style={{ width: "100%" }}>
                <h3 style={{ width: "100%" }}>Categories</h3>
                <table
                  className="allCustomersTable"
                  style={{ width: "90%", margin: "1rem 0rem" }}
                  // style={{ width: "100%", overflow: "hidden" }}
                > */}
                  <thead>
                    <tr style={{ paddingBottom: "1rem" }}>
                      <th>S.Id</th>
                      <th>S.code</th>
                      <th>S.name</th>
                      <th>S.Type</th>
                      <th>firm_name</th>
                      <th>firm_details</th>
                      <th>gst_no</th>
                      {/* <th>party_pan_no</th>
                      <th>party_adhar_no</th> */}
                      <th>contact_no</th>
                      {/* <th>email_id</th> */}
                      {/* <th>address</th> */}
                      <th>state</th>
                      {/* <th>city</th> */}
                    </tr>
                  </thead>

                  <tbody data-aos="zoom-in">
                    {partiesData.map((x) => (
                      <tr key={x.id}>
                        <td>{x.id}</td>
                        <td>{x.supplier_code}</td>
                        <td>{x.supplier_name}</td>
                        <td>{x.supplierType}</td>
                        <td>{x.firm_name}</td>
                        <td>{x.firm_details}</td>
                        <td>{x.gst_no}</td>
                        {/* <td>{x.party_pan_no}</td>
                        <td>{x.party_adhar_no}</td> */}
                        <td>{x.contact_no}</td>
                        {/* <td>{x.email_id}</td> */}
                        {/* <td>{x.address}</td> */}
                        <td>{x.state}</td>
                        {/* <td>{x.city}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
