import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import "../PagesStyles/CheckOut.css";
import { a4 } from "../../Api/RootApiPath";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import OrderSummaryProduct from "./OrderSummaryProduct";

export default function AccountDetailsPage() {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [mobile, setMobile] = useState("");
  const [perAdd, setPerAdd] = useState("");
  const [currAdd, setCurrAdd] = useState("");
  const [email, setEmail] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [custId, setCustId] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("FirstName", firstName);
    formData.append("MiddleName", middleName);
    formData.append("LastName", lastName);
    formData.append("Gender", gender);
    formData.append("DOB", dob);
    formData.append("Mobile", mobile);
    formData.append("PerAdd", perAdd);
    formData.append("CurrAdd", currAdd);
    formData.append("Email", email);
    formData.append("PinCode", pinCode);

    try {
      const response = await fetch(a4, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      const customerId = data.Customer_id;
      setCustId(customerId); // set the customerId to the state variable
      alert("Form submitted Successfully");
      navigate(`/setpassword?customerId=${customerId}`);

      // navigate('/home');
    } catch (error) {
      console.error(error);
    }
  };
  const newData = 134;

  const goToNew = () => {
    navigate(`/login?customerId=${newData}`);
  };
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="accountDetailsMainBox">
        <Link to="/checkout">
          <AiOutlineArrowLeft
            className="accountDetailsBackArrow"
            size={"3rem"}
          />
        </Link>
        <div className="accountDetailsFormBox">
          <h2>MY INFORMATION</h2>
          <form onSubmit={handleSubmit}>
            <label>First Name:</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <label>Middle Name:</label>
            <input
              type="text"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
            <label>Last Name:</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <label>Gender:</label>
            <select value={gender} onChange={(e) => setGender(e.target.value)}>
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            {/* <input
              type="text"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            /> */}
            <label>DOB:</label>
            <input
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
            <label>Mobile:</label>
            <input
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />

            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Pin Code:</label>
            <input
              type="text"
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
            />
            <label>Permanent Address:</label>
            <textarea
              type="text"
              value={perAdd}
              onChange={(e) => setPerAdd(e.target.value)}
            />
            <label>Current Address:</label>
            <textarea
              type="text"
              value={currAdd}
              onChange={(e) => setCurrAdd(e.target.value)}
            />
            <button
              className="addToCartBtn accountDetailsBtn"
              // onClick={() => goToNew()}
              type="submit"
            >
              Submit
            </button>
          </form>
          {/* <input type="submit" name="feessubmit" value="Submit" /> */}
          {/* <Link to="/productpage">
            <button>BACK</button>
          </Link>
          <Link to="shippingAddress">
            <button>NEXT</button>
          </Link> */}
        </div>
        <div className="accountDetailsFormBox  orderDetailsBox">
          <div className="orderSummaryRightsection">
            <h1>Order Summary</h1>
            <div className="orderSummaryProductBox">
              <div className="orderSummaryProductLeftBox">
                <h3>Item</h3>
              </div>
              <div className="orderSummaryProductRightBox">
                <h3>Total</h3>
              </div>
            </div>
            <div>
              {/* <OrderSummaryProduct /> */}
              <OrderSummaryProduct />
              {/* <h2 style={{ textAlign: "right" }}>₹176000</h2>
              <div className="orderSummaryProductBox">
                <div className="orderSummaryProductLeftBox">
                  <h3>Order Value : </h3>
                  <h3>
                    Discount Code : <button>Code</button>
                  </h3>
                  <h3>Delivery : </h3>
                  <h3>Total : </h3>
                </div>
                <div className="orderSummaryProductRightBox">
                  <h3> Rs.176000</h3>
                  <h3> -Rs.0</h3>
                  <h3> Rs.750</h3>
                  <h3> Rs.176750</h3>
                </div>
              </div> */}
            </div>
            <h3 style={{ marginTop: "3rem", textAlign: "center" }}>
              Need help? Please Contact
              <span
                style={{
                  textDecoration: "underline",
                  marginLeft: "0.5rem",
                }}
              >
                Customer Service
              </span>
            </h3>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
{
  /* <span>
  <h2>PHONE NUMBER</h2>
  <input type="text" />
</span>
<span>
  <h2>EMAIL</h2>
  <input type="email" />
</span>
<span>
  <h2>PAN CARD NUMBER</h2>
  <input type="text" />
</span> */
}
//     .then((res) => res.json())
//     .then((res) => console.log(res))
//     .catch(console.log("Unable to connect"));
// };
// useEffect(() => console.log(data, []));
// let formData = new FormData();
// formData.append("FirstName", data.FirstName)
// const submitForm = (e) => {
//   e.preventDefault();
//   const sendData = {
//     FirstName: data.FirstName,
//     MiddleName: data.MiddleName,
//     LastName: data.LastName,
//   };
//    axios
//     .get("/api_ls_customer_insert.php", {
//       body: JSON.stringify({ FirstName: "VIPUL" }),
//     })
//     .then((result) => {
//       if (result.data.status == "Invalid") alert("Invalid Details");
//       else {
//         console.log("SUCCESSFUL");
//       }
//     });
// };
// const addName2 = () => {
//   var formData = new FormData();
//   formData.append("FirstName", JSON.stringify(data.FirstName));
//   formData.append("MiddleName", JSON.stringify(data.MiddleName));
//   formData.append("LastName", JSON.stringify(data.LastName));

//   console.log(formData);
// };
// function handleSubmit() {
//   e.preventDefault();
//   axios
//     .post(
//       "https://sunshineteam.in/loyalstring/api_ls_customer_insert.php",
//       data
//     )
//     // .then(() => JSON.stringify(data))
//     .then((res) => console.log(res))
//     .catch((err) => console.log(err));
// }

// var formData = new FormData();
// formData.append("FirstName", JSON.stringify(data.FirstName));
// formData.append("MiddleName", JSON.stringify(data.MiddleName));
// formData.append("LastName", JSON.stringify(data.LastName));
