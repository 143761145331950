import React, { useState, useRef } from "react";
import { a26, a8 } from "../../Api/RootApiPath";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import axios from "axios";
import { useSelector } from "react-redux";

export default function AdminAddProduct() {
  // const [entryBy_Staff_Id, setEntryBy_Staff_Id] = useState("1");
  const [category, setCategory] = useState("");
  const [productType, setProductType] = useState("");
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [purity, setPurity] = useState("");
  const [huid, setHuid] = useState("");
  const [productCode, setProductCode] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [collection, setCollection] = useState("");
  const [occasion, setOccasion] = useState("");
  const [gender, setGender] = useState("");
  const [featured, setFeatured] = useState("");
  const [statusType, setStatusType] = useState("");
  const [grosswt, setGrosswt] = useState("");
  const [stoneWeight, setStoneWeight] = useState("");
  const [netWt, setNetWt] = useState("");
  const [stoneAmount, setStoneAmount] = useState("");
  const [making_per_gram, setMaking_per_gram] = useState("");
  const [making_Fixed_Amt, setMaking_Fixed_Amt] = useState("");
  const [making_Percentage, setMaking_Percentage] = useState("");
  const [making_Fixed_Wastage, setMaking_Fixed_Wastage] = useState("");
  const [pieces, setPieces] = useState("");
  const [size, setSize] = useState("");
  const [mrp, setMRP] = useState("");

  const [imageList1, setImageList1] = useState([]);
  const [imageList2, setImageList2] = useState("null");
  const [imageList3, setImageList3] = useState("null");
  const [imageList4, setImageList4] = useState("null");
  const [imageList5, setImageList5] = useState("null");
  const [imagePreview1, setImagePreview1] = useState(null);
  const [imagePreview2, setImagePreview2] = useState(null);
  const [imagePreview3, setImagePreview3] = useState(null);
  const [imagePreview4, setImagePreview4] = useState(null);
  const [imagePreview5, setImagePreview5] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [productId, setProductId] = useState("");

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const adminLoggedIn = allStates.reducer5;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  let categoryId = "";

  if (category == "Gold" && purity == "24CT") {
    categoryId = 1;
  } else if (category == "Gold" && purity == "23CT") {
    categoryId = 2;
  } else if (category == "Gold" && purity == "22CT") {
    categoryId = 3;
  } else if (category == "Gold" && purity == "18CT") {
    categoryId = 4;
  } else if (category == "Gold" && purity == "14CT") {
    categoryId = 5;
  } else if (category == "Silver" && purity == "100%") {
    categoryId = 6;
  } else if (category == "Silver" && purity == "92%") {
    categoryId = 7;
  } else if (category == "Silver" && purity == "Reg") {
    categoryId = 8;
  } else if (category == "MRP") {
    categoryId = 9;
  } else {
    categoryId = 10;
  }
  if (imageList1.length > 5) {
    alert("Max 5 Files");
  }

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (5 > files.length > 0) {
      const newFiles = Array.from(files).slice(0, 5 - selectedFiles.length);
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };
  // const handleImageChange1 = (event) => {
  //   const file = event.target.files;
  // if (file && file.type.match(/image\/.*jpeg|png|gif/)) {
  // Check if file is an image of valid format
  // const reader = new FileReader();
  // reader.onloadend = () => {
  //   setImageList1(file);
  //   setImagePreview1(reader.result);
  // };
  // reader.readAsDataURL(file);
  // if (file.length > 0) {
  //   const newFiles = Array.from(file).slice(0, 5 - imageList1.length);
  //   setImageList1([...imageList1, ...newFiles]);
  // } else {
  //   alert("Max 5 Files Allowed");
  // }
  // } else {
  //   alert("Invalid file type. Only JPG, JPEG, and PNG files are allowed.");
  // }
  // };
  // console.log(imageList1);
  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    if (file && file.type.match(/image\/.*jpeg|png|gif/)) {
      // Check if file is an image of valid format
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageList2(file.name);
        setImagePreview2(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Invalid file type. Only JPG, JPEG, and PNG files are allowed.");
    }
  };
  const handleImageChange3 = (event) => {
    const file = event.target.files[0];
    if (file && file.type.match(/image\/.*jpeg|png|gif/)) {
      // Check if file is an image of valid format
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageList3(file.name);
        setImagePreview3(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Invalid file type. Only JPG, JPEG, and PNG files are allowed.");
    }
  };
  const handleImageChange4 = (event) => {
    const file = event.target.files[0];
    if (file && file.type.match(/image\/.*jpeg|png|gif/)) {
      // Check if file is an image of valid format
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageList4(file.name);
        setImagePreview4(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Invalid file type. Only JPG, JPEG, and PNG files are allowed.");
    }
  };
  const handleImageChange5 = (event) => {
    const file = event.target.files[0];
    if (file && file.type.match(/image\/.*jpeg|png|gif/)) {
      // Check if file is an image of valid format
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageList5(file.name);
        setImagePreview5(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Invalid file type. Only JPG, JPEG, and PNG files are allowed.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const formData = {
    //   Product_Name: productName,
    //   Product_Code: productCode,
    //   description: description,
    //   Entryby_Staff_id: Entryby_Staff_id,
    //   Product_No: "Product_No",
    //   Category_id: categoryId,
    //   Category_Name: category,
    //   // Category_id: categoryId,
    //   Pieces: parseInt(pieces),
    //   HUIDCode: huid,
    //   // itemCode: itemCode,
    //   NetWt: parseInt(netWt),
    //   // Size: size,
    //   grosswt: parseInt(grosswt),
    //   purity: purity,
    //   collection: collection,
    //   occasion: occasion,
    //   gender: gender,
    //   product_type: productType,
    //   Makingcharges: makingCharges,
    //   Makingtype: makingType,
    //   StoneWeight: stoneWeight,
    //   StoneAmount: stoneAmount,
    //   Featured: featured,
    //   // Images: imageList1,
    //   // ImageList1: imageList2,
    //   // ImageList2: imageList2,
    //   // ImageList3: imageList3,
    //   // ImageList4: imageList4,
    //   // ImageList5: imageList5,
    // };

    const formData = new FormData();

    // change

    formData.append("Product_Name", productName);
    formData.append("Product_Code", productCode);
    formData.append("description", description);
    formData.append("Entryby_Staff_id", Entryby_Staff_id);
    formData.append("Product_No", "Product_No");
    formData.append("Category_id", categoryId);
    formData.append("Category_Name", category);
    formData.append("Pieces", parseInt(pieces));
    formData.append("HUIDCode", huid);
    formData.append("NetWt", parseInt(netWt));
    formData.append("Size", size);
    formData.append("grosswt", parseInt(grosswt));
    formData.append("purity", purity);
    formData.append("collection", collection);
    formData.append("occasion", occasion);
    formData.append("gender", gender);
    formData.append("product_type", productType);
    formData.append("Making_Fixed_Amt", making_Fixed_Amt);
    formData.append("Making_per_gram", making_per_gram);
    formData.append("Making_Percentage", making_Percentage);
    formData.append("Making_Fixed_Wastage", making_Fixed_Wastage);
    formData.append("StoneWeight", stoneWeight);
    formData.append("StoneAmount", stoneAmount);
    formData.append("Featured", featured);
    // formData.append("Images", selectedFiles);
    formData.append("MRP", mrp);
    // formData.append("Images", "");
    selectedFiles.forEach((file) => {
      formData.append("Images", file);
    });
    formData.append("ImageList1", "");
    formData.append("ImageList2", "");
    formData.append("ImageList3", "");
    formData.append("ImageList4", "");
    formData.append("ImageList5", "");

    // upto Here

    // formData.append("StatusType", statusType);
    // selectedFiles.forEach((file, index) => {
    //   formData.append("Images", file);
    // });
    // formData.append("Images", selectedFiles);
    // console.log(formData);
    // axios
    //   .post(a8, formData)
    //   .then((response) => {
    //     console.log(response.data);
    //     alert("Added Successfully");
    //   })
    // console.log(a8);
    try {
      const response = await fetch(a8, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(formData),
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        setProductId(data.data.id);
        // updateImages();
        console.log(data);
        alert("added");
      } else {
        // Handle the error if the upload fails
        console.error("Failed to upload the files.");
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);
    }
  };
  // try {
  //   const response = await fetch(a8, {
  //     method: "POST",
  //     body: formData,
  //   });
  //   alert("Successfully Added");

  //   const data = await response.json();
  //   console.log(data);
  // } catch (error) {
  //   console.error(error);
  // }
  // console.log("Form submitted:", { image });

  const updateImages = async () => {
    event.preventDefault();

    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });

    try {
      const response = await fetch(`${a26}/${productId}`, {
        method: "PUT",
        body: formData,
      });

      if (response.ok) {
        alert("Product uploaded successfully");
        // setLoading(false);
        // console.log("Files uploaded successfully.");
        // setPopup(false);
        // alert(`${parameter} Changed Successfully`);
        // updateStaffId();
      } else {
        // Handle the error if the upload fails
        console.error("Failed to upload the files.");
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);
    }
  };

  // console.log();
  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        {/* <h1>Add Product</h1> */}
        <div className="adminAddCategoryMainbox addProductMain">
          <form onSubmit={handleSubmit}>
            <h3 style={{ marginBottom: "3rem" }}>ADD PRODUCT</h3>
            <div className="addProductDetailsBox">
              <label htmlFor="category">Category:</label>
              <select
                id="category"
                required="required"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                <option value="Gold">Gold</option>
                <option value="Silver">Silver</option>
                <option value="Platinum">Platinum</option>
                <option value="Diamond">Diamond</option>
                <option value="Pearl">Pearl</option>
                <option value="Colour Stone">Colour Stone</option>
                <option value="MRP">MRP</option>
              </select>
              <label htmlFor="parentCategory">Product Type:</label>
              <select
                id="parentCategory"
                required="required"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              >
                <option value="">Select Product Type</option>
                <option value="Rings">Rings</option>
                <option value="Chains">Chains</option>
                <option value="Anklets">Anklets</option>
                <option value="Bracelets">Bracelets</option>
                <option value="Kadas">Kadas</option>
                <option value="Pendant Chain">Pendant Chain</option>
                <option value="Coins">Coins</option>
                <option value="Bangles">Bangles</option>
                <option value="Earrings">Earrings</option>
                <option value="Mangalsutra">Mangalsutra</option>
                <option value="Necklace Set">Necklace Set</option>
                <option value="Nosepin">Nosepin</option>
              </select>
              <label htmlFor="productName">Product Name:</label>
              <input
                required="required"
                type="text"
                id="productName"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
              <label htmlFor="description">Product Description:</label>
              <textarea
                required="required"
                type="text"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <label htmlFor="purity">Purity:</label>
              {category == "Gold" ? (
                <select
                  id="purity"
                  value={purity}
                  onChange={(e) => setPurity(e.target.value)}
                >
                  <option value="">Select Purity</option>
                  <option value="24CT">24CT</option>
                  <option value="23CT">23CT</option>
                  <option value="22CT">22CT</option>
                  <option value="18CT">18CT</option>
                  <option value="14CT">14CT</option>
                  <option value="Other">Other</option>
                </select>
              ) : (
                <select
                  id="purity"
                  value={purity}
                  onChange={(e) => setPurity(e.target.value)}
                >
                  <option value="">Select Purity</option>
                  <option value="100%">100%</option>
                  <option value="92%">92%</option>
                  <option value="Reg">Reg</option>
                  <option value="Other">Other</option>
                  <option value="None">None</option>
                </select>
              )}
              <label htmlFor="huid">HUID Code:</label>
              <input
                type="text"
                id="huid"
                value={huid}
                onChange={(e) => setHuid(e.target.value)}
              />
              <label htmlFor="productCode">Product Code:</label>
              <input
                required="required"
                id="productCode"
                type="text"
                value={productCode}
                onChange={(e) => setProductCode(e.target.value)}
              />
              {/* <label htmlFor="itemCode">Item Code:</label>
              <input
                // required="required"
                id="itemCode"
                type="text"
                value={itemCode}
                onChange={(e) => setItemCode(e.target.value)}
              /> */}
              <label htmlFor="collection">Collection:</label>
              <select
                required="required"
                id="collection"
                value={collection}
                onChange={(e) => setCollection(e.target.value)}
              >
                <option value="">Select Collection</option>
                <option value="Regular">Regular</option>
                <option value="Classic">Classic</option>
                <option value="Trendy">Trendy</option>
                <option value="Festive">Festive</option>
                <option value="Regional">Regional</option>
                <option value="Religious">Religious</option>
                <option value="BirthStones">BirthStones</option>
                <option value="Antiques">Antiques</option>
                <option value="None">None</option>
              </select>
              <label htmlFor="occasion">Occasion:</label>
              <select
                required="required"
                id="occasion"
                value={occasion}
                onChange={(e) => setOccasion(e.target.value)}
              >
                <option value="">Select Occasion</option>
                <option value="Casual">Casual</option>
                <option value="Classic">Modern</option>
                <option value="Engagement">Engagement</option>
                <option value="Bridal">Bridal</option>
                <option value="Valentines">Valentines</option>
                <option value="Office">Office</option>
                <option value="Gifts">Gifts</option>
                <option value="Other">Other</option>
                <option value="None">None</option>
              </select>
              <label htmlFor="gender">Gender:</label>
              <select
                required="required"
                id="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="">Select Gender for</option>
                <option value="Men">Men</option>
                <option value="Women">Women</option>
                <option value="Kids">Kids</option>
                <option value="Unisex">Unisex</option>
              </select>
              <label htmlFor="featured">Featured:</label>
              <select
                required="required"
                id="featured"
                value={featured}
                onChange={(e) => setFeatured(e.target.value)}
              >
                <option value="">Select Featured</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <label htmlFor="onlineStatus">Online Status:</label>
              <select
                required="required"
                id="onlineStatus"
                value={statusType}
                onChange={(e) => setStatusType(e.target.value)}
              >
                <option value="">Select Status Type</option>
                <option value="active">active</option>
                <option value="inactive">inactive</option>
              </select>
              <label htmlFor="grosswt">Gross Weight (Gram):</label>
              <input
                type="number"
                id="grosswt"
                value={grosswt}
                onChange={(e) => setGrosswt(e.target.value)}
              />
              <label htmlFor="stoneWeight">Stone Weight (Gram):</label>
              <input
                type="number"
                id="stoneWeight"
                value={stoneWeight}
                onChange={(e) => setStoneWeight(e.target.value)}
              />
              <label htmlFor="netWt">Net Weight (Gram):</label>
              <input
                type="number"
                id="netWt"
                value={netWt}
                onChange={(e) => setNetWt(e.target.value)}
              />
              <label htmlFor="stoneAmount">Stone Amount:</label>{" "}
              <input
                type="number"
                id="stoneAmount"
                value={stoneAmount}
                onChange={(e) => setStoneAmount(e.target.value)}
              />
              <label htmlFor="pieces">Pieces:</label>
              <input
                type="text"
                id="pieces"
                value={pieces}
                onChange={(e) => setPieces(e.target.value)}
              />{" "}
              <label htmlFor="size">Size:</label>
              <input
                type="text"
                id="size"
                value={size}
                onChange={(e) => setSize(e.target.value)}
              />
              <label htmlFor="mrp">MRP:</label>
              <input
                type="number"
                id="mrp"
                value={mrp}
                onChange={(e) => setMRP(e.target.value)}
              />
              {/* <div className="makingBoxAddProduct"> */}
              {/* <div> */}
              <label htmlFor="making_per_gram">Making Per Gram:</label>
              <input
                type="number"
                placeholder="Enter Value"
                id="making_per_gram"
                value={making_per_gram}
                onChange={(e) => setMaking_per_gram(e.target.value)}
              />
              {/* <select
                id="making_per_gram"
                value={making_per_gram}
                onChange={(e) => setMaking_per_gram(e.target.value)}
              >
                <option value="">Select Making Type</option>
                <option value="Percetage">Percentage</option>
                <option value="Gram">Gram</option>
                <option value="Fixed">Fixed</option>
              </select> */}
              {/* </div> */}
              {/* <div> */}
              <label htmlFor="making_Percentage">Making Percentage:</label>
              <input
                id="making_Percentage"
                type="number"
                placeholder="Enter Value"
                value={making_Percentage}
                onChange={(e) => setMaking_Percentage(e.target.value)}
              />
              <label htmlFor="making_Fixed_Amt">Fixed Making:</label>
              <input
                id="making_Fixed_Amt"
                type="number"
                placeholder="Enter Value"
                value={making_Fixed_Amt}
                onChange={(e) => setMaking_Fixed_Amt(e.target.value)}
              />
              <label htmlFor="making_Fixed_Wastage">Fixed Wastage:</label>
              <input
                id="making_Fixed_Wastage"
                type="number"
                placeholder="Enter Value"
                value={making_Fixed_Wastage}
                onChange={(e) => setMaking_Fixed_Wastage(e.target.value)}
              />
              {/* </div> */}
              {/* </div>   */}
              <label style={{ cursor: "pointer" }} htmlFor="images">
                Images :
              </label>
              <input
                multiple
                // accept="image/*"
                id="images"
                type="file"
                onChange={handleFileInputChange}
              />
              {/* <label htmlFor="imageList2">Image 2:</label>
              <input type="file" onChange={handleImageChange2} />
              <label htmlFor="imageList3">Image 3:</label>
              <input type="file" onChange={handleImageChange3} />
              <label htmlFor="imageList4">Image 4:</label>
              <input type="file" onChange={handleImageChange4} />
              <label htmlFor="imageList5">Image 5:</label>
              <input type="file" onChange={handleImageChange5} /> */}
            </div>
            <button type="submit">Submit</button>
            {/* <div className="previewBox">
              <div className="mainPreviewImage">
                {imagePreview1 && (
                  <img
                    style={{
                      width: "300px",
                      margin: "2rem",
                    }}
                    src={imagePreview1}
                    alt="Preview1"
                  />
                )}
              </div>
              <div className="smallPreviewImages">
                {imagePreview2 && (
                  <img
                    style={{
                      width: "60px",
                      height: "60px",
                      margin: "0.5rem",
                      marginLeft: "3rem",
                    }}
                    src={imagePreview2}
                    alt="Preview2"
                  />
                )}
                {imagePreview3 && (
                  <img
                    style={{
                      width: "60px",
                      height: "60px",
                      margin: "0.5rem",
                    }}
                    src={imagePreview3}
                    alt="Preview3"
                  />
                )}
                {imagePreview4 && (
                  <img
                    style={{
                      width: "60px",
                      height: "60px",
                      margin: "0.5rem",
                    }}
                    src={imagePreview4}
                    alt="Preview4"
                  />
                )}
                {imagePreview5 && (
                  <img
                    style={{
                      width: "60px",
                      height: "60px",
                      margin: "0.5rem",
                    }}
                    src={imagePreview5}
                    alt="Preview1"
                  />
                )}
              </div>
            </div> */}
          </form>

          <div></div>
          {/* <div
            style={{
              width: "60%",
              textAlign: "left",
              margin: "2rem",
              padding: "2rem",
              backgroundColor: "white",
            }}
          >

            <h1>{productName}</h1>
         
            <div className="previewBox">
              <div className="mainPreviewImage">
                {imagePreview1 && (
                  <img
                    style={{
                      width: "500px",

                      marginBottom: "1rem",
                    }}
                    src={imagePreview1}
                    alt="Preview1"
                  />
                )}
              </div>
              <div className="smallPreviewImages">
                {imagePreview2 && (
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      margin: "0.5rem",
                    }}
                    src={imagePreview2}
                    alt="Preview2"
                  />
                )}
                {imagePreview3 && (
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      margin: "0.5rem",
                    }}
                    src={imagePreview3}
                    alt="Preview3"
                  />
                )}
                {imagePreview4 && (
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      margin: "0.5rem",
                    }}
                    src={imagePreview4}
                    alt="Preview4"
                  />
                )}
                {imagePreview5 && (
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      margin: "0.5rem",
                    }}
                    src={imagePreview5}
                    alt="Preview1"
                  />
                )}
              </div>
            </div>
            <div className="previewBoxData">
              <h3>
                CategoryID : <span>{categoryId}</span>
              </h3>
              <h3>
                Category : <span>{category}</span>
              </h3>
              <h3>
                Product Type : <span>{productType}</span>
              </h3>
              <h3>
                Product Name : <span> {productName}</span>
              </h3>
              <h3>
                Product Description : <span> {description}</span>
              </h3>
              <h3>
                Purity : <span>{purity}</span>
              </h3>
              <h3>
                HUID Code: <span>{huid}</span>
              </h3>
              <h3>
                Product Code: <span>{productCode}</span>
              </h3>
              <h3>
                Collection : <span>{collection}</span>
              </h3>
              <h3>
                Occasion : <span>{occasion}</span>
              </h3>
              <h3>
                Gender : <span>{gender}</span>
              </h3>
              <h3>
                Featured : <span>{featured}</span>
              </h3>
              <h3>
                Status : <span>{statusType}</span>
              </h3>
              <h3>
                Gross weight : <span>{grosswt}</span>
              </h3>
              <h3>
                Stone weight : <span>{stoneWeight}</span>
              </h3>
              <h3>
                Net weight : <span>{netWt}</span>
              </h3>
              <h3>
                Stone Amount : <span>{stoneAmount}</span>
              </h3>
              <h3>
                Making Type : <span> {makingType}</span>
              </h3>
              <h3>
                Making Charges : <span>{makingCharges}</span>
              </h3>
              <h3>
                Pieces : <span>{pieces}</span>
              </h3>
              <h3>
                Size : <span> {size}</span>
              </h3>
              <h3>
                MRP : <span> {mrp}</span>
              </h3>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
