import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { s1, s3 } from "../../Api/RootApiPath";
import tempImg from "../../Images/blankProduct.jpg";
import Footer from "../../Footer/Footer";
import { BsBagX } from "react-icons/bs";
import Heading from "../../Heading/Heading";
import { AiFillLock } from "react-icons/ai";
import { removeFromCart } from "../../../redux/action/Actions";
import { useNavigate } from "react-router-dom";

export default function CheckOutMain() {
  const [productCount, setProductCount] = useState(1);
  useEffect(() => window.scrollTo(0, 0), []);
  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const userDetails = orderState.reducer4;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRemoveFromBag = (index) => {
    dispatch(removeFromCart(index));
  };
  let totalPrice = 0;
  let saved = 0;
  let discount = 0;
  let deliveryCharge = 0;
  return (
    <div className="checkOutMainBox">
      <div className="headingBox">
        <Heading />
      </div>
      <>
        <div className="checkoutMainContainer">
          <div className="allProductOrderMainCheckoutBox">
            <h4 style={{ margin: "1rem" }}>
              ** Delivery ONLY available within MUMBAI & Suburbs for Now **
            </h4>
            <h3 style={{ marginLeft: "1rem" }}>My Bag</h3>
            {orderItems.length === 0 ? (
              <h1 style={{ color: "rgba(0,0,0,0.5" }}>
                Please Add Something in Bag...
              </h1>
            ) : null}
            {orderItems.map((x) => {
              totalPrice += x.finalPrice * x.count;
              return (
                <div className="orderSummaryCheckoutMain">
                  <div className="orderSummaryCheckoutImageBox">
                    {/* <> */}
                    <img
                      className="orderSummaryCheckoutImage"
                      src={`${s3}/${x.image}`}
                      // src={tempImg}
                      alt={"ProductImage"}
                    />
                    <div className="orderSummaryCheckoutProductDetailsBox">
                      <p>{x.title}</p>
                      {/* <p>Product title</p> */}
                      {/* <p>Product Id</p> */}
                      <p>{x.Product_id}</p>
                      <span
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            margin: "0.5rem",
                            marginLeft: "1rem",
                          }}
                        >
                          <strong>Size: </strong>
                          <select>
                            {/* <option>{x.size}</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option> */}
                            <option>N.A</option>
                          </select>

                          <strong>Quantity: </strong>
                          <select>
                            <option>{x.count}</option>
                            {/* <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option> */}
                          </select>
                        </p>
                      </span>
                      <p style={{ color: "green" }}>
                        <strong>₹ {(x.finalPrice * x.count).toFixed(2)}</strong>
                      </p>
                    </div>
                  </div>

                  {/* {orderItems.map((x) => {
              return (
                  <div className="orderSummaryCheckoutImageBox">
                  <img
                  className="orderSummaryCheckoutImage"
                  //   src={`${s1}${x.image1}`}
                  src={tempImg}
                  alt={"ProductImage"}
                  />
                  <div className="orderSummaryCheckoutProductDetailsBox">
                  <p>{x.title}</p>
                  <p>{x.Product_id}</p>
                  <span
                  style={{ alignItems: "center", justifyContent: "center" }}
                  >
                  <p style={{ color: "black", margin: "1rem" }}>
                    <strong>Size: {x.Size}</strong>
                    <select>
                    <option>First</option>
                      <option>Second</option>
                      <option>Third</option>
                      <option>Fourth</option>
                      </select>
                      </p>
                      </span>
                      </div>
                      </div>
                      );
                    })} */}
                  <div className="orderSummaryCheckoutProductDetailsButtons">
                    <div className="btnBox1">
                      <button
                        id="redRemoveBtn"
                        onClick={() => handleRemoveFromBag(x)}
                      >
                        Remove
                      </button>
                    </div>
                    <div className="btnBox1">
                      <button>Move to Wishlist</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="orderSummaryCheckoutRightContainer">
            <div className="applyCouponBox"></div>
            <h3 style={{ marginLeft: "1rem" }}>Order Summary</h3>
            <div
              style={{ paddingBottom: "0px" }}
              className="orderSummaryCheckoutMain"
            >
              <div className="orderSummaryCheckoutMainBox">
                <div className="box1">
                  <p>Subtotal</p>
                  <p>You Saved</p>
                  <p>Coupon Discount</p>
                  <p>Delivery Charge</p>
                  <p>TOTAL COST</p>
                </div>

                <div className="box2">
                  <p>₹ {totalPrice.toFixed(2)}</p>
                  <p>₹ {saved}</p>
                  <p>₹ {discount}</p>
                  <p>₹ {deliveryCharge}</p>
                  <p>
                    ₹{" "}
                    {(totalPrice - saved - discount - deliveryCharge).toFixed(
                      2
                    )}
                  </p>
                </div>
              </div>
              <button
                // className="checkoutSecurelyBtn"
                className="checkoutNewLoginFormBoxButton"
                onClick={() =>
                  orderItems.length === 0
                    ? alert("Please Add Something in My Bag")
                    : userDetails.length === 0
                    ? //  navigate("/checkoutpage2")
                      navigate("/checkoutpagenewlogin")
                    : //  navigate("/deliverypage")
                      navigate("/checkoutpagenew/o2")
                }
              >
                <AiFillLock id="lockIcon" style={{ marginRight: "0.5rem" }} />
                Checkout Securely
              </button>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
}
