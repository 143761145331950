import React, { useEffect, useState } from "react";
import { a3, a5 } from "../../Api/RootApiPath";
import { userLoggedIn, userLoggedOut } from "../../../redux/action/Actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
export default function CustomerDetailsMobile({ csData }) {
  const [detailsType, setDetailsType] = useState("Dashboard");
  const [popUp, setPopup] = useState(false);
  const [detailsDrawer, setDetailsDrawer] = useState(false);
  const [parameter, setParameter] = useState("");
  const [formValue, setFormValue] = useState("");
  const [logOut, setLogOut] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedOut = (index) => {
    dispatch(userLoggedOut(index));
  };

  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  const csStates = useSelector((state) => state);
  const userDetails = csStates.reducer4[0];

  const [customerData, setCustomerData] = useState(userDetails);
  // console.log(csStates);
  // console.log(userDetails);
  const updatedetails = (entry) => {
    setPopup(true);
  };
  const updatedetailsBox = async (parameter) => {
    const formData = new FormData();
    formData.append("Customer_id", csData.Customer_id);
    formData.append("FieldName", parameter);
    formData.append("FieldValue", formValue);
    try {
      const response = await fetch(a5, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status == "success") {
        console.log(data);
        setPopup(false);
        // alert(`${parameter} Changed Successfully`);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dashBoard = () => {
    return (
      <div className="detailsBox">
        <h3 style={{ marginBottom: "2rem" }}>
          Hello {csData.FirstName} {csData.MiddleName} {csData.LastName}{" "}
        </h3>
        <h4 style={{ marginBottom: "1rem" }}>
          Login Id : {csData.Customer_login_id}
        </h4>
        <p>
          From your account dashboard you can view your recent orders, manage
          your billing address, and edit your password and account details.
        </p>
        <div className="createdOnBox createdOnBox2">
          <p style={{ marginBottom: "0.5rem" }}>
            Last Updated On : {csData.LastUpdated}
          </p>
          <p style={{ marginBottom: "0.5rem" }}>
            Created On : {csData.CreatedOn}
          </p>
        </div>
      </div>
    );
  };

  const orderUpdated = async (customerId) => {
    const formData = new FormData();
    formData.append("Customer_id", customerId);

    try {
      const response = await fetch(a3, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      loggedOut(userDetails),
        setDetailsDrawer(true),
        loggedIn(data.data[0]),
        setDetailsType("Orders");
      window.scrollTo(850, 850);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  const orders = () => {
    return (
      <div className="detailsBox">
        <h3 style={{ marginBottom: "2rem" }}>Your Orders</h3>
        <h4 style={{ marginBottom: "1rem" }}>
          Total Orders : {csData.OrderCount}
        </h4>

        <h4 style={{ marginBottom: "1rem" }}>
          Total Orders Value :{csData.OrderValue}
        </h4>
        <h4 style={{ marginBottom: "1rem" }}>
          Your Orders ID : {csData.OrderId}
        </h4>
        <h4 style={{ marginBottom: "1rem" }}>
          Your Orders Status : {csData.OrderStatus}
        </h4>

        <div className="createdOnBox">
          <p style={{ marginBottom: "0.5rem" }}>
            Last Updated On : {csData.LastUpdated}
          </p>
          <p style={{ marginBottom: "0.5rem" }}>
            Created On : {csData.CreatedOn}
          </p>
        </div>
      </div>
    );
  };
  const addresses = () => {
    return (
      <div className="detailsBox">
        <h3 style={{ marginBottom: "2rem" }}>Your Addresses</h3>
        <h3 style={{ marginBottom: "0.5rem" }}>Delivery Address :</h3>
        <h4 style={{ marginBottom: "2rem" }}>{csData.CurrAdd}</h4>
        <h3 style={{ marginBottom: "0.5rem" }}>Permanent Address :</h3>
        <h4 style={{ marginBottom: "2rem" }}>{csData.PerAdd}</h4>

        <div className="createdOnBox">
          <p style={{ marginBottom: "0.5rem" }}>
            Last Updated On : {csData.LastUpdated}
          </p>
          <p style={{ marginBottom: "0.5rem" }}>
            Created On : {csData.CreatedOn}
          </p>
        </div>
      </div>
    );
  };
  const accountDetails = () => {
    return (
      <div className="detailsBox detailsBoxMobileView">
        <h3 style={{ marginBottom: "2rem" }}>Update Your Account Details</h3>
        <div className="updateAccountDetailsMainBox ">
          <h3 style={{ marginBottom: "0.5rem" }}>First Name :</h3>
          <h4>{csData.FirstName}</h4>
          <button
            onClick={() => {
              setParameter("FirstName");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Middle Name :</h3>
          <h4>{csData.MiddleName}</h4>
          <button
            onClick={() => {
              setParameter("MiddleName");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Last Name :</h3>
          <h4>{csData.LastName}</h4>
          <button
            onClick={() => {
              setParameter("LastName");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Mobile :</h3>
          <h4>{csData.Mobile}</h4>
          <button
            onClick={() => {
              setParameter("Mobile");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Email :</h3>
          <h4>{csData.Email}</h4>
          <button
            onClick={() => {
              setParameter("Email");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Password :</h3>
          <h4>{/* {csData.Password} */}********</h4>
          <button
            onClick={() => {
              setParameter("Password");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Pin Code :</h3>
          <h4>{csData.PinCode}</h4>
          <button
            onClick={() => {
              setParameter("PinCode");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Delivery Address :</h3>
          <h4>{csData.CurrAdd}</h4>
          <button
            onClick={() => {
              setParameter("CurrAdd");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
          <h3 style={{ marginBottom: "0.5rem" }}>Permanent Address :</h3>
          <h4>{csData.PerAdd}</h4>
          <button
            onClick={() => {
              setParameter("PerAdd");
              updatedetails();
            }}
            style={{ marginInline: "1rem" }}
          >
            Update
          </button>
        </div>
        {/* <h4 style={{ marginBottom: "2rem" }}>{csData.CurrAdd}</h4>
        <h3 style={{ marginBottom: "0.5rem" }}>Permanent Address :</h3>
        <h4 style={{ marginBottom: "2rem" }}>{csData.PerAdd}</h4> */}

        <div className="createdOnBox">
          {/* <p style={{ marginBottom: "0.5rem" }}>
            Last Updated On : {csData.LastUpdated}
          </p>
          <p style={{ marginBottom: "0.5rem" }}>
            Created On : {csData.CreatedOn}
          </p> */}
        </div>
      </div>
    );
  };
  return (
    <div>
      <h1 style={{ marginTop: "7rem", color: "#1a4c2d" }}>{detailsType}</h1>
      <div className="mobileNavbarBottom customerProfileBox">
        <h3
          onClick={() => {
            setDetailsDrawer(true),
              setDetailsType("Dashboard"),
              window.scrollTo(850, 850);
          }}
        >
          Dashboard
        </h3>

        <h3 onClick={() => orderUpdated(csData.Customer_id)}>Orders</h3>
        <h3
          onClick={() => {
            setDetailsDrawer(true),
              setDetailsType("Addresses"),
              window.scrollTo(850, 850);
          }}
        >
          Addresses
        </h3>
        <h3
          onClick={() => {
            setDetailsDrawer(true),
              setDetailsType("Account Details"),
              window.scrollTo(850, 850);
          }}
        >
          Account Details
        </h3>
        <h3
          onClick={() => {
            setDetailsDrawer(true);
            setDetailsType("Logout"),
              setLogOut(true),
              window.scrollTo(850, 850);
          }}
        >
          Logout
        </h3>
      </div>
      <div
        className={
          detailsDrawer === true ? "customerDetailsMobileDrawer" : "none"
        }
      >
        <div
          className={
            popUp == true
              ? "updateAccountDetailsPopupMainBox updateAccountDetailsPopupMainBox2"
              : "none"
          }
        >
          <div className="updateAccountDetailsPopupCloseBtn">
            <AiOutlineClose size={"3rem"} onClick={() => setPopup(false)} />
          </div>
          <h1 style={{ color: "rgba(0,0,0,0.5)" }}>Change {parameter} </h1>
          <input type="text" onChange={(e) => setFormValue(e.target.value)} />
          <button onClick={() => updatedetailsBox(parameter)}>Submit</button>
        </div>
        <div
          className={
            logOut == true
              ? "updateAccountDetailsPopupMainBox updateAccountDetailsPopupMainBox2"
              : "none"
          }
        >
          <div className="updateAccountDetailsPopupCloseBtn">
            <AiOutlineClose size={"3rem"} onClick={() => setLogOut(false)} />
          </div>
          <h1 style={{ color: "rgba(0,0,0,0.5)" }}> Are You Sure ?</h1>
          <h1> Log out </h1>
          <button
            onClick={() => {
              loggedIn([]), navigate("/");
            }}
          >
            Submit
          </button>
        </div>
        {detailsType == "DashBoard"
          ? dashBoard()
          : detailsType == "Orders"
          ? orders()
          : detailsType == "Addresses"
          ? addresses()
          : detailsType == "Account Details"
          ? accountDetails()
          : dashBoard()}
      </div>
      <div className={popUp || logOut === true ? "new" : "new2"}></div>
    </div>
  );
}
