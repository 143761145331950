import React, { useState } from "react";
import "../Components Styles/Heading.css";
import UpperTagLine from "./UpperTagLine";
// import Logo from "../Images/MainLogo5.png";
import Logo from "../Images/gharesNewLogo4.png";
// import { GoSearch } from "react-icons/go";
// import img1 from "../Images/Caraousal_Images/Trending1.jpg";
import { IoCloseSharp, IoHeartOutline } from "react-icons/io5";
// import { GrLocation } from "react-icons/gr";
import { CiUser } from "react-icons/ci";
import { BsHandbag } from "react-icons/bs";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";
import { useSelector } from "react-redux";
// rgba(251,225,183,255) beige
// rgba(26,76,44,255) Dark Green
// rgba(241,179,29,255) Dark Yellow
export default function Heading() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const allGoldCategoriesList = [
    "Gold Coins",
    "Gold Kadas",
    "Gold Mangalsutra",
    // "Mala",
    // "Earrings",
    // "Bangles",
    // "Rings",
    // "Kada",
    // "Mangalsutra",
    // "Pendants",
    // "Chain",
    // "Specially Designed",
  ];
  const allSilverCategoryList = [
    "Anklet",
    "Bracelet",
    "Chain",
    "Kada",
    "Earrings",
    "Studs",
    "Couple Rings",
    "Fancy Pendant + Chain",
  ];
  const bagItems = useSelector((state) => state);
  // console.log(bagItems);
  const uniqueItem = bagItems.reducer2;
  const userDetails = bagItems.reducer4;
  const totalItems = bagItems.reducer2.length;

  return (
    <div className="headingMainContainer">
      <UpperTagLine />
      <div className="logoBar">
        <Link to="/">
          {/* <div id="mainLogo"></div> */}
          <span>
            <img
              style={{ width: "auto" }}
              id="mainLogo"
              src={Logo}
              alt="M K Ghare logo"
              onClick={() => console.log("Takeme to Home")}
            />
          </span>
        </Link>

        {/* Mobile View Hamburgr */}
        <div className="hamburger" onClick={() => setMobileMenu(!mobileMenu)}>
          <GiHamburgerMenu size={"1.75rem"} className="hamburgerIcon" />
        </div>
        <div className="mobileIcons">
          {userDetails === null || userDetails.length === 0 ? (
            <div className="signIn">
              <CiUser size={"1rem"} />
              <span className="link" style={{ paddingLeft: "0.5rem" }}>
                {/* <Link to="/accountdetails">SIGN IN</Link> */}
                <Link to="/customerloginpage">SIGN IN</Link>
              </span>
            </div>
          ) : (
            <div className="signIn">
              <CiUser size={"1rem"} />
              <span className="link" style={{ paddingLeft: "0.5rem" }}>
                {/* <Link to="/accountdetails">SIGN IN</Link> */}
                <Link to={`/customerprofile?customerId=${userDetails[0]?.id}`}>
                  {`${userDetails[0]?.firstName} ${userDetails[0]?.lastName}`}
                </Link>
              </span>
            </div>
          )}

          {/* <CiUser size={"1.7rem"} /> */}
          {/* <IoHeartOutline size={"1.7rem"} /> */}
          <p>{totalItems}</p>
          <Link to="/checkoutmain">
            <BsHandbag id="handBagIconMobile" size={"1.5rem"} />
          </Link>
        </div>
      </div>
      {/* Mobile View List  */}
      <div
        className={
          mobileMenu === false ? "mobileMenuList hide" : "mobileMenuList"
        }
      >
        <MobileNavbar
          goldList={allGoldCategoriesList.map((item, index) => ({
            name: item,
            key: index,
          }))}
          silverList={allSilverCategoryList.map((item, index) => ({
            name: item,
            key: index,
          }))}
        />
        <button
          className="closeBtn"
          onClick={() => {
            setMobileMenu(!mobileMenu);
          }}
        >
          <AiOutlineClose color="white" />
        </button>
        {/* <div className="drawerScreenMain"> */}
        <div
          className={
            mobileMenu === false ? "drawerScreen hide" : "drawerScreen"
          }
          onClick={() => setMobileMenu(!mobileMenu)}
        ></div>
        {/* </div> */}
      </div>

      {/* NavBar has 3 components in flex row searchAndStore, links and favAndBag */}

      {/* <DesktopNavbar
        goldList={allGoldCategoriesList}
        silverList={allSilverCategoryList}
      /> */}

      <DesktopNavbar
        goldList={allGoldCategoriesList.map((item, index) => ({
          name: item,
          key: index,
        }))}
        silverList={allSilverCategoryList.map((item, index) => ({
          name: item,
          key: index,
        }))}
      />
    </div>
  );
}
