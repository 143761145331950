import React, { useEffect, useState } from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useLocation } from "react-router-dom";
import { a17, a24, s1, a26, s3, a43, a31 } from "../../Api/RootApiPath";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import { BsImages } from "react-icons/bs";
import MyPDF from "./AdminComponent/AdminProductLabel";

export default function AdminProductDetails() {
  const [data, setData] = useState("");
  const [popUp, setPopup] = useState(false);
  const [parameter, setParameter] = useState("");
  const [formValue, setFormValue] = useState("");
  const [placeHolder, setPlaceHolder] = useState("");
  const [loading, setLoading] = useState(false);
  const [qr, setQr] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [rfidData, setRfidData] = useState([]);
  const [barcodeChangeButton, setBarcodeChangeButton] = useState(false);

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const adminLoggedIn = allStates.reducer5;
  let Entryby_Staff_id = parseInt(adminLoggedIn);
  // console.log(Entryby_Staff_id);
  const location = useLocation();
  let allImages = "";
  let params = "";
  params = new URLSearchParams(location.search);
  let productId = "NA";
  productId = params.get("productId");
  const searchProduct = async () => {
    // const formData = new FormData();
    // formData.append("Product_id", productId);
    const formData = {
      Id: productId,
    };
    try {
      const response = await fetch(a17, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // if (data.status == "success") {
      //   setData(data.data);
      // } else {
      //   console.error(data.error);
      // }
      setData(data.data);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
    }
  };
  if (data != "" && data.images) {
    allImages = data.images.split(",");
  } else allImages = "No Data Found";
  const updatedetails = (entry) => {
    setPopup(true);
    if (entry === "image") {
      setPopup("imageRequested");
    }
  };
  const updatedetailsBox = async (parameter) => {
    const formData = {
      Id: productId,
      [parameter]: formValue,
    };
    // const formData = new FormData();
    // formData.append("Product_id", productId);
    // formData.append("FieldName", parameter);
    // formData.append("FieldValue", formValue);
    try {
      const response = await fetch(a24, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // if (data.status == "success") {
      //   console.log(data);
      //   setPopup(false);
      //   alert(`${parameter} Changed Successfully`);
      // } else {
      //   console.error(data.error);
      // }
      // setLoading(false);
      // setPopup(false);
      // alert(`${parameter} Changed Successfully`);
      updateStaffId();
    } catch (error) {
      console.error(error);
    }
  };
  const updateStaffId = async () => {
    const formData = {
      Id: productId,
      Entryby_Staff_id: Entryby_Staff_id,
    };
    // const formData = new FormData();
    // formData.append("Product_id", productId);
    // formData.append("FieldName", parameter);
    // formData.append("FieldValue", formValue);
    try {
      const response = await fetch(a24, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // if (data.status == "success") {
      //   console.log(data);
      //   setPopup(false);
      //   alert(`${parameter} Changed Successfully`);
      // } else {
      //   console.error(data.error);
      // }
      setLoading(false);
      setPopup(false);
      alert(`${parameter} Changed Successfully`);
    } catch (error) {
      console.error(error);
    }
  };
  const updateImagesBox = async (parameter) => {
    // const formData = {
    //   [parameter]: formValue,
    // };
    const formData = new FormData();
    formData.append("Images", formValue);
    // formData.append("Product_id", productId);
    // formData.append("FieldName", parameter);
    // formData.append("FieldValue", formValue);
    try {
      const response = await fetch(`${a26}/${productId}`, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "PUT",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(formData),
        body: formData,
      });
      const data = await response.json();
      // if (data.status == "success") {
      //   console.log(data);
      //   setPopup(false);
      //   alert(`${parameter} Changed Successfully`);
      // } else {
      //   console.error(data.error);
      // }
      setPopup(false);
      alert(`${parameter} Changed Successfully`);
    } catch (error) {
      console.error(error);
    }
  };
  let productData = [data];
  console.log(productData);
  // const handleFileInputChange = (event) => {
  //   const files = event.target.files;
  //   if (files.length > 5) {
  //     alert("You can select up to 5 files.");
  //     event.target.value = null; // Reset the file input
  //     return;
  //   }

  //   // Handle the selected files as desired
  //   else setFormValue(files);
  //   console.log(formValue);
  //   // updateImagesBox(parameter);
  // };
  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (5 > files.length > 0) {
      const newFiles = Array.from(files).slice(0, 5 - selectedFiles.length);
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };
  const handleFileSubmit = async () => {
    // event.preventDefault();

    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });
    console.log(formData, "formData", productId);
    try {
      const response = await fetch(`${a26}/${productId}`, {
        method: "PUT",
        body: formData,
      });

      if (response.ok) {
        // Files uploaded successfully
        // setLoading(false);
        // console.log("Files uploaded successfully.");
        // setPopup(false);
        // alert(`${parameter} Changed Successfully`);
        updateStaffId();
      } else {
        // Handle the error if the upload fails
        console.error("Failed to upload the files.");
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);
    }
  };

  const getRfidData = async () => {
    try {
      fetch(a43)
        .then((res) => res.json())
        .then((data) => setRfidData(data.data));
    } catch (error) {
      console.error(error);
    }
  };
  console.log(rfidData);
  useEffect(() => {
    searchProduct();
  }, [popUp]);
  useEffect(() => {
    getRfidData();
  }, [popUp]);

  useEffect(() => {
    QRCode.toDataURL(data.itemCode).then(setQr);
  }, [data]);

  const printPDF = async (products) => {
    const doc = new jsPDF({
      format: [29, 12],
      orientation: "landscape",
    });

    const fontSize = 6;
    const imageHeight = 7;
    const imageWidth = 7;

    for (let i = 0; i < products.length; i++) {
      const {
        collection,
        grosswt,
        stoneWeight,
        stoneAmount,
        netWt,
        itemCode,
        purity,
        mrp,
        product_No,
        pieces,
        making_Fixed_Wastage,
        making_Percentage,
      } = products[i];

      // console.log("products", products);
      if (i > 0) {
        doc.addPage(); // Add a new page for each product after the first one
      }

      doc.setFontSize(fontSize);
      doc.setFont("helvetica", "bold");
      // {
      //   collection.length > 20
      //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
      //     : doc.text(`${collection}`, 1, 3);
      // }

      if (mrp == 0 || mrp === "") {
        doc.text(`G.WT: ${parseFloat(grosswt).toFixed(3)}`, 3, 3);
        doc.text(`S.WT: ${parseFloat(stoneWeight).toFixed(3)}`, 3, 5.5);
        if (
          parseFloat(making_Percentage) !== 0 &&
          making_Percentage !== "" &&
          making_Fixed_Wastage !== 0 &&
          making_Fixed_Wastage !== ""
        ) {
          doc.text(
            `W.WT: ${(
              parseFloat(netWt) / parseFloat(making_Percentage) +
              parseFloat(making_Fixed_Wastage)
            ).toFixed(3)}`,
            3,
            7.5
          );
          doc.text(
            `N.WT: ${(
              parseFloat(netWt) +
              parseFloat(netWt / making_Percentage) +
              parseFloat(making_Fixed_Wastage)
            ).toFixed(3)}`,
            3,
            10
          );

          doc.text(`${itemCode}`, 18, 3);
          doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
          doc.text(`${purity}`, 18, 7.5);
          doc.text(`Pc:${pieces}`, 18, 10);
        } else if (
          parseFloat(making_Percentage) !== 0 &&
          making_Percentage !== ""
        ) {
          doc.text(
            `W.WT: ${(
              parseFloat(netWt) / parseFloat(making_Percentage)
            ).toFixed(3)}`,
            3,
            7.5
          );
          doc.text(
            `N.WT: ${(
              parseFloat(netWt) + parseFloat(netWt / making_Percentage)
            ).toFixed(3)}`,
            3,
            10
          );

          doc.text(`${itemCode}`, 18, 3);
          doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
          doc.text(`${purity}`, 18, 7.5);
          doc.text(`Pc:${pieces}`, 18, 10);
        } else if (making_Fixed_Wastage !== 0 && making_Fixed_Wastage !== "") {
          doc.text(
            `W.WT: ${parseFloat(making_Fixed_Wastage).toFixed(3)}`,
            3,
            7.5
          );
          doc.text(
            `N.WT: ${(
              parseFloat(making_Fixed_Wastage) + parseFloat(netWt)
            ).toFixed(3)}`,
            3,
            10
          );

          doc.text(`${itemCode}`, 18, 3);
          doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
          doc.text(`${purity}`, 18, 7.5);
          doc.text(`Pc:${pieces}`, 18, 10);
        } else {
          doc.text(`W.WT: N/A`, 3, 8);
          doc.text(`N.WT: ${netWt.toFixed(3)}`, 3, 10.5);

          doc.text(`${itemCode}`, 18, 3);
          doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
          doc.text(`${purity}`, 18, 7.5);
          doc.text(`Pc:${pieces}`, 18, 10);
        }
      } else {
        doc.text(`G.WT: ${grosswt.toFixed(3)}`, 3, 3);
        doc.text(`MRP: ${parseInt(mrp)}`, 3, 6);
        doc.text(`Pc:${pieces}`, 18, 9);
        // doc.text(`${product_No}`, 4, 11.5);
        doc.text(`${itemCode}`, 18, 3);
        doc.text(`${purity}`, 18, 6);
      }

      // try {
      //   const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
      //   doc.addImage(qrCodeDataUrl, "JPEG", 1, 3, imageWidth, imageHeight);
      // } catch (error) {
      //   console.error(error);
      // }
    }

    const pdfData = doc.output("datauristring");
    const newWindow = window.open();
    newWindow.document.write(
      `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
    );
  };
  const setBarcode = (value) => {
    // Update the barcodeNumber property in the data object
    const barcodeValue = value.toUpperCase();
    setData((prevData) => ({
      ...prevData,
      barcodeNumber: barcodeValue,
    }));

    // Find a matching product in the rifdData array
    const matchingProduct = rfidData.find(
      (item) => item.barcodeNumber === barcodeValue
    );

    if (matchingProduct) {
      // Update the 'tid' property in the data object with the matching product's tid
      setData((prevData) => ({
        ...prevData,
        tid: matchingProduct.tid,
      })),
        setBarcodeChangeButton(true);
    } else {
      // If no matching product found, set 'tid' to null or some default value
      setData((prevData) => ({
        ...prevData,
        tid: null, // or any default value you want
      })),
        setBarcodeChangeButton(false);
      // setBarCodeAlert(true);
    }
    if (value === "" && matchingProduct === undefined) {
      setBarcodeChangeButton(true);
    }
  };

  const updateBarcodeNumber = async () => {
    setLoading(true);
    const data2 = [data];

    try {
      const response = await fetch(a31, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data2),
      });

      const rcvdData = await response.json();
      console.log("rcvdData", rcvdData);
      setData(rcvdData.data[0]);
      setLoading(false);
      setPopup(false);
      alert(`${parameter} Changed Successfully`);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        <h2 style={{ margin: "10px 0" }}>Product details</h2>
        {/* <MyPDF
          name={data.product_Name}
          grossWt={data.grosswt}
          stoneWt={data.stoneWeight}
          netWt={data.netWt}
          itemCode={data.itemCode}
          purity={data.purity}
          pieces={data.pieces}
          mrp={data.mrp}
          product_No={data.product_No}
        /> */}
        {/* <MyPDF data={data} /> */}
        <div className="adminProductDetailsMainBox">
          {/* <div className="adminProductDetailImageBox">
            <img
              style={{ width: "300px", cursor: "pointer" }}
              // src={`${s1}${data.ImageList1}`}
              // src={`${s1}${data.imageList1}`}
              // src={`${s3}${data.images}`}
              src={`${s3}/${allImages[0]}`}
              alt="images"
              onClick={() => {
                setPlaceHolder("Add Image");
                setParameter("Images");
                updatedetails("image");
              }}
            />
          </div> */}
          <div
            className="adminProductDetailDetailsBox"
            style={{ width: "90%" }}
          >
            <h3 style={{ marginLeft: "10px" }}>
              Last Modified By : {data.entryby_Staff_id}
            </h3>
            <h2
              // onClick={() => {
              //   setPlaceHolder(data.collection);
              //   setParameter("Collection Name");
              //   updatedetails();
              // }}
              style={{ margin: "10px" }}
            >
              {data.collection}
            </h2>
            <div style={{ margin: "20px 10px" }}>
              {data.images && data.images.length > 0 ? (
                data.images.split(",").map((image, index) => (
                  <img
                    key={index}
                    style={{ height: "100px", marginRight: "10px" }}
                    onClick={() => {
                      setPlaceHolder("Add Image");
                      setParameter("Images");
                      updatedetails("image");
                    }}
                    className="adminOrderDetailsItemsproductImage"
                    src={`${s3}/${image.trim()}`}
                    alt={`Product Image ${index + 1}`}
                  />
                ))
              ) : (
                <BsImages
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPlaceHolder("Add Image");
                    setParameter("Images");
                    updatedetails("image");
                  }}
                  size={"30px"}
                />
              )}
            </div>

            <div className="adminProductDetailsInfoBox">
              <div>
                <p style={{ margin: "10px 0" }}>Label: {data.itemCode}</p>
                <p style={{ margin: "10px 0" }}>
                  Product Type: {data.itemType}
                </p>
                <p style={{ margin: "10px 0" }}>Purity: {data.purity}</p>
              </div>
              <div style={{ textAlign: "end" }}>
                <p> .</p>
                <p style={{ margin: "10px 0" }}>
                  Barcode Number: {data.barcodeNumber}
                </p>
                <p style={{ margin: "10px 0" }}>Tid Number: {data.tid}</p>
              </div>
            </div>
            {/* <h2
              onClick={() => {
                setPlaceHolder(data.product_Name);
                setParameter("Product_Name");
                updatedetails();
              }}
              style={{ margin: "1rem 0" }}
            >
              {data.product_Name}
            </h2> */}
            <p
              onClick={() => {
                setPlaceHolder(data.description);
                setParameter("description");
                updatedetails();
              }}
              style={{ color: "red", lineHeight: "1.5rem", margin: "1rem 0" }}
            >
              {data.description}
            </p>
            <div className="adminProductDetailDetailsBoxItems">
              {/* <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.size);
                  setParameter("Size");
                  updatedetails();
                }}
              >
                Size: {data.size == "" ? "0" : data.size}
              </p> */}
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.grosswt);
                  setParameter("grosswt");
                  updatedetails();
                }}
              >
                Gross Wt: {data.grosswt}gm
              </p>
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.stoneWeight);
                  setParameter("stoneWeight");
                  updatedetails();
                }}
              >
                Stone Wt: {data.stoneWeight}gm
              </p>
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.netWt);
                  setParameter("NetWt");
                  updatedetails();
                }}
              >
                Net Wt: {data.netWt}gm
              </p>
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.stoneAmount);
                  setParameter("StoneAmount");
                  updatedetails();
                }}
              >
                Stone Amount: {data.stoneAmount}
              </p>
            </div>
            <div className="adminProductDetailDetailsBoxItems">
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.making_per_gram);
                  setParameter("making_per_gram");
                  updatedetails();
                }}
              >
                M.PerGram: ₹{data.making_per_gram}
              </p>
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.making_Percentage);
                  setParameter("making_Percentage");
                  updatedetails();
                }}
              >
                M.Percentage: {data.making_Percentage}%
              </p>
              {/* <p
              // onClick={() => {
              //   setPlaceHolder(data.purity);
              //   setParameter("purity");
              //   updatedetails();
              // }}
              >
                Purity: {data.purity}
              </p> */}
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.making_Fixed_Amt);
                  setParameter("making_Fixed_Amt");
                  updatedetails();
                }}
              >
                M.Fixed.Amt: ₹{data.making_Fixed_Amt}
              </p>
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.making_Fixed_Wastage);
                  setParameter("making_Fixed_Wastage");
                  updatedetails();
                }}
              >
                M.Fixed.Wastage: {data.making_Fixed_Wastage}gm
              </p>
            </div>
            <div className="adminProductDetailDetailsBoxItems">
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.mrp);
                  setParameter("mrp");
                  updatedetails();
                }}
              >
                MRP: ₹{data.mrp}
              </p>
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.occasion);
                  setParameter("occasion");
                  updatedetails();
                }}
              >
                Occassion: {data.occasion}
              </p>
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.gender);
                  setParameter("gender");
                  updatedetails();
                }}
              >
                Gender: {data.gender}
              </p>
              <p
                className="adminProductDetailDetailsBoxEditItems"
                onClick={() => {
                  setPlaceHolder(data.statusType);
                  setParameter("StatusType");
                  updatedetails();
                }}
              >
                Status: {data.statusType}
              </p>
              {/* <p
              // onClick={() => {
                //   setPlaceHolder(data.itemType);
                //   setParameter("itemType");
                //   updatedetails();
              // }}
              >
                ItemType: {data.itemType}
              </p> */}
              {/* <p
                onClick={() => {
                  setPlaceHolder(data.collection);
                  setParameter("collection");
                  updatedetails();
                }}
              >
                Collection: {data.collection}
              </p> */}
            </div>
            <div className="adminProductDetailDetailsBoxItems">
              <p
                className="adminProductDetailDetailsBoxEditItems adminProductDetailDetailsBoxEditItemsBarcode"
                onClick={() => {
                  setPlaceHolder(data.barcodeNumber);
                  setParameter("barcodeNumber");
                  updatedetails();
                }}
              >
                Barcode Number: {data.barcodeNumber}
              </p>

              {/* <p
                onClick={() => {
                  setPlaceHolder(data.pieces);
                  setParameter("Pieces");
                  updatedetails();
                }}
              >
              Pieces: {data.pieces}
              </p> */}

              {/* <p>Item Code: {data.itemCode}</p> */}
              {/* <p>Item Code: {data.BarcodeNumber}</p> */}
            </div>
            {/* <img style={{ width: "120px" }} src={qr} /> */}
            <button
              style={{ margin: "20px 10px" }}
              onClick={() => printPDF([data])}
              className="adminOrderDetailsPdfButton"
            >
              Print Label
            </button>
          </div>
          <div
            style={{ height: "auto", paddingBottom: "50px" }}
            className={
              popUp == true ? "updateAccountDetailsPopupMainBox" : "none"
            }
          >
            <div className="updateAccountDetailsPopupCloseBtn">
              <AiOutlineClose size={"3rem"} onClick={() => setPopup(false)} />
            </div>
            <h1 style={{ color: "rgba(0,0,0,0.5)" }}>Change {parameter} </h1>
            {parameter === "barcodeNumber" ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <input
                  style={{ marginBottom: "10px" }}
                  placeholder={placeHolder}
                  type="text"
                  value={data.barcodeNumber}
                  onChange={(e) => setBarcode(e.target.value)}
                />
                <input
                  style={{ cursor: "not-allowed", marginTop: "10px" }}
                  type="text"
                  placeholder={data.tid}
                  // value={data.tid} // Set the value of the input to data.tid
                  readOnly // Make the input read-only
                />
                <div
                  style={{ height: "70px", marginBottom: "1rem" }}
                  className={loading == true ? "loading" : "none"}
                >
                  <InfinitySpin width="150" color="#4fa94d" />
                </div>
                {barcodeChangeButton ? (
                  <button
                    onClick={() => {
                      if (data.barcodeNumber === "" && data.tid === null) {
                        updateBarcodeNumber();
                        // Show an alert when barcodeNumber is empty and tid is null
                      } else {
                        // updatedetailsBox(parameter);
                        updateBarcodeNumber();
                        // setLoading(true);
                      }
                    }}
                  >
                    {data.barcodeNumber === "" && data.tid === null
                      ? "Change Barcode Empty"
                      : `Change Barcode to - ${data.barcodeNumber}`}
                  </button>
                ) : null}
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <input
                  placeholder={placeHolder}
                  type="text"
                  onChange={(e) => setFormValue(e.target.value)}
                />
                <div
                  style={{ height: "70px", marginBottom: "1rem" }}
                  className={loading == true ? "loading" : "none"}
                >
                  <InfinitySpin width="150" color="#4fa94d" />
                </div>
                <button
                  onClick={() => {
                    updatedetailsBox(parameter), setLoading(true);
                  }}
                >
                  Submit
                </button>
              </div>
            )}
          </div>

          {/* Image requested */}
          <div
            style={{ height: "auto" }}
            className={
              popUp == "imageRequested"
                ? "updateAccountDetailsPopupMainBox"
                : "none"
            }
          >
            <div
              style={{ margin: "20px" }}
              className="updateAccountDetailsPopupCloseBtn"
            >
              <AiOutlineClose
                size={"3rem"}
                onClick={() => {
                  setPopup(false), setSelectedFiles([]);
                }}
              />
            </div>
            <h1 style={{ color: "rgba(0,0,0,0.5)" }}>Change {parameter} </h1>
            <input
              placeholder={placeHolder}
              type="file"
              multiple
              max="5"
              // onChange={(e) => setFormValue(e.target.value)}
              // onChange={(e) => handleFileInputChange(e)}
              onChange={handleFileInputChange}
            />
            <div
              style={{ height: "70px", marginBottom: "1rem" }}
              className={loading == true ? "loading" : "none"}
            >
              <InfinitySpin width="150" color="#4fa94d" />
            </div>
            <p>{selectedFiles.length} out of 5 images selected</p>
            {/* <button onClick={() => updateImagesBox(parameter)}>Submit</button> */}
            <button
              style={{ margin: "20px" }}
              onClick={() => {
                handleFileSubmit(parameter), setLoading(true);
              }}
            >
              Submit
            </button>
          </div>
        </div>

        {/* <div className="newBox2"> */}
        {/* <ProductDetails images={fakeImages} /> */}
        {/* </div> */}
        <div className={popUp === true ? "new" : "new2"}></div>
      </div>
    </div>
  );
}
