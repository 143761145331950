import React from "react";
import "../Components Styles/Footer.css";
import { SlEnvolopeLetter } from "react-icons/sl";
import { GrInstagram, GrFacebookOption, GrYoutube } from "react-icons/gr";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const [x, setX] = useState("");
  const onEnter = (a) => setX(a);
  const subscribeToNewsletter = () => {
    alert("You Have Subscribed to Ghare's");
  };
  return (
    <div className="footerOuterContainer">
      {/* <div className="mobileFooterHeadings">
        <h3>ABOUT US</h3>
        <h3>POLICIES</h3>
        <h3>CUSTOMER SERVICES</h3>
        <h3>FIND A STORE</h3>
      </div> */}
      <div className="footerMain">
        <div className="footerItems">
          <Link to={"/aboutus#section1"}>
            <h3>ABOUT US</h3>
          </Link>
          <ul>
            <Link to="/aboutus#section1">
              <li>The Golden Story</li>
            </Link>
            <Link to="/aboutus#section2">
              <li>What our Brand stands for</li>
            </Link>

            <Link to="/aboutus#section3">
              <li>Our Inspiration</li>
            </Link>

            <Link to="/aboutus#section4">
              <li>Why Shop with us?</li>
            </Link>
            <Link to="/aboutus#section5">
              <li>Affiliates and Associations</li>
            </Link>
            {/* <li>Awards and Achievements</li> */}
          </ul>
        </div>
        <div className="footerItems">
          <h3>POLICIES</h3>
          <ul>
            <Link to="/termsandconditions">
              <li>Terms & Conditions</li>
            </Link>
            <Link to="/privacypolicy">
              <li>Privacy Policy</li>
            </Link>
            <Link to="/refundandexchange">
              <li>Return & Exchange</li>
            </Link>
            <Link to="/shippingpolicy">
              <li>Shipping Policy</li>
            </Link>
          </ul>
        </div>
        <div className="footerItems">
          <h3>CUSTOMER SERVICES</h3>
          <ul>
            <Link to="/contactdetails">
              <li>Contact Us</li>
            </Link>
            <a href="tel:+917867869030">
              <li>+91-78678-69030</li>
            </a>
            {/* <Link to="/checkoutmain"> */}
            <li>11AM to 9PM, 7 Days a Week</li>
            {/* </Link> */}
            {/* <Link to="/adminpanellogin">
              <li>Admin Login</li>
            </Link> */}
          </ul>
        </div>
        <div className="footerItems">
          <h3>FIND A STORE</h3>
          <ul>
            <Link to="/stores">
              <li> Stores</li>
            </Link>
            <li
              style={{
                color: "white",
                marginBottom: "1rem",
                fontWeight: "normal",
              }}
            >
              SUBSCRIBE TO OUR NEWSLETTER
            </li>
            <div className="newsLetterBox">
              <input
                type={"text"}
                placeholder="Enter Email Address"
                className="newsLetterBoxInput"
                onChange={(e) => onEnter(e.target.value.toLowerCase())}
              />
              <button
                className="subscribeNewsLetterBtn"
                onClick={() => subscribeToNewsletter()}
              >
                <SlEnvolopeLetter size={"1.5rem"} />
              </button>
            </div>
          </ul>
        </div>
      </div>
      <div className="socialMediaIconsBox">
        <a href="https://www.instagram.com/mkgharejewellers/" target="_blank">
          <GrInstagram className="socialMediaIcon" size={"2.0rem"} />
        </a>
        <a href="https://www.facebook.com/MKGhareJewellers" target="_blank">
          <GrFacebookOption className="socialMediaIcon" size={"2.0rem"} />
        </a>
        <a
          href="https://www.youtube.com/channel/UCgEtut0wEpRaO8jfYIEMOZw"
          target="_blank"
        >
          <GrYoutube className="socialMediaIcon" size={"2.0rem"} />
        </a>
      </div>
      {/* <Link to={"/adminpanellogin"}> */}
      <p className="copyrightStatement">© Ghare Jewellers Private Limited</p>
      {/* </Link> */}
    </div>
  );
}
