import React, { useState } from "react";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { RxCross2, RxCheck } from "react-icons/rx";
import { a2, a25, a42 } from "../../Api/RootApiPath";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function ChangePassword() {
  const [csData, setCsData] = useState("");
  const [email, setEmail] = useState(null);
  const [otpRcvd, setOtpRcvd] = useState("");
  const [otp, setOtp] = useState("");
  const [otpButton, setOtpButton] = useState(true);
  const [pageFor, setPageFor] = useState("forgotPassword");
  // const [pageFor, setPageFor] = useState("confirmOtp");
  const [resendOtp, setResendOtp] = useState(false);
  const [incorrectOtpLine, setIncorrectOtpLine] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [errorStatement, setErrorStatement] = useState(false);
  const [timer, setTimer] = useState(300); // 300 seconds = 5 minutes

  useEffect(() => {
    if (email) {
      getCsData(email);
    }
  }, [pageFor, email]);

  const getCsData = async () => {
    if (email !== "") {
      const formData = {
        customer_login_id: email,
      };
      fetch(a2, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setCsData(data.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      null;
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendOtpButton = async () => {
    const formData = {
      email: email,
    };
    try {
      const response = await fetch(a42, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setResendOtp(true);
      setOtpRcvd(data.r);
      setTimer(300);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const startTimer = () => {
    setOtpButton(true);
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (timer === 0) {
      // Timer expired
      // Handle the case here, such as disabling the form or showing an error message
      setOtpButton(false);
      setResendOtp(false);
    }
  }, [timer]);
  useEffect(() => {
    if (pageFor === "confirmOtp") {
      if (timer > 0) {
        startTimer();
        setOtpButton(true);
      } else {
        setOtpButton(false);
        setResendOtp(false);
      }
    }
  }, [pageFor]);
  console.log(otpRcvd);
  const handleOtpChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length <= 6) {
      setResendOtp(false);
      setOtp(value);
    }
  };

  const validateOtp = () => {
    if (otp == otpRcvd) {
      setPageFor("changePassword");
    } else {
      setIncorrectOtpLine(true);
    }
  };

  const validatePassword = () => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()]{8,}$/;
    return regex.test(password);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const checkBothPassword = () => {
    if (password === password2 && validatePassword()) {
      changePassword();
    } else if (password === password2 && !validatePassword()) {
      setErrorStatement("Please Enter a strong Password");
    } else {
      setErrorStatement("Sorry, The passwords dont match");
    }
  };

  const changePassword = () => {
    const formData = {
      Id: csData.id,
      Password: password,
    };
    fetch(a25, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCsData(data.data);
        setPageFor("passwordChanged");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="checkOutMainBox">
      <div className="headingBox">
        <Heading />
      </div>
      <div
        style={{ marginTop: 0, paddingTop: "50px" }}
        className="checkoutNewLoginMainBox"
      >
        {pageFor === "forgotPassword" && (
          <div className="checkoutNewLoginFormBox" style={{ paddingTop: "0" }}>
            <h2 style={{ margin: "20px 0" }}>Forgot password ?</h2>
            <p>
              Please enter the email address you used to create your account,
              and we'll send you an OTP to reset your password.
            </p>
            <label>
              Email<sup style={{ color: "red" }}>*</sup>
            </label>
            <div
              id="emailInput"
              className={`password-input ${
                email && !validateEmail(email) ? "invalid" : ""
              } ${email && validateEmail(email) ? "valid" : ""} ${
                !email ? "blank" : ""
              }`}
            >
              <input
                id="emailInputField"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
              />
              {email && !validateEmail(email) && (
                <p style={{ margin: "0" }} className="invalid">
                  Please enter a valid email
                </p>
              )}
              {email && validateEmail(email) && (
                <RxCheck className="checkMark" size="20px" />
              )}
              {email && !validateEmail(email) && (
                <RxCross2
                  color="red"
                  style={{ top: "40%" }}
                  className="checkMark"
                  size="20px"
                />
              )}
            </div>
            <button
              onClick={() => {
                if (email && validateEmail(email)) {
                  setPageFor("confirmOtp"), sendOtpButton();
                }
              }}
              className={
                email && validateEmail(email)
                  ? "checkoutNewLoginFormBoxButton"
                  : "none"
              }
            >
              Send OTP
            </button>
          </div>
        )}
        {pageFor === "confirmOtp" && (
          <div className="checkoutNewLoginFormBox" style={{ paddingTop: "0" }}>
            {/* {resendOtp ? <p style={{ color: "green" }}> Send OTP...</p> : null} */}
            <p>Please enter the OTP received</p>
            <label>
              OTP<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              value={otp}
              onChange={handleOtpChange}
              type="tel"
              maxLength="6"
              pattern="\d*"
              inputMode="numeric"
            />
            {incorrectOtpLine ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ margin: "10px 0" }} className="invalid">
                  Sorry, Incorrect OTP
                </p>
                <p
                  style={{ margin: "5px 0", cursor: "pointer" }}
                  onClick={() => {
                    setResendOtp(false),
                      sendOtpButton(),
                      setIncorrectOtpLine(false);
                  }}
                >
                  Resend OTP
                </p>
              </div>
            ) : null}
            {timer > 0 ? (
              <p
                className={timer <= 30 ? "invalid" : "valid"}
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  margin: "5px",
                }}
              >
                {Math.floor(timer / 60)}:{timer % 60}
              </p>
            ) : null}
            {otpButton ? (
              <button
                onClick={() => {
                  setPageFor("confirmOtp");
                  validateOtp();
                }}
                className="checkoutNewLoginFormBoxButton"
              >
                Confirm OTP
              </button>
            ) : null}
            <p>
              Didn't receive OTP?{" "}
              <span
                onClick={() => {
                  setResendOtp(false), sendOtpButton();
                }}
                style={{ cursor: "pointer" }}
              >
                Resend OTP
              </span>
            </p>
          </div>
        )}
        {pageFor === "changePassword" && (
          <div className="checkoutNewLoginFormBox" style={{ paddingTop: "0" }}>
            <h2 style={{ margin: "20px 0" }}>Change password</h2>

            <label>
              New Password<sup style={{ color: "red" }}>*</sup>
            </label>
            {errorStatement !== "" ? (
              <p style={{ color: "red" }}>{errorStatement}</p>
            ) : null}
            <div
              id="passwordInput"
              className={`password-input ${
                password && !validatePassword() ? "invalid" : ""
              } ${password && validatePassword() ? "valid" : ""} ${
                !password ? "blank" : ""
              }`}
            >
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value), setErrorStatement("");
                }}
              />
              <button
                className="toggle-password-visibility"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </button>
              <div className="password-requirements">
                {password && password.length < 8 ? (
                  <p style={{ margin: "5px" }} className="invalid">
                    8 characters <RxCross2 />
                  </p>
                ) : (
                  <p
                    style={{ margin: "5px" }}
                    className={password ? "valid" : ""}
                  >
                    8 characters {password && <RxCheck />}
                  </p>
                )}
                {password && !/[a-z]/.test(password) ? (
                  <p style={{ margin: "5px" }} className="invalid">
                    1 lowercase <RxCross2 />
                  </p>
                ) : (
                  <p
                    style={{ margin: "5px" }}
                    className={password ? "valid" : ""}
                  >
                    1 lowercase {password && <RxCheck />}
                  </p>
                )}
                {password && !/[A-Z]/.test(password) ? (
                  <p style={{ margin: "5px" }} className="invalid">
                    1 uppercase <RxCross2 />
                  </p>
                ) : (
                  <p
                    style={{ margin: "5px" }}
                    className={password ? "valid" : ""}
                  >
                    1 uppercase {password && <RxCheck />}
                  </p>
                )}
                {password && !/\d/.test(password) ? (
                  <p style={{ margin: "5px" }} className="invalid">
                    1 number <RxCross2 />
                  </p>
                ) : (
                  <p
                    style={{ margin: "5px" }}
                    className={password ? "valid" : ""}
                  >
                    1 number {password && <RxCheck />}
                  </p>
                )}
              </div>
            </div>
            <label style={{ marginTop: "10px" }}>
              Confirm Password<sup style={{ color: "red" }}>*</sup>
            </label>
            <div id="passwordInput" className={`password-input`}>
              <input
                type={showPassword2 ? "text" : "password"}
                value={password2}
                onChange={(e) => {
                  setPassword2(e.target.value), setErrorStatement("");
                }}
              />
              <button
                className="toggle-password-visibility"
                onClick={togglePasswordVisibility2}
              >
                {showPassword2 ? "HIDE" : "SHOW"}
              </button>
            </div>
            <button
              onClick={() => {
                checkBothPassword();
              }}
              className="checkoutNewLoginFormBoxButton"
            >
              Change Password
            </button>
          </div>
        )}
        {pageFor === "passwordChanged" && (
          <div className="checkoutNewLoginFormBox" style={{ paddingTop: "0" }}>
            <h2 style={{ margin: "20px 0" }}>Password Changed</h2>

            <label>Please Login Again</label>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/customerloginpage"
            >
              <label
                className="passwordResetLoginAgainLink"
                style={{
                  textAlign: "end",
                  margin: "30px 0",
                  marginBottom: "0px",
                }}
              >
                Login Now
              </label>
            </Link>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
