import { ADMIN_SIDEBAR_OPENED } from "../ActionTypes";
import { ADMIN_SIDEBAR_CLOSED } from "../ActionTypes";
const initialState = {
  isOpen: false,
};
// export const reducer6 = (state = [], action) => {
//   switch (action.type) {
//     case ADMIN_SIDEBAR_OPENED: {
//       return [...state, action.payload];
//     }
//     case ADMIN_SIDEBAR_CLOSED: {
//       const newArray = state.filter((index) => {
//         return index !== action.payload;
//       });
//       return newArray;
//     }

//     default:
//       return state;
//   }
// };
export const reducer6 = (state = initialState, action) => {
  switch (action.type) {
    case "ADMIN_SIDEBAR_OPENED":
      return {
        ...state,
        isOpen: true,
      };
    case "ADMIN_SIDEBAR_CLOSED":
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};
