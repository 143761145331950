/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import "./App.css";
import PrivacyPolicy from "./Components/Footer/PrivacyPolicy";
import RefundAndExchangePolicy from "./Components/Footer/RefundAndExchangePolicy";
import ShippingPolicy from "./Components/Footer/ShippingPolicy";
import TermsAndConditions from "./Components/Footer/TermsAndConditions";
import Heading from "./Components/Heading/Heading";
import AboutUs from "./Components/Pages/AboutUs/AboutUs";
import AdminAddCategory from "./Components/Pages/AdminPanel/AdminAddCategory";
import AdminAddProduct from "./Components/Pages/AdminPanel/AdminAddProduct";
import AdminAddProductType from "./Components/Pages/AdminPanel/AdminAddProductType";
import AdminAddPurity from "./Components/Pages/AdminPanel/AdminAddPurity";
import AdminAllCustomers from "./Components/Pages/AdminPanel/AdminAllCustomers";
import AdminPanelLogin from "./Components/Pages/AdminPanel/AdminComponent/AdminPanelLogin";
import AdminDailyRates from "./Components/Pages/AdminPanel/AdminDailyRates";
import AdminHome from "./Components/Pages/AdminPanel/AdminHome";
import AdminProductDetails from "./Components/Pages/AdminPanel/AdminProductDetails";
import AdminProducts from "./Components/Pages/AdminPanel/AdminProducts";
import AccountDetailsPage from "./Components/Pages/CheckOut/AccountDetailsPage";
import CheckOut from "./Components/Pages/CheckOut/CheckOut";
import CheckOutFullPage from "./Components/Pages/CheckOut/CheckOutFullPage";
import CheckOutMain from "./Components/Pages/CheckOut/CheckOutMain";
import LoginPage from "./Components/Pages/CheckOut/LoginPage";
import PaymentFailedPage from "./Components/Pages/CheckOut/PaymentFailedPage";
import PaymentInformation from "./Components/Pages/CheckOut/PaymentInformation";
import PaymentPage from "./Components/Pages/CheckOut/PaymentPage";
import PaymentSuccessPage from "./Components/Pages/CheckOut/PaymentSuccessPage";
import RazorpayPage from "./Components/Pages/CheckOut/RazorpayPage";
import SetPasswordPage from "./Components/Pages/CheckOut/SetPasswordPage";
import ShippingAddress from "./Components/Pages/CheckOut/ShippingAddress";
import OurContactDetails from "./Components/Pages/Contact us/OurContactDetails";
import StoreLocater from "./Components/Pages/Contact us/StoreLocater";
import HomePage from "./Components/Pages/HomePage/HomePage";
import ProductPage from "./Components/Pages/ProductPage/ProductPage";
import ShopPage from "./Components/Pages/ShopPage/ShopPage";
import CustomerProfile from "./Components/Pages/CustomerProfile/CustomerProfile";
import CheckOutFullPage2 from "./Components/Pages/CheckOut/CheckOutFullPage2";
import DeliveryPage from "./Components/Pages/CheckOut/DeliveryPage";
import AdminAllOrders from "./Components/Pages/AdminPanel/AdminAllOrders";
import { useSelector } from "react-redux";
import AdminAddBulkStock from "./Components/Pages/AdminPanel/AdminAddBulkStock";
import AdminAddSupplier from "./Components/Pages/AdminPanel/AdminAddSupplier";
import AdminAddBox from "./Components/Pages/AdminPanel/AdminAddBox";
import AdminAddCollection from "./Components/Pages/AdminPanel/AdminAddCollection";
import AdminLabelledList from "./Components/Pages/AdminPanel/AdminComponent/AdminLabelledList";
import CategoryPage from "./Components/Pages/CategoryPage/CategoryPage";
import CategoryGenderPage from "./Components/Pages/CategoryPage/CategoryGenderPage";
import CheckOutPageNew from "./Components/Pages/CheckOut/CheckOutPageNew";
import CheckOutPageNewLogin from "./Components/Pages/CheckOut/CheckOutPageNewLogin";
import CustomerLoginPage from "./Components/Pages/CheckOut/CustomerLoginPage";
import ChangePassword from "./Components/Pages/CheckOut/ChangePassword";
import CustomerSignUpPage from "./Components/Pages/CheckOut/CustomerSignUpPage";
import AdminOrderDetails from "./Components/Pages/AdminPanel/AdminOrderDetails";
import AdminAddCustomer from "./Components/Pages/AdminPanel/AdminAddCustomer";
import AdminInvoice from "./Components/Pages/AdminPanel/AdminInvoice";
import AdminNewList from "./Components/Pages/AdminPanel/AdminNewList";
import FetchAllProducts from "./Components/Pages/AdminPanel/AdminComponent/FetchAllProducts";

// "proxy": "https://localhost:44395",
function App() {
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer5;
  // let isAuthenticated = "";
  // adminLoggedIn.toString() === "admin"
  //   ? (isAuthenticated = true)
  //   : (isAuthenticated = false);
  // console.log(adminLoggedIn);
  let isAuthenticated = "";
  if (adminLoggedIn.toString() === "1") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "2") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "3") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "4") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "5") {
    isAuthenticated = true;
  } else isAuthenticated = false;
  // console.log(isAuthenticated);
  // console.log(parseInt(adminLoggedIn));
  // const loadScript = (src) => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // };
  // useEffect(() => {
  //   loadScript("https://checkout.razorpay.com/v1/checkout.js");
  // });
  // {
  // }
  const userDetails = allStates.reducer4;

  const navigate = useNavigate();

  // Use useEffect to navigate to the '/adminpanellogin' route when the component mounts
  useEffect(() => {
    navigate("/adminpanellogin");
  }, []);

  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<HomePage />}></Route> */}
        <Route path="/" element={<AdminPanelLogin />}></Route>
        <Route path="/reloadProducts" element={<FetchAllProducts />}></Route>
        {/* <Route path="/productpage" element={<ProductPage />}></Route> */}
        <Route path="/productpage/:id" element={<ProductPage />}></Route>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/shoppage" element={<ShopPage />}></Route>
        <Route path="/aboutus" element={<AboutUs />}></Route>
        <Route path="/contactdetails" element={<OurContactDetails />}></Route>
        <Route path="/setpassword" element={<SetPasswordPage />}></Route>
        <Route path="/adminpanellogin" element={<AdminPanelLogin />}></Route>
        <Route path="/deliverypage" element={<DeliveryPage />}></Route>
        <Route path="/paymentpage" element={<PaymentPage />}></Route>
        <Route path="/category/:category" element={<CategoryPage />}></Route>
        <Route path="/gender/:gender" element={<CategoryGenderPage />}></Route>

        <Route
          path="/paymentsuccesspage"
          element={<PaymentSuccessPage />}
        ></Route>
        <Route
          path="/paymentfailedpage"
          element={<PaymentFailedPage />}
        ></Route>
        <Route
          path="/refundandexchange"
          element={<RefundAndExchangePolicy />}
        ></Route>
        <Route
          path="/termsandconditions"
          element={<TermsAndConditions />}
        ></Route>
        <Route path="/shippingpolicy" element={<ShippingPolicy />}></Route>
        <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/checkout" element={<CheckOut />}></Route>
        <Route path="/customerprofile" element={<CustomerProfile />}></Route>
        <Route path="/checkoutmain" element={<CheckOutMain />}></Route>
        <Route path="/checkoutpage" element={<CheckOutFullPage />}></Route>
        <Route path="/changepassword" element={<ChangePassword />}></Route>
        <Route
          path="/checkoutpagenew/:type"
          element={
            userDetails.length != 0 ? (
              <CheckOutPageNew />
            ) : (
              <CheckOutPageNewLogin />
            )
          }
        ></Route>
        <Route
          path="/checkoutpagenewlogin"
          element={<CheckOutPageNewLogin />}
        ></Route>
        <Route path="/checkoutpage2" element={<CheckOutFullPage2 />}></Route>
        <Route
          path="/customerloginpage"
          element={<CustomerLoginPage />}
        ></Route>
        <Route
          path="/customersignuppage"
          element={<CustomerSignUpPage />}
        ></Route>
        <Route path="/razorpaypage" element={<RazorpayPage />}></Route>
        <Route path="/stores" element={<StoreLocater />}></Route>
        <Route
          path="/adminhome"
          element={isAuthenticated ? <AdminHome /> : <AdminPanelLogin />}
        />
        {/* <Route path="/adminhome" element={<AdminHome />}></Route> */}
        <Route
          path="/admin-products"
          element={isAuthenticated ? <AdminProducts /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin-labelled-list"
          element={
            isAuthenticated ? <AdminLabelledList /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/admin-addcategory"
          element={isAuthenticated ? <AdminAddCategory /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin-addcollection"
          element={
            isAuthenticated ? <AdminAddCollection /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/admin-addproduct"
          element={isAuthenticated ? <AdminAddProduct /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/adminaddbulkstock"
          element={
            isAuthenticated ? <AdminAddBulkStock /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/adminaddsupplier"
          element={isAuthenticated ? <AdminAddSupplier /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/adminaddbox"
          element={isAuthenticated ? <AdminAddBox /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/adminaddcustomer"
          element={isAuthenticated ? <AdminAddCustomer /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin-dailyrates"
          element={isAuthenticated ? <AdminDailyRates /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin-purity"
          element={isAuthenticated ? <AdminAddPurity /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin-allcustomers"
          element={
            isAuthenticated ? <AdminAllCustomers /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/admin-allorders"
          element={isAuthenticated ? <AdminAllOrders /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin-orderdetails/:id"
          element={
            isAuthenticated ? <AdminOrderDetails /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/admin-add_product"
          element={
            isAuthenticated ? <AdminAddProductType /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/admininvoice"
          element={isAuthenticated ? <AdminInvoice /> : <AdminPanelLogin />}
        ></Route>
        <Route
          path="/admin-productdetails"
          element={
            isAuthenticated ? <AdminProductDetails /> : <AdminPanelLogin />
          }
        ></Route>
        <Route
          path="/admin-newlist"
          element={isAuthenticated ? <AdminNewList /> : <AdminPanelLogin />}
        ></Route>
        <Route path="/accountdetails" element={<AccountDetailsPage />}></Route>
        <Route
          path="checkout/accountdetails/shippingaddress"
          element={<ShippingAddress />}
        ></Route>
        <Route
          path="checkout/accountdetails/shippingaddress/paymentinformation"
          element={<PaymentInformation />}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
