import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { removeFromCart, userLoggedIn } from "../../../redux/action/Actions";
import { a2, a25, a3, a4, a5, s1 } from "../../Api/RootApiPath";
import Heading from "../../Heading/Heading";
import HomePage from "../HomePage/HomePage";
import CheckoutFullPageOrderSummary from "./CheckoutFullPageOrderSummary";
import LoginPage from "./LoginPage";
import PaymentPage from "./PaymentPage";
import ShippingAddress from "./ShippingAddress";
import { FcGoogle } from "react-icons/fc";
import { GrFacebook } from "react-icons/gr";
import Footer from "../../Footer/Footer";
import RazorpayPayment from "../../Api/RazorpayPayment";
// eslint-disable-next-line

export default function CheckOutFullPage2() {
  const [step, setStep] = useState(1);
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [progress, setProgress] = useState(0);
  // register
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [mobile, setMobile] = useState("");
  const [perAdd, setPerAdd] = useState("");
  const [currAdd, setCurrAdd] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [custId, setCustId] = useState("");
  const [registerSteps, setRegistersteps] = useState(0.2);
  // setPassword
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const userDetails = orderState.reducer4;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalPrice = orderItems.reduce((acc, x) => acc + x.price * x.count, 0);
  const calculateProgress = () => {
    const totalFields = 4; // change this to the total number of fields in your form
    // const filledFields = mobile && email && firstName && lastName ? 4 : 0; // change this condition to match your form
    const filledFields = registerSteps; // change this condition to match your form
    const progressPercentage = (filledFields / totalFields) * 100;
    setProgress(progressPercentage);
  };
  const handleRemoveFromBag = (index) => {
    dispatch(removeFromCart(index));
  };
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    // const formData = new FormData();
    // formData.append("customer_login_id", email);
    try {
      const response = await fetch(a2, {
        method: "POST",
        // body: formData,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customer_login_id: email }),
      });
      const data = await response.json();
      // console.log("Data:", data);
      paswordChecking(data);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (password1 !== password2) {
      alert("Sorry, the passwords do not match.");
      return;
    }

    setPassword(password1);
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "Password");
    // formData.append("FieldValue", password1);
    const formData = {
      Id: customerId,
      Password: password1,
    };
    try {
      // console.log(customerId);
      // const response = await fetch(a5, {
      const response = await fetch(a25, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "Success") {
        // setCustomerId(data.Customer_id);
        setCustomerId(data.data.id);
        // console.log(customerId);
        // alert("Successfully Logged In..");
        window.scrollTo(0, 0);
        // navigate(`/customerprofile?customerId=${customerId}`);
        setRegister("deliveryDetails");
        setRegistersteps(registerSteps + 0.8);
        calculateProgress();
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(userDetails);
  const handleRegister = async (e) => {
    e.preventDefault();

    // const formData = new FormData();
    const formData = {
      mobile: mobile,
      email: email,
      firstName: firstName,
      lastName: lastName,
      gender: gender,
    };
    // formData.append("mobile", mobile);
    // formData.append("email", email);
    // formData.append("firstName", firstName);
    // formData.append("lastName", lastName);
    // formData.append("gender", gender);
    try {
      const response = await fetch(a4, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.message === undefined) {
        setCustomerId(data.data.id);

        setRegister("setPassword");
        setRegistersteps(registerSteps + 1);
        calculateProgress();
        window.scrollTo(0, 0);
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const paswordChecking = (data) => {
    // console.log("Data:", data.data);
    // if (data.status === "success") {
    if (data.status === "Success") {
      const customerDetails = data.data;

      // const PasswordChecker = data.data.find(
      // const PasswordChecker = customerDetails.find(
      const PasswordChecker = customerDetails;
      // (x) => x.password === loginPassword
      // );
      // (x) => x.Password === loginPassword

      if (PasswordChecker) {
        if (loginPassword === PasswordChecker.password) {
          // setCustomerId(data.data[0].Customer_id);
          // setCustomerId(data.data[0].id);
          setCustomerId(data.data.id);
          // loggedIn(data.data[0]);
          loggedIn(data.data);
          // let customerId = data.data[0].Customer_id;
          // let customerId = data.data[0].id;
          let customerId = data.data.id;
          if (orderItems.length === 0) {
            navigate("/customerprofile");
          } else {
            // setRegister("deliveryDetails");
            // setRegistersteps(registerSteps + 1);
            // calculateProgress();

            navigate(`/deliverypage`);
          }
          // alert(
          //   // `Successfully Logged In.. Welcome ${customerDetails.FirstName} ${customerDetails.LastName}`
          //   `Successfully Logged In.. Welcome ${customerDetails.firstName} ${customerDetails.lastName}`
          // );
        } else {
          alert("Incorrect Password");
        }
      } else {
        alert("Incorrect Email");
      }
    } else {
      console.error(data.error);
      alert("Sorry either Username or Password is Incorrect");
    }
  };
  const handleDelivery = async (e) => {
    e.preventDefault();
    let deliverAddress = `${street}, ${city}, ${state}, ${pinCode} `;

    const formData = {
      Id: customerId,
      perAdd: deliverAddress,
    };
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "PerAdd");
    // formData.append("FieldValue", deliverAddress);
    try {
      const response = await fetch(a5, {
        // body: formData,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        // setCustomerId(data.Customer_id);
        console.log(data);
        setCustomerId(data.data.id);
        updateUserDetails(e);
        // handleDeliveryPost(deliverAddress, pinCode);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // const handleDeliveryPost = async (deliverAddress, pinCode) => {
  //   const formData = new FormData();
  //   formData.append("Customer_id", customerId);
  //   formData.append("FieldName", "PinCode");
  //   formData.append("FieldValue", pinCode);
  //   try {
  //     const response = await fetch(a5, {
  //       method: "POST",
  //       body: formData,
  //     });
  //     const data = await response.json();
  //     if (data.status == "success") {
  //       handleDeliveryCurrentAddress(deliverAddress, pinCode);
  //     } else {
  //       console.error(data.error);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // const handleDeliveryCurrentAddress = async (deliverAddress, pinCode) => {
  //   const formData = new FormData();
  //   formData.append("Customer_id", customerId);
  //   formData.append("FieldName", "CurrAdd");
  //   formData.append("FieldValue", `${deliverAddress} ${pinCode}`);
  //   try {
  //     const response = await fetch(a5, {
  //       method: "POST",
  //       body: formData,
  //     });
  //     const data = await response.json();
  //     if (data.status == "success") {
  //       window.scrollTo(0, 0);
  //       console.log(data);
  //       updateUserDetails();
  //     } else {
  //       console.error(data.error);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const updateUserDetails = async (e) => {
    // const updateUserDetails = () => {
    e.preventDefault();
    const formData = {
      Id: customerId,
    };
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);

    try {
      const response = await fetch(a3, {
        // const response = fetch(a3, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      loggedIn(data.data);
      if (orderItems.length === 0) {
        navigate("/customerprofile");
      } else {
        navigate(`/paymentpage?customerId=${customerId}`);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  const loginForm = () => {
    return (
      <div
        style={{ width: "100%" }}
        className="checkoutFullPageFormSideBox"
        data-aos="fade-down"
      >
        <h3
          style={{
            paddingLeft: "2rem",
            // backgroundColor: "white",
            // border: "1px solid grey",
            // color: "black",
          }}
        >
          1. Login to Ghare's
        </h3>
        <div className="loginPageBox">
          <p style={{ color: "black", marginBottom: "3rem" }}>
            Login to Ghare's
          </p>
          {/* <div className="loginSocialMediaOptionsBox">
            <button className="socialMediaOption">
              <FcGoogle size={"1.5rem"} style={{ marginRight: "8px" }} />
              <li style={{ color: "grey", listStyle: "none" }}>Google</li>
            </button>
            <button className="socialMediaOption">
              <GrFacebook size={"1.5rem"} style={{ marginRight: "8px" }} />
              <li style={{ color: "grey", listStyle: "none" }}>Facebook</li>
            </button>
          </div>
          <h4
            style={{ marginTop: "2rem", marginBottom: "1rem", color: "grey" }}
          >
            Or
          </h4> */}
          <form className="loginFormCheckoutFullPage" onSubmit={handleLogin}>
            {/* <hr></hr> */}
            <p style={{ alignSelf: "flex-start" }}>Enter Username Or Email</p>
            <input
              required="required"
              type={"text"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p style={{ alignSelf: "flex-start" }}>Enter Password</p>
            <input
              required="required"
              type={"password"}
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
            />
            <button
              style={{ margin: "32px 0" }}
              type="submit"
              className="checkoutSecurelyBtn checkoutLoginBtn"
            >
              CONTINUE
            </button>
          </form>
          <h4
            style={{ color: "grey", marginBottom: "1rem", marginTop: "1rem" }}
          >
            Or
          </h4>
          <p>
            <span>New to Ghare's? </span>
            <span
              className="createAccountLink"
              onClick={() => {
                setRegister("Register"), window.scrollTo(0, 0);
                calculateProgress();
              }}
            >
              Create an Account
            </span>
          </p>
          {/* <button
            onClick={() => {
              setRegister("deliveryDetails");
              setRegistersteps(registerSteps + 1);
              calculateProgress();
            }}
          >
            Next Step
          </button> */}
        </div>
      </div>
    );
  };

  const registerForm = () => {
    return (
      <div className="checkoutFullPageFormSideBox" data-aos="fade-down">
        <h3 style={{ paddingLeft: "2rem" }}>1. Sign Up to Ghare's</h3>
        <div className="loginPageBox">
          <p style={{ color: "black", marginBottom: "3rem" }}>
            Sign Up with Ghare's
          </p>
          {/* <h4>Become a Member Today - It's quick and free!</h4>
          <h4>100 Bonus points when you join</h4>
          <h4>Free standard delivery</h4>
          <h4> Access to brand partnerships and offers</h4> */}
          {/* <div className="loginSocialMediaOptionsBox">
            <button className="socialMediaOption">
              <FcGoogle size={"1.5rem"} style={{ marginRight: "8px" }} />
              <li style={{ color: "grey", listStyle: "none" }}>Google</li>
            </button>
            <button className="socialMediaOption">
              <GrFacebook size={"1.5rem"} style={{ marginRight: "8px" }} />
              <li style={{ color: "grey", listStyle: "none" }}>Facebook</li>
            </button>
          </div>
          <h4
            style={{ marginTop: "2rem", marginBottom: "1rem", color: "grey" }}
          >
            Or
          </h4> */}
          <form
            onSubmit={handleRegister}
            className="loginFormCheckoutFullPage"
            style={{ textAlign: "left" }}
          >
            {/* <div className="registerBox"> */}
            {/* <hr></hr> */}
            <div className="loginFormCheckoutGridBox">
              <p style={{ justifySelf: "flex-start" }}>First Name</p>
              <p style={{ justifySelf: "flex-start" }}>Last Name</p>
              <input
                type="text"
                required="required"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <input
                type="text"
                required="required"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
            <p style={{ alignSelf: "flex-start" }}>Enter Mobile Number</p>
            <input
              type="text"
              required="required"
              value={mobile}
              maxLength="10"
              // pattern="d{10}"
              onChange={(e) => {
                setMobile(e.target.value);
              }}
            />
            <p style={{ alignSelf: "flex-start" }}>Enter Email</p>
            <input
              type="email"
              required="required"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {/* <div className="loginFormCheckoutGridBox"> */}
            <p style={{ alignSelf: "flex-start" }}>Gender</p>
            <select
              required="required"
              value={gender}
              style={{ width: "95%" }}
              onChange={(e) => {
                setGender(e.target.value);
              }}
            >
              <option value="">...</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            {/* </div> */}
            {/* <p style={{ alignSelf: "left" }}>Gender</p> */}
            {/* <div
              className="genderRadioBox"
              style={{
                marginTop: "16px",
                fontSize: "12px",
                width: "70%",
              }}
            >
              <input
                id="male"
                type="radio"
                name="gender"
                value="male"
                onChange={handleGenderChange}
                checked={gender === "male"}
              />
              <label htmlFor="male" style={{ marginInline: "16px" }}>
                Male
              </label>
              <input
                id="female"
                type="radio"
                name="gender"
                value="female"
                onChange={handleGenderChange}
                checked={gender === "female"}
              />
              <label htmlFor="female" style={{ marginInline: "16px" }}>
                Female
              </label>
              <input
                id="other"
                type="radio"
                name="gender"
                value="other"
                onChange={handleGenderChange}
                checked={gender === "other"}
              />
              <label htmlFor="other" style={{ marginInline: "16px" }}>
                Other
              </label>
            </div> */}
            {/* <p style={{ alignSelf: "left" }}>Date of Birth</p>
            <p style={{ alignSelf: "left" }}>Pin Code for Delivery</p>
            <input
              type={"date"}
              required="required"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
            <input
              type={"number"}
              required="required"
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
            />
            <p style={{ alignSelf: "left" }}>Permanent Address</p>
            <p style={{ alignSelf: "left" }}>Delivery Address</p>
            <textarea
              type={"text"}
              required="required"
              value={perAdd}
              onChange={(e) => setPerAdd(e.target.value)}
            />
            <textarea
              type={"text"}
              required="required"
              value={currAdd}
              onChange={(e) => setCurrAdd(e.target.value)}
            /> */}
            {/* </div> */}
            <button
              style={{ margin: "32px 0" }}
              type="submit"
              className="checkoutSecurelyBtn checkoutLoginBtn"
            >
              REGISTER TO CONTINUE
            </button>
          </form>
          <h4 style={{ color: "grey", marginBottom: "1rem" }}>Or</h4>
          <p>
            <span>Already have an account? </span>

            <span
              className="createAccountLink"
              onClick={() => {
                setRegister("Login"), window.scrollTo(0, 0);
              }}
            >
              Log in
            </span>
          </p>
          {/* <button
            onClick={() => {
              setRegister("setPassword");
              setRegistersteps(registerSteps + 1);
              calculateProgress();
            }}
          >
            Next Step
          </button> */}
        </div>
      </div>
    );
  };
  const setPassword = () => {
    return (
      <div className="checkoutFullPageFormSideBox" data-aos="fade-down">
        <div className="loginPageBox">
          <h3 style={{ paddingLeft: "5rem" }}>2. Set Password</h3>
          <form
            className="loginFormCheckoutFullPage"
            onSubmit={handleChangePassword}
          >
            {/* <div className="loginPageBox setPasswordMain"> */}
            <p style={{ color: "black", marginBottom: "2rem" }}>
              **Your Username is your Email Address**
            </p>

            <p style={{ alignSelf: "flex-start" }}>Set Password</p>
            <input
              required="required"
              type="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />
            <p style={{ alignSelf: "flex-start" }}>Confirm Password</p>
            <input
              required="required"
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            {/* <Link to="/razorpaypage"> */}
            <button
              style={{ margin: "32px 0" }}
              className="checkoutSecurelyBtn checkoutLoginBtn"
              type="submit"
              // onClick={() => {
              //   setRegister("paymentPage"), window.scrollTo(0, 0);
              // }}
            >
              CONTINUE
            </button>
            {/* <button
              onClick={() => {
                setRegister("deliveryDetails");
                setRegistersteps(registerSteps + 0.8);
                calculateProgress();
              }}
            >
              Next Step
            </button> */}
            {/* </Link> */}
            {/* </div> */}
          </form>
        </div>
      </div>
    );
  };
  const deliveryDetails = () => {
    return (
      <div
        style={{ width: "100%" }}
        className="checkoutFullPageFormSideBox"
        data-aos="fade-down"
      >
        <h3
          style={{
            paddingLeft: "2rem",
            // backgroundColor: "white",
            // border: "1px solid grey",
            // color: "black",
          }}
        >
          3. Add Delivery Details
        </h3>
        <div className="loginPageBox">
          <p style={{ color: "black" }}>Add Delivery Details</p>

          <form className="loginFormCheckoutFullPage" onSubmit={handleDelivery}>
            {/* <hr></hr> */}
            <p style={{ alignSelf: "flex-start" }}>
              Apartment/ Building/ Flat No.
            </p>
            <input
              required="required"
              type={"text"}
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
            <p style={{ alignSelf: "flex-start" }}>Town / City</p>
            <input
              required="required"
              type={"text"}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <p style={{ alignSelf: "flex-start" }}>Pincode / Zip Code</p>
            <input
              placeholder={
                userDetails !== 0 ? userDetails.PinCode : " Not Found"
              }
              required="required"
              type={"text"}
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
            />
            <p style={{ alignSelf: "flex-start" }}>State</p>
            <input
              required="required"
              type={"text"}
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <button
              style={{ margin: "32px 0" }}
              type="submit"
              className="checkoutSecurelyBtn checkoutLoginBtn"
            >
              CONTINUE
            </button>
          </form>
          {/* <button
            onClick={() => {
              setRegister("paymentPage");
              setRegistersteps(registerSteps + 1);
            }}
          >
            Next Step
          </button> */}
        </div>
      </div>
    );
  };

  const paymentPage = async () => {
    return (
      <div className="checkoutFullPageFormSideBox" data-aos="fade-down">
        <h3 style={{ paddingLeft: "2rem" }}>3. Payment for Order</h3>

        <div className="loginPageBox">
          {/* <p style={{ color: "black" }}>Add Delivery Details</p> */}
          <div className="orderReviewBoxForPayment">
            <h2 style={{ margin: "1rem 0" }}>Pay For Order</h2>
            <p style={{ margin: "2rem 0" }}>
              Please wait while we are processing your payment.
            </p>
            <p>
              <div className="payentPagePayBtnBox">
                <button
                  className="addToCartBtn payBtn"
                  onClick={async () =>
                    await RazorpayPayment(
                      totalPrice,
                      firstName,
                      lastName,
                      email,
                      mobile
                    )
                  }
                >
                  Pay Now
                </button>
                <button
                  className="addToCartBtn payBtn"
                  onClick={() => {
                    alert("Failed to Process Payment");
                    navigate("/checkoutmain");
                  }}
                >
                  Cancel
                </button>
              </div>
            </p>
          </div>
          <div className="orderSummarySideBoxForPayment">
            <p style={{ margin: "2rem 0" }}>Order Summary</p>
            <div className="loginFormCheckoutGridBox2">
              <p
                style={{
                  textAlign: "left",
                  color: "black",
                }}
              >
                Product Name
              </p>
              <p
                style={{
                  textAlign: "right",
                  color: "black",
                }}
              >
                Price
              </p>
            </div>
            {orderItems.map((x) => {
              return (
                // <>
                <div className="loginFormCheckoutGridBox2">
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "grey",
                    }}
                  >
                    {x.title}
                  </p>
                  <p
                    style={{
                      textAlign: "right",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "grey",
                    }}
                  >
                    ₹{x.finalPrice}
                  </p>
                </div>
                // </>
              );
            })}
            <div
              style={{
                borderTop: "1px solid grey",
                marginTop: "8px",
                paddingTop: "8px",
              }}
              className="loginFormCheckoutGridBox2"
            >
              <p style={{ textAlign: "left" }}>SubTotal :</p>
              <p> ₹ {totalPrice.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      // <PaymentPage />
    );
  };
  const [register, setRegister] = useState("Login");

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 1200 });
    calculateProgress();
  }, [register]);
  return (
    <div className="checkOutMainBox">
      <div className="headingBox orderSummaryHeadingBox">
        <Heading />
        {register == "Register" || "setPssword" ? (
          <div className="progress-bar">
            <div
              className="progress"
              style={{
                width: `${progress}%`,
              }}
            />
          </div>
        ) : null}
      </div>
      {/* <div className="checkoutFullPageFormSideMainBox" data-aos="fade-down"> */}
      {/* {loginForm()} */}
      {register == "Login" ? (
        <div className="checkoutFullPageFormSideMainBox" data-aos="fade-down">
          {loginForm()}
        </div>
      ) : register == "Register" ? (
        <div className="checkoutFullPageFormSideMainBox" data-aos="fade-up">
          {registerForm()}
        </div>
      ) : register == "setPassword" ? (
        <div className="checkoutFullPageFormSideMainBox" data-aos="fade-down">
          {setPassword()}
        </div>
      ) : register == "deliveryDetails" ? (
        deliveryDetails()
      ) : register == "paymentPage" ? (
        paymentPage()
      ) : (
        <h1>payment done</h1>
      )}
      {/* </div> */}
      {/* <h3 onClick={() => setRegister("Register")}>2. Register Here</h3> */}
      {/* <div>
        {step === 1 && <LoginSignup nextStep={nextStep} />}
        {step === 2 && <DeliveryDetails nextStep={nextStep} />}
        {step === 3 && <PaymentInformation />}
    </div> */}
      <Footer />
    </div>
  );
}
