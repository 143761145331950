import React, { useEffect, useState } from "react";
import "../PagesStyles/CustomerProfile.css";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { a3 } from "../../Api/RootApiPath";
import men from "../../Images/avatarMen.jpg";
import women from "../../Images/avatarWomen.jpg";
import { SlUser, SlUserFemale } from "react-icons/sl";
import CustomerDetails from "./CustomerDetails";
import { useDispatch, useSelector } from "react-redux";
import { userLoggedIn, userLoggedOut } from "../../../redux/action/Actions";
import CustomerDetailsMobile from "./CustomerDetailsMobile";

export default function CustomerProfile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showPage, setShowPage] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Customer_id = params.get("customerId");
  const dispatch = useDispatch();
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };

  const loggedOut = (index) => {
    dispatch(userLoggedOut(index));
  };
  const csStates = useSelector((state) => state);
  let userDetails = csStates.reducer4;

  const accountDetails = async () => {
    const formData = {
      Id: Customer_id,
    };

    try {
      const response = await fetch(a3, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log("Data:", data.data);
      loggedIn([data.data]);
      setLoading(false);
      setShowPage(true);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      navigate("/customerloginpage");
      alert(error);
    }
  };

  useEffect(() => {
    if (Customer_id) {
      setShowPage(false);
      accountDetails();
      console.log("first");
    } else if (!Customer_id && userDetails.length > 0) {
      setShowPage(true);
      console.log("second");
    } else {
      navigate("/customerloginpage");
      setShowPage(false);
    }
  }, [Customer_id]);

  const customerProfile = () => {
    return (
      <div>
        <div className="headingBox orderSummaryHeadingBox">
          <Heading />
        </div>
        <div className="profilePageMainBox">
          <div className="profileBox">
            {userDetails.gender === "Female" ? (
              <img className="profileImage" src={women} alt="WomenClipArt" />
            ) : (
              <img className="profileImage" src={men} alt="MenClipArt" />
            )}
            <div className="profileMyDetailsBox">
              <h2>
                {userDetails[0].firstName} {userDetails[0].middleName}{" "}
                {userDetails[0].lastName}
              </h2>
              <h3>Email: {userDetails[0].email}</h3>
              <h3>Contact No: {userDetails[0].mobile}</h3>
              <h3>
                Status: {userDetails[0].statusType ? "Active" : "Inactive"}
              </h3>
            </div>
            <div className="profileMyDetailsBox">
              <h2>Delivery Address</h2>
              <h3>
                {userDetails[0].currAddStreet}, {userDetails[0].currAddTown},{" "}
                {userDetails[0].currAddState}
              </h3>
              <h3>{userDetails[0].currAddPinCode}</h3>
            </div>
          </div>
          <div className="customerDetailsDesktop">
            <CustomerDetails csData={userDetails[0]} orderId="" />
          </div>
          <div className="customerDetailsMobileview">
            <CustomerDetailsMobile csData={userDetails[0]} orderId="" />
          </div>
        </div>
        <Footer />
      </div>
    );
  };

  return <div>{showPage ? customerProfile() : null}</div>;
}
