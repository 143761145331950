import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { a3, a5, a10, paymentApi } from "../../Api/RootApiPath";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import { useSelector } from "react-redux";
import RazorpayPayment from "../../Api/RazorpayPayment";
// import GooglePayButton from "@google-pay/button-react";
import { SHA256 } from "crypto-js";
import GooglepayButton from "../../Api/ApiImages/GooglepayButton";
export default function PaymentPage() {
  const [csData, setCsData] = useState("");
  // const [orderID, setOrderID] = useState("");
  const [amountPaid, setAmountPaid] = useState(0);
  const [qrData, setQRData] = useState("");
  const [url, setUrl] = useState("");

  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const userDetails = orderState.reducer4[0];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  let userOrderCount = 0;
  let userOrderValue = 0;
  let userOrderId = " ";
  let userOrderStatus = " ";
  userOrderCount = orderState.reducer4[0].orderCount;
  userOrderValue = orderState.reducer4[0].orderValue;
  userOrderId = orderState.reducer4[0].orderId;
  userOrderStatus = orderState.reducer4[0].orderStatus;

  const totalPrice = orderItems.reduce((acc, x) => acc + x.price * x.count, 0);
  const navigate = useNavigate();
  let products = "";
  products = orderItems.map(
    (x) => ` ${x.title} : Qty-${x.count} : Price-₹${x.price} `
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [customerId]);
  let updatedOrderCount = `${userOrderCount} ${products} //  `;
  if (userOrderCount == 0) {
    updatedOrderCount = `${products}`;
  } else {
    updatedOrderCount = `${userOrderCount} // ${products} `;
  }
  let updatedOrderValue = `${userOrderValue}, ₹${totalPrice}`;
  if (userOrderValue == 0) {
    updatedOrderValue = `₹${totalPrice}`;
  } else {
    updatedOrderValue = `${userOrderValue} // ₹${totalPrice} `;
  }
  let updatedOrderStatus = "Paid";
  if (userOrderStatus == " ") {
    updatedOrderStatus = "Paid";
  } else {
    updatedOrderStatus = `${userOrderStatus} ${updatedOrderStatus} //`;
  }

  let quantity = "";
  quantity = orderItems.map((x) => `${x.count},`);
  let itemPrice = "";
  itemPrice = orderItems.map((x) => `${x.price},`);
  const updateOrder = (orderId, amount) => {
    setAmountPaid(amount);
    updateOrderCount(orderId);
  };
  const updateOrderCount = async (orderId) => {
    // let newCount = Number(userOrderCount) + 1;
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "OrderCount");
    // formData.append("FieldValue", updatedOrderCount);
    const formData = {
      Id: customerId,
      OrderCount: updatedOrderCount,
    };
    try {
      const response = await fetch(a5, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        updateOrderValue(orderId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateOrderValue = async (orderId) => {
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "OrderValue");
    // formData.append("FieldValue", updatedOrderValue);
    const formData = {
      Id: customerId,
      OrderValue: updatedOrderValue,
    };
    try {
      const response = await fetch(a5, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        updateOrderStatus(orderId);
        // navigate(`/paymentsuccesspage?orderId=${orderId}`);
        // updateOrderDetails(orderId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateOrderStatus = async (orderId) => {
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "OrderStatus");
    // formData.append("FieldValue", updatedOrderStatus);
    const formData = {
      Id: customerId,
      OrderStatus: updatedOrderStatus,
    };
    try {
      const response = await fetch(a5, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        updateOrderId(orderId);
        // navigate(`/paymentsuccesspage?orderId=${orderId}`);
        // updateOrderDetails(orderId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateOrderId = async (orderId) => {
    let updatedOrderId = " ";
    if (userOrderId == " " || userOrderId == null) {
      updatedOrderId = `${orderId} `;
    } else {
      updatedOrderId = `${userOrderId} ${orderId} //`;
    }
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "OrderId");
    // formData.append("FieldValue", updatedOrderId);
    const formData = {
      Id: customerId,
      OrderId: updatedOrderId,
    };
    try {
      const response = await fetch(a5, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        // navigate(`/paymentsuccesspage?orderId=${orderId}`);
        // updateOrderDetails(orderId);
        // navigate(`/customerprofile?orderId=${orderId}`);
        updateOrderTime(orderId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateOrderTime = async (orderId) => {
    const currDate = new Date();
    let currDateAndTime = `// ${currDate.toLocaleDateString()} ${currDate.toLocaleTimeString()} `;
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "DOB");
    // formData.append("FieldValue", currDateAndTime);
    const formData = {
      Id: customerId,
      Dob: currDateAndTime,
    };
    try {
      const response = await fetch(a5, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        // navigate(`/paymentsuccesspage?orderId=${orderId}`);
        // updateOrderDetails(orderId);
        // updateOrderTime()
        navigate(`/customerprofile?orderId=${orderId}`);
        // orderConfirmationEmail(orderId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const orderConfirmationEmail = async (orderId) => {
    // event.preventDefault();
    const config = {
      // Username: "saajanmore03@gmail.com",
      // Password: "AF21BD5AA5EFB6F45CBC233AFDB3CD68337A",
      // Host: "smtp.elasticemail.com",
      // Port: 2525,
      SecureToken: "eb0e35b5-f261-49e7-9a15-70711e96841c",
      To: "saajanmore1995@gmail.com",
      From: "saajanmore03@gmail.com",
      Subject: "This is the subject",
      Body: "And this is the body",
    };
    if (window.Email) {
      window.Email.send(config).then(() =>
        navigate(`/customerprofile?orderId=${orderId}`)
      );
    }
  };
  const productsList = orderItems.map((x) => x.Product_id);
  const productsCount = orderItems.map((x) => x.count);
  const productsPrice = orderItems.map((x) => x.finalPrice);

  console.log("ID", `${productsList}`);
  console.log("count", productsCount);
  console.log("Price", productsPrice);
  // const addOrder = async () => {
  //   const formData = {
  //     Customer_Id: customerId,
  //     // Product_id:
  //   };
  //   try {
  //     const response = await fetch(a5, {
  //       //   method: "POST",
  //       //   body: formData,
  //       // });
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });
  //     const data = await response.json();
  //     if (data.status == "success") {
  //       // navigate(`/paymentsuccesspage?orderId=${orderId}`);
  //       // updateOrderDetails(orderId);
  //       // updateOrderTime()
  //       navigate(`/customerprofile?orderId=${orderId}`);
  //       // orderConfirmationEmail(orderId);
  //     } else {
  //       console.error(data.error);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // useEffect(async () => {
  //   await fetch(a10);
  // }, []);
  // RazorPay Working Code Below

  // const handlePayment = async (
  //   amount,
  //   FirstName,
  //   LastName,
  //   Email,
  //   Mobile,
  //   custId
  // ) => {
  //   const data = await fetch(`${paymentApi}/razorpay`, {
  //     method: "POST",
  //     body: JSON.stringify({ amount, custId }),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   }).then((x) => x.json());

  //   const options = {
  //     // key: "rzp_live_jn0S7ZelnBE85s",
  //     key: "rzp_test_cXuVIdRi8q8yrR",
  //     currency: data.currency,
  //     amount: data.amount,
  //     description: "Wallet Transaction",
  //     image: `${paymentApi}/gharesNewLogo.png`,
  //     order_id: data.id,
  //     handler: function (response) {
  //       const paymentId = response.razorpay_payment_id;
  //       const orderId = response.razorpay_order_id;
  //       // setOrderID(orderId);
  //       alert("Payment Successful! Order ID: " + orderId);
  //       updateOrder(orderId, amount);
  //       window.location.href = `/paymentsuccesspage?orderId=${orderId}&custId=${customerId}`;
  //     },
  //     prefill: {
  //       name: `${FirstName} ${LastName}`,
  //       email: `${Email}`,
  //       contact: `${Mobile}`,
  //     },
  //   };
  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // };

  // PHONEPAY CODE BELOW

  const handlePayment = async (amount, mobile, id, orderId) => {
    // alert("An error Occured,");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        /* your payload data */

        amount,
        mobile,
        id,
        orderId,
      }),
    };

    // fetch("http://localhost:5000/pay", requestOptions)
    fetch(
      "https://mkghare.azurewebsites.net/api/Orders/PaymentRequest",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server
        // setQRData(data.data.instrumentResponse.qrData);
        setUrl(data.data.instrumentResponse.redirectInfo.url);
        // console.log(data);
        // console.log(data.data);
        // console.log(data.data.instrumentResponse);
        // console.log(data.data.instrumentResponse.redirectInfo);
        // console.log(data.data.instrumentResponse.redirectInfo.url);
        // console.log(url);
        window.location.href = data.data.instrumentResponse.redirectInfo.url;
        // var urlRcvd = url;
        // // Open a popup window
        // var popupWindow = window.open(
        //   urlRcvd,
        //   "_blank",
        //   "width=500,height=500"
        // );

        // // Navigate the popup window to the specific URL
        // popupWindow.location.href = url;
        // // navigate(url);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  // console.log(productsList);
  const insertOrder = async (totalPrice, mobile, csId) => {
    // e.preventDefault();
    const formData = {
      Customer_id: customerId,
      // Product_id: `${productsList}`,
      Product_id: parseInt(productsList),
      Qty: `${productsCount}`,
      Price: `${productsPrice}`,
      PaymentMode: "PhonePay",
      Offer: "Offer",
      GovtTax: "GovtTax",
      ReceivedAmt: totalPrice,
      OrderStatus: "Payment Pending",
    };

    try {
      const response = await fetch(a10, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      const orderIdCreated = data.data.orderNumber;
      // console.log(data.data);
      // console.log(orderIdCreated);
      // handlePayment(totalPrice, mobile, csId, orderIdCreated);
      handlePayment(totalPrice, mobile, customerId, orderIdCreated);
      // alert("Order Created");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="accountDetailsMainBox">
        <div style={{ width: "40vw" }} className="accountDetailsFormBox">
          <h2 style={{ margin: "1rem 0" }}>Pay For Order</h2>
          <p style={{ margin: "2rem 0", alignSelf: "flex-start" }}>
            <strong>Products : </strong>
            {products}
          </p>
          <p style={{ margin: "2rem 0", alignSelf: "flex-start" }}>
            <strong>Total Amount : ₹</strong>
            {totalPrice.toFixed(2)}
          </p>
          <p>
            <div className="payentPagePayBtnBox">
              <button
                className="addToCartBtn payBtn"
                // onClick={() =>
                //   handlePayment(
                //     totalPrice,
                //     csData.FirstName,
                //     csData.LastName,
                //     csData.Email,
                //     csData.mobile,
                //     csData.id
                //   )
                // }
                onClick={() => {
                  insertOrder(totalPrice, csData.mobile, csData.id);
                }}
              >
                Pay Now
              </button>
              <button
                className="addToCartBtn payBtn"
                onClick={() => navigate("/shoppage")}
              >
                Cancel
              </button>
              {/* <GooglepayButton /> */}
            </div>
          </p>
          <div className="clear"></div>
        </div>
      </div>

      {/* {qrData ? (
        <img src={`data:image/png;base64,${qrData}`} alt="QR Code" />
      ) : (
        <p>Loading QR code...</p>
      )} */}
      <Footer />
    </div>
  );
}
