import React, { useEffect, useState } from "react";
import "../PagesStyles/CheckOut.css";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineInfoCircle, AiOutlineLock } from "react-icons/ai";
import blank from "../../Images/blankProduct.jpg";
import OrderSummaryProduct from "./OrderSummaryProduct";
import { Await, Link, useNavigate } from "react-router-dom";
import { a2 } from "../../Api/RootApiPath";

export default function CheckOut() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [customerId, setCustomerId] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Customer_login_id", email);

    try {
      const response = await fetch(a2, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "success") {
        setCheckPassword(data.data);

        if (password === checkPassword) {
          alert("Successfully Logged In..");
          setCustomerId(data.data.Customer_id);
          navigate(`/paymentpage?customerId=${customerId}`);
        } else {
          alert("Incorrect Password");
        }
      } else {
        console.error(data.error);
        alert("Sorry either Username or Password is Incorrect");
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="checkOutMainBox">
      <div className="headingBox">
        <Heading />
      </div>
      <h1>Continue To CheckOut</h1>
      <div className="checkOutOptionsContainer">
        <div className="returningCustomerBox">
          <h2>Returning Customer</h2>
          <p style={{ marginBottom: "1rem" }}>
            Lovely, Welcome back! Sign in for faster checkout
          </p>
          <form onSubmit={handleSubmit}>
            <h3>Email</h3>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <h3>Password</h3>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="forgotPasswordBox">
              <p>
                {/* <input type="checkbox" /> */}
                Remember Me
              </p>
              <a href="#">Forgot Password</a>
            </div>
            <button type="submit">CONTINUE TO CHECKOUT </button>
          </form>
        </div>
        <div className="otherCustomerOptionsBox">
          <div className="newCustomerBox">
            <h2>NEW CUSTOMER</h2>
            <p style={{ marginBottom: "1rem" }}>
              Become a Member Today - It's quick and free!
            </p>
            <p className="newCustomerP">
              <GiCheckMark className="newCustomerCheckIcon" />
              100 Bonus points when you join
            </p>
            <p className="newCustomerP">
              <GiCheckMark className="newCustomerCheckIcon" />
              Free standard delivery
            </p>
            <p className="newCustomerP">
              <GiCheckMark className="newCustomerCheckIcon" />
              Access to brand partnerships and offers
            </p>
            <Link to="/accountdetails">
              <button>BECOME A MEMBER</button>
            </Link>
          </div>
          {/* <div className="guestCheckOutBox">
            <h2>Guest Checkout</h2>
            <p>Not ready to become a member just yet?</p>
            <Link to="accountdetails">
              <button>CONTINUE TO CHECKOUT</button>
            </Link>
          </div> */}
        </div>
      </div>
      {/* <div className="orderSummarySectionBox">
        <div className="orderSummaryLeftsection">
          <h1 style={{ textDecoration: "underline" }}>
            Place your order in 3 steps
          </h1>
          <h2>1. Account Details</h2>
          <h2>2. Shipping Address</h2>
          <h2>3. Payment Information</h2>
        </div> */}
      {/* <div className="orderSummaryRightsection">
          <h1>Order Summary</h1>
          <div className="orderSummaryProductBox">
            <div className="orderSummaryProductLeftBox">
              <h3>Item</h3>
            </div>
            <div className="orderSummaryProductRightBox">
              <h3>Total</h3>
            </div>
          </div>
          <div>
            <OrderSummaryProduct />
            <OrderSummaryProduct />
            <h2 style={{ textAlign: "right" }}>₹176000</h2>
            <div className="orderSummaryProductBox">
              <div className="orderSummaryProductLeftBox">
                <h3>Order Value : </h3>
                <h3>
                  Discount Code : <button>Code</button>
                </h3>
                <h3>Delivery : </h3>
                <h3>Total : </h3>
              </div>
              <div className="orderSummaryProductRightBox">
                <h3> Rs.176000</h3>
                <h3> -Rs.0</h3>
                <h3> Rs.750</h3>
                <h3> Rs.176750</h3>
              </div>
            </div>
          </div>
          <h3 style={{ marginTop: "3rem", textAlign: "center" }}>
            Need help? Please Contact
            <span
              style={{
                textDecoration: "underline",
                marginLeft: "0.5rem",
              }}
            >
              Customer Service
            </span>
          </h3>
        </div> */}
      {/* </div>
      <div className="chooseDeliveryOptionsBox">
        <h2>DELIVERY</h2>
        <h3>Choose mode of delivery</h3>
        <AiOutlineInfoCircle size={"1.3rem"} className="infoSymbol" />
      </div>
      <div className="chooseDeliveryOptionsBox">
        <h2>PAYMENTS AND DISCOUNTS</h2>
        <h3>Choose mode of payment</h3>
        <AiOutlineInfoCircle size={"1.3rem"} className="infoSymbol" />
      </div> */}

      <p
        style={{ textAlign: "left", marginLeft: "2rem", marginBottom: "5rem" }}
      >
        <AiOutlineLock style={{ marginRight: "1rem" }} />
        All data will be encrypted
      </p>
      <Footer />
    </div>
  );
}
