import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { s1 } from "../../Api/RootApiPath";
import { AiOutlineMinusSquare } from "react-icons/ai";
import { removeFromCart } from "../../../redux/action/Actions";

export default function OrderSummaryProduct() {
  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const totalItems = orderItems.length;

  let totalPrice = 0;
  const dispatch = useDispatch();
  const handleRemoveFromBag = (index) => {
    dispatch(removeFromCart(index));
  };
  // const ProductNumber = "R14909D88ATZDI8";
  // const Quantity = 8;
  // const Size = 8;
  // const Price = 11000;
  // return (
  // <div>
  //   {totalItems ? (
  //     orderItems.map((x) => (
  //       <div className="orderSummaryProductBoxMain">
  //         <div className="orderSummaryProductBoxMainImg">
  //           <img src={`${s1}${x.image1}`} alt={"ProductImage"} />
  //         </div>
  //         <div className="orderSummaryProductBoxDetails">
  //           <h2>
  //             <em>{x.title}</em>
  //           </h2>
  //           <p>
  //             Product Number:
  //             <span>{x.productNo}</span>
  //           </p>
  //           <p>
  //             Size:<span>{x.size}</span>
  //           </p>
  //           <p>
  //             Qty: <span>{x.count}</span>
  //           </p>
  //         </div>
  //         <div className="orderSummaryProductBoxPrice">
  //           <h2>₹{x.count * x.finalPrice}</h2>
  //         </div>
  //       </div>
  //     ))
  //   ) : (
  //     <div></div>
  //   )}
  return (
    <div>
      {totalItems ? (
        orderItems.map((x) => {
          const productPrice = x.count * x.finalPrice; // calculate price for this product
          totalPrice += productPrice; // add price to total
          return (
            <div className="orderSummaryProductBoxMain">
              <div className="orderSummaryProductBoxMainImg">
                <img src={`${s1}${x.image1}`} alt={"ProductImage"} />
              </div>
              <div className="orderSummaryProductBoxDetails">
                <h2>
                  <em>{x.title}</em>
                </h2>
                <p>
                  Product Number:
                  <span>{x.productNo}</span>
                </p>
                <p>
                  Size:<span>{x.size}</span>
                </p>
                <p>
                  Qty: <span>{x.count}</span>
                </p>
              </div>
              <AiOutlineMinusSquare
                className="orderSummaryMinusIcon"
                size={"1.5rem"}
                onClick={() => handleRemoveFromBag(x)}
              />
              <div className="orderSummaryProductBoxPrice">
                <h2>₹{productPrice}</h2>
              </div>
            </div>
          );
        })
      ) : (
        <div></div>
      )}
      {/* <h2 style={{ textAlign: "right" }}>₹{totalPrice}</h2>{" "} */}
      {/* display total price */}
      <div className="orderSummaryProductBox">
        {totalItems !== 0 ? (
          <>
            <div className="orderSummaryProductLeftBox">
              <h3>Order Value : </h3>
              <h3>
                Discount Code : <button>Code</button>
              </h3>
              <h3>Delivery : </h3>
              <h3>Total : </h3>
            </div>
            <div className="orderSummaryProductRightBox">
              <h3> Rs.{totalPrice}</h3> {/* display total price */}
              <h3> -Rs.0</h3>
              <h3> Rs.750</h3>
              <h3> Rs.{totalPrice + 750}</h3>
              {/* display total price with delivery charge */}
            </div>
          </>
        ) : (
          <h3>Add something to cart</h3>
        )}

        {/* <h2 style={{ textAlign: "right" }}>₹176000</h2>
      <div className="orderSummaryProductBox">
        <div className="orderSummaryProductLeftBox">
          <h3>Order Value : </h3>
          <h3>
            Discount Code : <button>Code</button>
          </h3>
          <h3>Delivery : </h3>
          <h3>Total : </h3>
        </div>
        <div className="orderSummaryProductRightBox">
          <h3> Rs.176000</h3>
          <h3> -Rs.0</h3>
          <h3> Rs.750</h3>
          <h3> Rs.176750</h3>
        </div> */}
      </div>
    </div>
  );
}
