import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeFromCart } from "../../../redux/action/Actions";
import { s1 } from "../../Api/RootApiPath";
import Heading from "../../Heading/Heading";
import HomePage from "../HomePage/HomePage";
import LoginPage from "./LoginPage";
import PaymentPage from "./PaymentPage";
import ShippingAddress from "./ShippingAddress";

export default function CheckoutFullPageOrderSummary() {
  const [step, setStep] = useState(1);
  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRemoveFromBag = (index) => {
    dispatch(removeFromCart(index));
  };
  let totalPrice = 0;
  // useEffect(() => window.scrollTo(0, 0), []);
  // useEffect(() => []);
  return (
    <div
      className="checkoutFullPageOrderSummaryBox"
      // style={{ border: "2px solid red" }}
    >
      <h3>Order Summary</h3>
      {orderItems.map((x) => {
        totalPrice += x.finalPrice * x.count;
        return (
          <div className="orderSummaryCheckoutMain">
            <div className="orderSummaryCheckoutImageBox">
              <img
                className="orderSummaryCheckoutImage secondCheckoutImage"
                src={`${s1}${x.image1}`}
                // src={tempImg}
                alt={"ProductImage"}
              />
              <div className="orderSummaryCheckoutProductDetailsBox">
                <p>{x.title}</p>

                {/* <p>Product Id</p> */}
                <p>Quantity: {x.count}</p>
              </div>
            </div>
            <div className="subTotalAmountBox">
              <p style={{ color: "rgba(0, 0, 0, 0.7)" }}>
                ₹ {x.finalPrice * x.count}
              </p>
            </div>
          </div>
        );
      })}
      {/* <div className="orderSummaryCheckoutMain"> */}
      <div className="orderSummaryCheckoutMainBox orderSummaryCheckoutMainBox2">
        <div className="box1 box1new">
          <p>Subtotal</p>
          <p>Discount Applied</p>

          <p>Delivery Charge</p>
          <p>TOTAL COST</p>
        </div>

        <div className="box2 box2new">
          <p>₹ {totalPrice}</p>
          <p>0</p>
          <p>0</p>
          <p>₹ {totalPrice}</p>
        </div>
      </div>
      <div className="orderSummaryCheckoutMainBox orderSummaryCheckoutMainBox2">
        <div className="box1 box1new">
          <p>TOTAL COST</p>
        </div>

        <div className="box2 box2new">
          <p>₹ {totalPrice}</p>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
