import React, { useEffect } from "react";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { RxCross2, RxCheck } from "react-icons/rx";
import { a1 } from "../../Api/RootApiPath";
import { userLoggedIn } from "../../../redux/action/Actions";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { GrFacebook } from "react-icons/gr";
import AlertMessage from "../../AlertMessages/AlertMessage";

export default function CustomerLoginPage() {
  const allStates = useSelector((state) => state);
  const userDetails = allStates.reducer4;

  const [email, setEmail] = useState("");
  const [allCsData, setAllCsData] = useState([]);
  const [csData, setCsData] = useState([]);
  const [oldUser, setOldUser] = useState("EmailPage");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [dataRcvd, setDataRcvd] = useState(false);
  const [pageFor, setPageFor] = useState("Login");
  const [serverError, setServerError] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };
  console.log(dataRcvd);
  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserExist();
  }, []);
  const checkUserExist = async () => {
    fetch(a1)
      .then((res) => res.json())
      .then((result) => {
        setAllCsData(result.data);
        setDataRcvd(true);
        setServerError(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setDataRcvd(false);
      });
  };

  // console.log(allCsData);
  console.log(userDetails);
  const ifNewUser = (email) => {
    let checkEmailInput = document.getElementById("emailInput");

    if (emailInput.classList.contains("valid")) {
      // console.log("Email Accepted");
      const filteredData = allCsData.filter(
        (x) => x.customer_login_id === email
      );
      if (filteredData.length > 0) {
        console.log("exist"),
          setCsData(filteredData),
          setOldUser("OldUser"),
          window.scrollTo(0, 0);
      } else {
        console.log("new user"),
          setCsData(filteredData),
          setPageFor("Register");
        setOldUser("NewUser"), window.scrollTo(0, 0);
      }
    } else {
      setEmail(" ");
      validateEmail(email), window.scrollTo(0, 0);
    }
  };

  const checkLogin = () => {
    if (password === csData[0].password) {
      return loggedIn(csData), navigate("/customerprofile");
    } else setIncorrectPassword(true);
  };
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  const addNewUser = () => {
    const newUser = [
      {
        currAdd: "",
        dob: "",
        email: email,
        firstName: "Firstname",
        gender: "",
        lastName: "Lastname",
        middleName: "",
        mobile: "",
        password: "",
        perAdd: "",
        pinCode: "",
      },
    ];
    loggedIn(newUser);
    navigate("/checkoutpagenew/n2");
  };
  // console.log(forgotPassword);
  // console.log(csData);
  return (
    <div className="checkOutMainBox">
      {serverError ? (
        <AlertMessage message="Server Error !" type="error" />
      ) : null}
      <div className="headingBox">
        <Heading />
      </div>
      <div
        style={{ marginTop: 0, paddingTop: "50px" }}
        className="checkoutNewLoginMainBox"
      >
        <h2 style={{ marginBottom: "20px" }}>{pageFor} To Ghare's</h2>
        {oldUser === "EmailPage" ? (
          <div
            className="checkoutNewLoginFormBox"
            style={{ paddingTop: "40px" }}
          >
            {/* <div className="loginSocialMediaOptionsBox">
              <button className="socialMediaOption">
                <FcGoogle size={"1.5rem"} style={{ marginRight: "8px" }} />
                <li style={{ color: "grey", listStyle: "none" }}>Google</li>
              </button>
              <button className="socialMediaOption">
                <GrFacebook size={"1.5rem"} style={{ marginRight: "8px" }} />
                <li style={{ color: "grey", listStyle: "none" }}>Facebook</li>
              </button>
            </div>
            <h4
              style={{
                textAlign: "center",
                marginTop: "1rem",
                marginBottom: "0rem",
                color: "grey",
              }}
            >
              Or
            </h4> */}

            <label>
              Email<sup style={{ color: "red" }}>*</sup>
            </label>
            <div
              id="emailInput"
              className={`password-input ${
                email && !validateEmail(email) ? "invalid" : ""
              } ${email && validateEmail(email) ? "valid" : ""} ${
                !email ? "blank" : ""
              }`}
            >
              <input
                id="emailInputField"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
              />
              {email && !validateEmail(email) && (
                <p style={{ margin: "0" }} className="invalid">
                  Please enter a valid email
                </p>
              )}
              {email && validateEmail(email) && (
                <RxCheck className="checkMark" size="20px" />
              )}
              {email && !validateEmail(email) && (
                <RxCross2
                  color="red"
                  style={{ top: "40%" }}
                  className="checkMark"
                  size="20px"
                />
              )}
            </div>

            {/* <p>{email}</p> */}
            {/* {dataRcvd ? ( */}
            <button
              className="checkoutNewLoginFormBoxButton"
              onClick={() =>
                dataRcvd ? ifNewUser(email) : setServerError(true)
              }
            >
              Continue
            </button>
            {/* // ) : null} */}
            <p
              id="newToGhareLine"
              onClick={() => {
                navigate("/customersignuppage");
                // setPageFor("Register");
                // setOldUser("RegisterPage");
              }}
              style={{ textAlign: "center" }}
            >
              New To Ghare's, <span>Create an Account</span>
            </p>
          </div>
        ) : oldUser === "OldUser" ? (
          <div className="checkoutNewLoginFormBox">
            <h3 style={{ color: "black" }}>Welcome !</h3>
            <p style={{ margin: "10px 0", marginBottom: "30px" }}>
              **********{email.substr(-10, 100)}
            </p>
            {/* <p style={{ margin: "20px 0" }}>
              Please login to proceed to checkout.
            </p> */}
            <label>
              Password<sup style={{ color: "red" }}>*</sup>
            </label>
            {/* <div id="password-input" className={`password-input`}> */}
            <div
              className={`password-input ${incorrectPassword ? "invalid" : ""}`}
            >
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="toggle-password-visibility"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </button>
              {incorrectPassword ? (
                <p style={{ margin: "0" }} className="invalid">
                  Wrong email or password, please try again.
                </p>
              ) : null}
              {incorrectPassword ? (
                <RxCross2
                  color="red"
                  style={{ top: "40%" }}
                  className="checkMark"
                  size="20px"
                />
              ) : null}
            </div>

            <Link to="/changepassword">
              <p style={{ textAlign: "end", color: "black" }}>
                Forgot Password
              </p>
            </Link>
            {dataRcvd ? (
              <button
                style={{ margin: "10px 0" }}
                className="checkoutNewLoginFormBoxButton"
                onClick={() => checkLogin()}
              >
                Continue
              </button>
            ) : null}
          </div>
        ) : (
          <>
            <div
              style={{ marginBottom: "10px" }}
              className="checkoutNewLoginFormBox"
            >
              <h3 style={{ color: "black" }}>Welcome !</h3>
              <p style={{ margin: "10px 0", marginBottom: "20px" }}>{email}</p>
              <p>Become a member today – It’s quick and free!</p>
            </div>
            <div
              style={{ marginTop: "10px", marginBottom: "20px" }}
              className="checkoutNewLoginFormBox"
            >
              <h3 style={{ color: "black" }}>New customer benefits</h3>
              <p>Become a member today – It’s quick and free!</p>
              <p style={{ margin: "0", display: "flex", alignItems: "center" }}>
                <RxCheck
                  style={{ color: "grey", marginRight: "10px" }}
                  size={"20px"}
                />
                100 bonus points when you join
              </p>
              <p style={{ margin: "0", display: "flex", alignItems: "center" }}>
                <RxCheck
                  style={{ color: "grey", marginRight: "10px" }}
                  size={"20px"}
                />
                Free standard delivery
              </p>
              <p style={{ margin: "0", display: "flex", alignItems: "center" }}>
                <RxCheck
                  style={{ color: "grey", marginRight: "10px" }}
                  size={"20px"}
                />
                Access to brand partnerships and offers
              </p>

              {dataRcvd ? (
                <button
                  className="checkoutNewLoginFormBoxButton"
                  onClick={() => navigate("/customersignuppage")}
                >
                  Register Now
                </button>
              ) : null}
            </div>
            <p
              style={{
                textAlign: "center",
                cursor: "pointer",
                fontSize: "13px",
                marginBottom: "100px",
              }}
            >
              Already have a account?{" "}
              <u
                onClick={() => {
                  setOldUser("EmailPage"),
                    setEmail(""),
                    setPassword(""),
                    setPageFor("Login");
                  window.scrollTo(0, 0);
                }}
              >
                Login Here
              </u>
            </p>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
}
