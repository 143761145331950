import React, { useEffect, useState } from "react";
import { a1 } from "../../Api/RootApiPath";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

export default function AdminAllCustomers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csName, setCsName] = useState("");
  const [csMobile, setCsMobile] = useState("");
  const [csEmail, setCsEmail] = useState("");
  const [csAddress, setCsAddress] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage, setCustomersPerPage] = useState(25);

  const navigate = useNavigate();

  const handleCustomersPerPageChange = (e) => {
    setCustomersPerPage(parseInt(e.target.value));
  };
  // let customersPerPage = 25;

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  useEffect(() => {
    fetch(a1)
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
        setData(response.data);
        setFilteredCustomers(response.data.reverse());
        setLoading(false);

        // setOlddata(response);
      });
  }, [loading]);
  console.log(data);

  const indexOfLastProduct = currentPage * customersPerPage;
  const indexOfFirstProduct = indexOfLastProduct - customersPerPage;
  const currentCustomers = filteredCustomers.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredCustomers.length / customersPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const filterCustomer = () => {
    let filtered = data;

    if (csName !== "") {
      filtered = filtered.filter(
        (customer) =>
          (customer.firstName &&
            customer.firstName.toLowerCase().includes(csName.toLowerCase())) ||
          (customer.lastName &&
            customer.lastName.toLowerCase().includes(csName.toLowerCase()))
      );
    }
    if (csMobile !== "") {
      filtered = filtered.filter((customer) =>
        customer.mobile.includes(csMobile)
      );
    }
    if (csEmail !== "") {
      filtered = filtered.filter((customer) =>
        customer.email.includes(csEmail)
      );
    }
    if (csAddress !== "") {
      filtered = filtered.filter(
        (customer) =>
          (customer.perAddStreet &&
            customer.perAddStreet
              .toLowerCase()
              .includes(csAddress.toLowerCase())) ||
          (customer.perAddTown &&
            customer.perAddTown
              .toLowerCase()
              .includes(csAddress.toLowerCase())) ||
          (customer.perAddState &&
            customer.perAddState
              .toLowerCase()
              .includes(csAddress.toLowerCase())) ||
          (customer.perAddPinCode &&
            customer.perAddPinCode.includes(csAddress)) ||
          (customer.currAddStreet &&
            customer.currAddStreet.toLowerCase().includes(csAddress)) ||
          (customer.currAddTown &&
            customer.currAddTown.toLowerCase().includes(csAddress)) ||
          (customer.currAddState &&
            customer.currAddState.toLowerCase().includes(csAddress)) ||
          (customer.currAddPinCode &&
            customer.currAddPinCode.toLowerCase().includes(csAddress))
      );
    }

    setFilteredCustomers(filtered);
  };

  useEffect(() => {
    filterCustomer();
    window.scrollTo(0, 0);
  }, [csName, data, currentPage, csMobile, csEmail, csAddress]);

  console.log(csName);
  return (
    <div className="allCustomersTableMain">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState
            ? "adminContentbox allCustomersTable"
            : "adminContentbox adminContentbox2 allCustomersTable"
        }
      >
        <h2 style={{ marginBottom: "2rem", marginTop: "1rem" }}>
          All Customers
        </h2>
        <div className={loading == true ? "loading" : "none"}>
          {/* <h1>Loading...</h1> */}
          <InfinitySpin width="200" color="#4fa94d" />
        </div>
        <div>
          <div className="adminAllOrdersFilterBox">
            <div className="adminAllOrderLeftBox adminAllCustomersLeftBox">
              <p style={{ marginInline: "0" }}>Display</p>
              <select
                value={customersPerPage}
                onChange={handleCustomersPerPageChange}
              >
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
                <option value={"200"}>200</option>
              </select>
              <p style={{ marginInline: "0" }}>Customers</p>
              <input
                type="text"
                placeholder="Search By Name..."
                value={csName}
                onChange={(e) => {
                  setCsName(e.target.value), setCurrentPage(1);
                }}
              />
              <input
                type="text"
                placeholder="Search By Mobile..."
                value={csMobile}
                onChange={(e) => {
                  setCsMobile(e.target.value), setCurrentPage(1);
                }}
              />
              <input
                type="text"
                placeholder="Search By Email..."
                value={csEmail}
                onChange={(e) => {
                  setCsEmail(e.target.value), setCurrentPage(1);
                }}
              />
              <input
                type="text"
                placeholder="Search By Address..."
                value={csAddress}
                onChange={(e) => {
                  setCsAddress(e.target.value), setCurrentPage(1);
                }}
              />
            </div>
            <div className="adminAllOrderRightBox">
              <button
                style={{
                  whiteSpace: "nowrap",
                  padding: "10px 20px",
                  width: "auto",
                }}
                onClick={() => navigate("/adminaddcustomer")}
              >
                <CgProfile size={"15px"} style={{ marginRight: "5px" }} />
                Add New Customer
              </button>
              <button>Export</button>
            </div>
          </div>
          <table style={{ padding: "20px" }}>
            <thead style={{ whiteSpace: "nowrap" }}>
              <tr>
                <th>Id</th>
                {/* <th>Customer_login_id</th> */}
                <th>Customer Name</th>
                {/* <th>MiddleName</th> */}
                {/* <th>LastName</th> */}
                <th>Mobile</th>
                <th>Email</th>
                {/* <th>PinCode</th> */}
                {/* <th>Gender</th> */}
                {/* <th>DOB</th> */}
                <th>Delivery Address</th>
                <th>Billing Address</th>
                <th>Created On</th>
                {/* <th>StatusType</th> */}
              </tr>
            </thead>
            {/* {!loading ? ( */}
            <tbody>
              {currentCustomers.map((x) => (
                // <tr key={x.Customer_id}>
                <tr key={x.id}>
                  <td>{x.id}</td>
                  {/* <td>{x.Customer_login_id}</td> */}
                  <td>{`${x.firstName} ${x.lastName}`}</td>
                  {/* <td>{x.MiddleName}</td> */}
                  {/* <td>{x.LastName}</td> */}
                  <td>{x.mobile}</td>
                  <td>{x.email}</td>
                  {/* <td>{x.PinCode}</td> */}
                  {/* <td>{x.Gender}</td> */}
                  {/* <td>{x.DOB}</td> */}
                  <td>
                    {x.currAddStreet !== null ? x.currAddStreet : ""}{" "}
                    {x.currAddTown !== null ? x.currAddTown : ""}{" "}
                    {x.currAddState !== null ? x.currAddState : ""}{" "}
                    {x.currAddPinCode !== null ? x.currAddPinCode : ""}
                  </td>
                  <td>
                    {x.perAddStreet !== null ? x.perAddStreet : ""}{" "}
                    {x.perAddTown !== null ? x.perAddTown : ""}{" "}
                    {x.perAddState !== null ? x.perAddState : ""}{" "}
                    {x.perAddPinCode !== null ? x.perAddPinCode : ""}
                  </td>
                  {/* <td>{`${x.perAddStreet} ${x.perAddTown} ${x.perAddState} ${x.perAddPinCode} `}</td> */}
                  <td>{x.createdOn}</td>
                  {/* <td>{x.StatusType}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* ) : ( */}
        {/* <p style={{ margin: "10px 0" }}>Loading</p> */}
        {/* )} */}
        <div className="bulkProductAddingTableMain">
          <button onClick={goToPreviousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <button onClick={goToNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
