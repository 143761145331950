import React, { useEffect, useState } from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { a18, a22, a23, a37 } from "../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { AiOutlineEdit } from "react-icons/ai";
import { InfinitySpin } from "react-loader-spinner";
import { AiOutlineClose } from "react-icons/ai";

export default function AdminAddPurity() {
  const [categories, setCategories] = useState([]);
  const [purity, setPurity] = useState("");
  const [label, setLabel] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [purityData, setPurityData] = useState([]);
  const [categoryName, setCategoryName] = useState("");

  const [popUp, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productInUpdate, setProductInUpdate] = useState([]);

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, [showForm]);
  useEffect(() => {
    fetch(a18)
      .then((res) => res.json())
      .then((data) => setCategories(data.data));
  }, [showForm]);

  const fetchAllPurity = async () => {
    await fetch(a22)
      .then((res) => res.json())
      .then((data) => setPurityData(data.data));
    console.log(purityData);
  };

  useEffect(() => {
    fetchAllPurity();
  }, [showForm]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let Label = `${categoryName}${purity}`;
    const formData = {
      Category: categoryName,
      Purity: purity,
      Label: label,
    };
    // const formData = new FormData();

    // formData.append("Category", category);
    // formData.append("Purity", purity);
    // formData.append("Label", label);
    // const formData = {};
    try {
      const response = await fetch(a23, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log(data);
      // alert("Added Successfully");
      setShowForm(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, property) => {
    const updatedProduct = {
      ...productInUpdate,
      [property]: e.target.value,
    };

    setProductInUpdate(updatedProduct);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      Id: productInUpdate.id,

      Category: productInUpdate.category,
      Purity: productInUpdate.purity,
      Label: productInUpdate.label,
      TodaysRate: productInUpdate.todaysRate,
      // HsnCode: productInUpdate.hsnCode,
      // Description: productInUpdate.description,
      // Entryby_Staff_id: Entryby_Staff_id,
      // Id: productInUpdate.id,
      // Name: productInUpdate.name,
      // ParentsCategory: productInUpdate.parentsCategory,
      // Description: productInUpdate.description,
      // Slug: productInUpdate.slug,
      // Label: productInUpdate.label,
      // Entryby_Staff_id: Entryby_Staff_id,
    };
    try {
      const response = await fetch(a37, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      // alert("updated");
      setLoading(false);
      fetchAllPurity();
      setProductInUpdate([]);
      setPopup(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        {showForm ? (
          <div
            style={{ flexDirection: "column" }}
            className="adminAddCategoryMainbox"
          >
            <button
              style={{
                width: "200px",
                alignSelf: "flex-start",
                margin: "2rem",
                marginBottom: "0",
              }}
              onClick={() => {
                setShowForm(!showForm);
              }}
            >
              Back To Purity List
            </button>
            {/* </div> */}
            <div className="adminAddCategoryMainbox">
              <form onSubmit={handleSubmit}>
                <h3>ADD PURITY</h3>
                <label htmlFor="category">Category:</label>
                <select
                  required="required"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                >
                  <option value="">Select an option</option>
                  {categories.map((x) => {
                    return <option value={x.category}>{x.name}</option>;
                  })}
                </select>

                <label htmlFor="purity">Purity:</label>
                <input
                  type="text"
                  required="required"
                  id="purity"
                  value={purity}
                  onChange={(e) => setPurity(e.target.value)}
                />

                <label htmlFor="label">Label:</label>
                <input
                  type="text"
                  required="required"
                  id="label"
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                />
                <button type="submit">Submit</button>
              </form>

              {/* <input type="submit" name="feessubmit" value="Submit" /> */}
            </div>
          </div>
        ) : (
          <div
            style={{ flexDirection: "column" }}
            className="adminAddCategoryMainbox"
          >
            <button
              style={{
                width: "200px",
                alignSelf: "flex-end",
                margin: "2rem",
                marginBottom: "0",
              }}
              onClick={() => {
                setShowForm(!showForm);
                // setProductTypeData("");
              }}
            >
              Add New Purity
            </button>
            <div className="adminAddCategoryMainbox">
              <form style={{ width: "100%" }}>
                <h3 style={{ width: "100%" }}>Purities</h3>
                <table
                  className="allCustomersTable"
                  style={{ width: "90%", margin: "1rem 0rem" }}
                >
                  <thead>
                    <tr style={{ paddingBottom: "1rem" }}>
                      <th>Id</th>
                      <th>Category</th>
                      <th>Purity</th>
                      {/* <th>Rate</th> */}
                      <th>Label</th>
                    </tr>
                  </thead>

                  <tbody data-aos="zoom-in">
                    {purityData.map((x) => (
                      <tr key={x.id}>
                        <td>{x.id}</td>
                        <td>{x.category}</td>
                        <td>{x.purity}</td>
                        {/* <td>{x.todaysRate}</td> */}
                        <td>{x.label}</td>
                        <td>
                          <AiOutlineEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setPopup(true);
                              setProductInUpdate(x);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>
              <div
                style={{
                  height: "auto",
                }}
                className={
                  popUp == true
                    ? "updateAccountDetailsPopupMainBox editCategoryPopBox"
                    : "none"
                }
              >
                <div
                  className="updateAccountDetailsPopupCloseBtn"
                  style={{ margin: "0.5rem" }}
                >
                  <AiOutlineClose
                    size={"3rem"}
                    onClick={() => {
                      setPopup(false);
                      // setShowEdit(!showEdit);
                    }}
                  />
                </div>
                <div
                  style={{ height: "100px", marginBottom: "1rem" }}
                  className={loading == true ? "loading" : "none"}
                >
                  <InfinitySpin
                    className={loading == true ? "loading" : "none"}
                    width="150"
                    color="#4fa94d"
                  />
                </div>
                <div
                  className="editCategoryFormBox"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <form
                    style={{ marginTop: "0", paddingTop: 0 }}
                    onSubmit={handleEdit}
                  >
                    <label htmlFor="category">Category:</label>
                    <select
                      id="category"
                      placeholder={productInUpdate.category}
                      onChange={(e) => handleInputChange(e, "category")}
                    >
                      <option value="">Select an option</option>
                      {categories.map((x) => {
                        return <option value={x.name}>{x.name}</option>;
                      })}
                    </select>

                    <label htmlFor="purity">Purity:</label>
                    <input
                      type="text"
                      id="purity"
                      placeholder={productInUpdate.purity}
                      onChange={(e) => handleInputChange(e, "purity")}
                    />

                    <label htmlFor="label">Label</label>
                    <input
                      type="text"
                      id="label"
                      placeholder={productInUpdate.label}
                      onChange={(e) => handleInputChange(e, "label")}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "2fr",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button type="submit" style={{ width: "100%" }}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              onClick={() => setPopup(false)}
              className={popUp === true ? "new" : "new2"}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
}
