import React, { useState, useEffect } from "react";
import { a44 } from "../../Api/RootApiPath";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import AdminProductCard from "./AdminComponent/AdminProductCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";

export default function AdminNewList() {
  const [allProducts, setAllProducts] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const productsPerPage = 100;

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  const navigate = useNavigate();
  //firebase code
  // const firebaseConfig = {
  //   // Your configuration details here
  //   apiKey: "AIzaSyDerNHPJwykR6GA6H_tMb7p-eMdQ8uR13k",
  //   authDomain: "loyalstrings-3c53b.firebaseapp.com",
  //   databaseURL: "https://loyalstrings-3c53b-default-rtdb.firebaseio.com",
  //   projectId: "loyalstrings-3c53b",
  //   storageBucket: "loyalstrings-3c53b.appspot.com",
  //   messagingSenderId: "423371897105",
  //   appId: "1:423371897105:web:5cd4266256c8722a6034b0",
  //   measurementId: "G-GZGQ6XMDSJ",
  // };

  // const app = initializeApp(firebaseConfig);
  // const database = getDatabase(app);
  // const dataref = ref(database, "testdata");

  // const fetchData = () => {
  //   onValue(dataref, (snapshot) => {
  //     const data2 = snapshot.val();
  //     console.log("check allp" + data2);
  //     // setFirebaseData(data2);
  //     setAllProducts([data2]), setLoading(false);
  //   });
  // };

  const fetchAllProducts = async () => {
    fetch(a44)
      .then((res) => res.json())
      .then((data) => {
        setAllProducts(data.data.reverse()), setLoading(false);
      });
  };
  console.log(allProducts);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllProducts();
    // fetchData();
  }, []);

  console.log(allProducts);
  // console.log(allProducts);
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = allProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(allProducts.length / productsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const handleCheckboxChange = (productId) => {
    if (productId === "all") {
      // Select/deselect all checkboxes
      setSelectAll(!selectAll);
      setSelectedProducts(selectAll ? [] : allProducts.map((x) => x.id));
    } else {
      // Check if the product is already selected
      const isSelected = selectedProducts.includes(productId);

      // If the product is already selected, remove it from the list
      if (isSelected) {
        setSelectedProducts((prevSelected) =>
          prevSelected.filter((id) => id !== productId)
        );
      } else {
        // If the product is not selected, add it to the list
        setSelectedProducts((prevSelected) => [...prevSelected, productId]);
      }
    }
  };
  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState
            ? "adminContentbox allCustomersTable"
            : "adminContentbox adminContentbox2 allCustomersTable"
        }
      >
        <h2 style={{ margin: "1rem 0" }}>All Products List</h2>
        <div className={loading == true ? "loading" : "none"}>
          {/* <h1>Loading...</h1> */}
          <InfinitySpin width="200" color="#4fa94d" />
        </div>

        {/*  {
      "createdBy": "vishwas",
      "tidValue": null,
      "epcValue": "",
      "category": "gold",
      "product": "kada",
      "purity": "100ct",
      "barcodeNumber": "",
      "itemCode": "KD345",
      "box": "Gold",
      "grossWeight": "23.455",
      "netWeight": "43.32",
      "stoneweight": "4.76",
      "makinggm": "",
      "makingper": "10",
      "fixedamount": "",
      "fixedwastage": "",
      "stoneamount": "",
      "mrp": "5000",
      "hudicode": "67677",
      "partycode": "0045",
      "updatedDate": "22/07/2023",
      "updatedBy": "67",
      "tagstate": "",
      "tagtransaction": "yes",
      "status": "active",
      "id": 1,
      "createdOn": "2023-07-28T16:46:04.4137019",
      "lastUpdated": "2023-08-03T10:35:36.2683994",
      "statusType": true
    }, */}

        {loading == false ? (
          <div style={{ overflowX: "auto", padding: "0 2rem" }}>
            <table style={{ width: "98%", marginLeft: "1rem" }}>
              <thead>
                <tr style={{ whiteSpace: "nowrap" }}>
                  <th>Id</th>
                  <th>Created By</th>
                  <th>TID</th>
                  <th>EPC</th>
                  <th>Category</th>
                  <th>Product</th>
                  <th>Purity</th>
                  <th>Barcode</th>
                  <th>Item Code</th>
                  <th>Box</th>
                  <th>Gross Wt</th>
                  <th>Stone WT</th>
                  <th>Net Wt</th>
                  <th>Making/gm</th>
                  <th>Making Per</th>
                  <th>Fixed Amount</th>
                  <th>Fixed Wastage</th>
                  <th>Stone Amount</th>
                  <th>MRP</th>
                  <th>Huid Code</th>
                  <th>Party Code</th>
                  <th>Updated Date</th>
                  <th>Updated By</th>
                  <th>Tag State</th>
                  <th>Tag Transaction</th>
                  <th>Status</th>

                  {/* <th>Tid</th> */}
                  {/* <th>Select</th> */}
                </tr>
              </thead>
              <tbody style={{ position: "relative" }}>
                {currentProducts.map((x, index) => (
                  <tr key={x.id}>
                    {/* <td>{index}</td> */}
                    <td>{x.id}</td>
                    <td>{x.createdBy}</td>
                    {/* <td>{x.product_Name}</td> */}
                    <td>{x.tidValue}</td>
                    <td>{x.epcValue}</td>
                    <td>{x.category}</td>
                    <td>{x.product}</td>
                    <td>{x.purity}</td>
                    <td>{x.barcodeNumber}</td>
                    <td>{x.itemCode}</td>
                    <td>{x.box}</td>
                    <td>{parseFloat(x.grossWeight).toFixed(3)}</td>
                    <td>{parseFloat(x.stoneweight).toFixed(3)}</td>
                    <td>{parseFloat(x.netWeight).toFixed(3)}</td>
                    <td>{x.makinggm}</td>
                    <td>{x.makingper}</td>
                    <td>{x.fixedamount}</td>
                    <td>{x.fixedwastage}</td>
                    <td>{x.stoneamount}</td>
                    <td>₹{x.mrp}</td>
                    <td>{x.hudicode}</td>
                    <td>{x.partycode}</td>
                    <td>{x.updatedDate}</td>
                    <td>{x.updatedBy}</td>
                    <td>{x.tagstate}</td>
                    <td>{x.tagtransaction}</td>
                    <td>{x.status}</td>
                    {/* <td>{x.tid}</td> */}
                    {/* <td>
                      <input
                        onChange={() => handleCheckboxChange(x.id)}
                        type="checkbox"
                        checked={selectAll || selectedProducts.includes(x.id)}
                      />
                    </td> */}
                  </tr>
                ))}
              </tbody>
              {/* <div className="adminAllLabelledListButtonBox">
                <button onClick={printAll}>Print</button>
                <button
                onClick={() => {
                    setSelectedProducts([]);
                    setSelectAll(false);
                  }}
                >
                  Reset
                </button>
              </div> */}
            </table>
          </div>
        ) : null}

        {/* Pagination controls */}
        <div className="bulkProductAddingTableMain">
          <button onClick={goToPreviousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <button onClick={goToNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
