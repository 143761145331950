import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import {
  a1,
  a16,
  a18,
  a20,
  a22,
  a33,
  a41,
  a48,
  a49,
  a50,
  a51,
} from "../../Api/RootApiPath";
import { InfinitySpin } from "react-loader-spinner";
import { RxCross2 } from "react-icons/rx";
import { ImCancelCircle } from "react-icons/im";
import { useNavigate } from "react-router-dom";

export default function AdminInvoice() {
  const [allCsData, setAllCsData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [allProductData, setAllProductData] = useState([]);
  const [allCategoriesData, setAllCategoriesData] = useState([]);
  const [allProductTypeData, setAllProductTypeData] = useState([]);
  const [allCollectionData, setAllCollectionData] = useState([]);
  const [allPuritiesData, setAllPuritiesData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [allSelectedProducts, setAllSelectedProducts] = useState([]);
  const [labelName, setLabelName] = useState("");
  const [barcode, setBarcode] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [productName, setProductName] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [purityType, setPurityType] = useState("");
  const [productQty, setProductQty] = useState("");
  const [selectedProductPrice, setSelectedProductPrice] = useState(0);
  const [allProdctsNetAmount, setAllProdctsNetAmount] = useState(0);
  const [allProdctsGstAmount, setAllProdctsGstAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const [totalPayableGstAmount, setTotalPayableGstAmount] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [orderItemsData, setOrderItemsData] = useState("");
  const [billType, setBillType] = useState(false);

  const [loading, setLoading] = useState(false);
  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  useEffect(() => {
    fetch(a1)
      .then((res) => res.json())
      .then((response) => {
        setAllCsData(response.data);
      });
  }, []);
  const selectedCategoryName =
    allSelectedProducts.length > 0 ? allSelectedProducts[0].category_Name : "";

  const filteredProductData = allProductData.filter(
    (product) => product.category_Name === selectedCategoryName
  );
  const navigate = useNavigate();
  // useEffect(() => {
  //   fetch(a41)
  //     .then((res) => res.json())
  //     .then((response) => {
  //       setAllProductData(response.data);
  //     });
  // }, []);
  const productsData = useSelector((state) => state.reducer7.products);
  // console.log("Fetched Products:", productsData);
  useEffect(() => {
    setAllProductData(productsData);
  }, []);
  useEffect(() => {
    if (selectedCustomer) {
      setCustomerName(
        `${selectedCustomer.firstName} ${selectedCustomer.lastName}`
      );
      setCustomerMobile(selectedCustomer.mobile);
      setCustomerId(selectedCustomer.id);
    } else {
      setCustomerName("");
      setCustomerMobile("");
      setCustomerEmail("");
      setCustomerId("");
    }
  }, [selectedCustomer]);
  useEffect(() => {
    if (selectedProduct) {
      setCategoryName(selectedProduct.category_Name);
      setProductName(selectedProduct.itemType);
      setCollectionName(selectedProduct.collection);
      setPurityType(selectedProduct.purity);
      setBarcode(selectedProduct.barcodeNumber);
      setLabelName(selectedProduct.itemCode);
    } else {
      setCustomerName("");
      setCustomerMobile("");
      setCustomerEmail("");
    }
  }, [selectedProduct]);

  const filteredCustomers = allCsData.filter((customer) => {
    const fullName = `${customer.firstName} ${customer.lastName}`;
    return fullName.toLowerCase().includes(customerName.toLowerCase());
  });
  const handleNameInputChange = (e) => {
    const { value } = e.target;
    setCustomerName(value); // Update the name input value

    const selected = allCsData.find((customer) => {
      const fullName = `${customer.firstName} ${customer.lastName}`;
      return fullName.toLowerCase() === value.toLowerCase();
    });

    if (selected) {
      setCustomerEmail(selected.email);
      setCustomerId(selected.id); // Update the email input value based on selected customer's email
    }

    setSelectedCustomer(selected); // Update the selected customer based on name match
  };

  const handleMobileInputChange = (e) => {
    const { value } = e.target;
    setCustomerMobile(value); // Update the mobile input value

    const selected = allCsData.find((customer) => customer.mobile === value);

    if (selected) {
      setCustomerEmail(selected.email);
      setCustomerId(selected.id); // Update the email input value based on selected customer's email
    }

    setSelectedCustomer(selected); // Update the selected customer based on mobile match
  };

  const handleEmailInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerEmail(value); // Update the mobile input value

    const selected = allCsData.find((customer) => customer.email == value);
    setSelectedCustomer(selected); // Update the selected customer based on mobile match
  };
  const handleProductLabelChange = (e) => {
    const { value } = e.target;
    setLabelName(value);
    setSelectedProduct([]);
    setCategoryName("");
    setProductName("");
    setCollectionName("");
    setPurityType("");
    setProductQty("");
    setBarcode("");
    setSelectedProductPrice(0);
    if (value) {
      const selected = allProductData.find(
        (product) => product.itemCode === value
      );
      if (selected) {
        setSelectedProduct(selected);
        calculateFinalPrice(selected); // Calculate the final price
      } else {
        console.log("Not selected");
      }
    }
  };
  const handleProductBarcodeChange = (e) => {
    const { value } = e.target;
    setBarcode(value);
    setLabelName("");
    setSelectedProduct([]);
    setCategoryName("");
    setProductName("");
    setCollectionName("");
    setPurityType("");
    setProductQty("");
    setSelectedProductPrice(0);
    if (value) {
      const selected = allProductData.find(
        (product) => product.barcodeNumber === value
      );
      if (selected) {
        setSelectedProduct(selected);
        calculateFinalPrice(selected); // Calculate the final price
      } else {
        console.log("Not selected");
      }
    }
  };

  const addProductToList = (selectedProduct) => {
    if (!allSelectedProducts.some((x) => x.id === selectedProduct.id)) {
      setAllSelectedProducts((prevItems) => [...prevItems, selectedProduct]);
    } else {
      alert("Product Already added");
    }
  };

  const handleInputChange = (e, productId, property) => {
    // if (allSelectedProducts.length > 0) {
    //   setAllSelectedProducts((x) => x.filter((y) => y !== selectedProduct));
    // }
    const { value } = e.target;
    // setBarCodeAlert(false);
    const updatedProducts = allSelectedProducts.map((product) => {
      if (product.id === productId) {
        // Parse properties to numbers or set them as 0 if the value is empty or invalid
        const grosswt = parseFloat(product.grosswt) || 0;
        const stoneWeight = parseFloat(product.stoneWeight) || 0;
        const netWt = parseFloat(product.netWt) || 0;

        // Update the specific property in the product object
        let updatedProduct = { ...product, [property]: value };

        if (property === "barcodeNumber") {
          // Convert the barcode number to uppercase before doing the comparison
          const barcodeValue = value.toUpperCase();
          updatedProduct.barcodeNumber = barcodeValue; // Set the barcodeNumber property to uppercase

          // Find a matching product in the rifdData array
          const matchingProduct = rifdData.find(
            (item) => item.barcodeNumber === barcodeValue
          );

          if (matchingProduct) {
            updatedProduct.tid = matchingProduct.tid;
          } else {
            // If no matching product found, set 'tid' to null or some default value
            updatedProduct.tid = null; // or any default value you want
            // setBarCodeAlert(true);
          }
        }

        // If 'grosswt' is changed, calculate 'netWt'
        if (property === "grosswt" && !isNaN(value)) {
          updatedProduct.netWt = (parseFloat(value) - stoneWeight).toFixed(3);
        }

        // If 'stoneWeight' is changed, calculate 'netWt'
        if (property === "stoneWeight" && !isNaN(value)) {
          updatedProduct.netWt = (grosswt - parseFloat(value)).toFixed(3);
        }

        // If 'netWt' is changed, calculate 'grosswt' and 'stoneWeight'
        if (property === "netWt" && !isNaN(value)) {
          updatedProduct.grosswt = (parseFloat(value) + stoneWeight).toFixed(3);
          updatedProduct.stoneWeight = (grosswt - parseFloat(value)).toFixed(3);
        }

        setSelectedProduct(updatedProduct);

        return updatedProduct;
      }
      return product;
    });

    setAllSelectedProducts(updatedProducts);
  };
  useEffect(() => {
    recalculatePrices();
  }, [allSelectedProducts]);

  useEffect(() => {
    if (selectedProduct.length > 0) {
      const finalPrice = calculateFinalPrice(
        selectedProduct.netWt,
        selectedProduct.making_per_gram,
        selectedProduct.making_Percentage,
        selectedProduct.making_Fixed_Amt,
        selectedProduct.making_Fixed_Wastage,
        selectedProduct.stoneAmount,
        selectedProduct.mrp,
        selectedProduct.tblPurity.todaysRate,
        selectedProduct.id
      );

      setSelectedProductPrice(finalPrice); // Set the calculated final price here
      setTotalPrice((x) => parseFloat(x) + finalPrice);
    }
  }, [selectedProduct]);

  const calculateFinalPrice = (selectedProduct) => {
    let netGoldRate =
      (parseFloat(selectedProduct.netWt) *
        parseFloat(selectedProduct.tblPurity.todaysRate)) /
      10;
    let makingCharges1 =
      parseFloat(selectedProduct.netWt) *
      parseFloat(selectedProduct.making_per_gram);
    let makingCharges2 =
      (parseFloat(netGoldRate) *
        parseFloat(selectedProduct.making_Percentage)) /
      100;
    let makingCharges3 = parseFloat(selectedProduct.making_Fixed_Amt);
    let makingCharges4 =
      (parseFloat(selectedProduct.tblPurity.todaysRate) *
        parseFloat(selectedProduct.making_Fixed_Wastage)) /
      10;
    let GST = 0.03;

    let grossTotalRate =
      parseFloat(netGoldRate) +
      parseFloat(makingCharges1) +
      parseFloat(makingCharges2) +
      parseFloat(makingCharges3) +
      parseFloat(makingCharges4) +
      parseFloat(selectedProduct.stoneAmount);
    let GSTAdded = parseFloat(GST) * parseFloat(grossTotalRate);
    let finalPrice = parseFloat(grossTotalRate) + parseFloat(GSTAdded);

    if (selectedProduct.mrp !== "" && selectedProduct.mrp !== 0) {
      GSTAdded = GST * parseFloat(selectedProduct.mrp);
      finalPrice = parseFloat(selectedProduct.mrp) + parseFloat(GSTAdded);
    }

    // Calculate total making charges
    let totalMakingCharges =
      parseFloat(makingCharges1) +
      parseFloat(makingCharges2) +
      parseFloat(makingCharges3) +
      parseFloat(makingCharges4);

    // Update selectedProduct with additional properties and calculated price
    let updatedProduct = {
      ...selectedProduct,
      finalPrice: parseFloat(grossTotalRate).toFixed(3),
      making: totalMakingCharges,
      totalGstAmount: GSTAdded,
    };
    setSelectedProduct(updatedProduct); // Update the selected product
    setSelectedProductPrice(parseFloat(finalPrice).toFixed(3));
    // setAllSelectedProducts((prev) => [...prev, updatedProduct]);
    // Update the price input field
  };

  // console.log(selectedProduct, "selectedProuctPrice");
  const removeProductFromList = (id) => {
    const updatedProductList = allSelectedProducts.filter((x) => x.id !== id);
    setAllSelectedProducts(updatedProductList);
  };
  // useEffect(() => {
  //   calculateNetAmount();
  // }, [allSelectedProducts]);
  // useEffect(() => {
  //   setAllSelectedProducts((x) => x.filter((y) => y !== selectedProduct));

  // }, [selectedProduct]);
  const calculateNetAmount = () => {
    if (allSelectedProducts.length > 0) {
      let totalNetAmount = allSelectedProducts.reduce(
        (total, product) => total + parseFloat(product.finalPrice),
        0
      );
      let totalGstAmount = allSelectedProducts.reduce(
        (total, product) => total + parseFloat(product.totalGstAmount),
        0
      );
      let totalAmountPaying = allSelectedProducts.reduce(
        (total, product) =>
          total +
          parseFloat(product.finalPrice) +
          parseFloat(product.totalGstAmount),
        0
      );
      setAllProdctsNetAmount(parseFloat(totalNetAmount).toFixed(3));
      setAllProdctsGstAmount(parseFloat(totalGstAmount).toFixed(3));
      setTotalPayableGstAmount(parseFloat(totalGstAmount).toFixed(3));
      setTotalPayableAmount(parseFloat(totalAmountPaying).toFixed(3));
    } else {
      setAllProdctsNetAmount(0); // Reset the total to 0 when there are no selected products
      setAllProdctsGstAmount(0); // Reset the total to 0 when there are no selected products
      setTotalPayableGstAmount(0);
      setTotalPayableAmount(0);
    }
  };
  useEffect(() => {
    calculateNetAmount();
  }, [selectedProduct]);

  const handleProductDiscount = (e) => {
    const { value } = e.target;
    setDiscountAmount(value);
    setAllProdctsNetAmount((x) => x - value);
  };
  const recalculatePrices = () => {
    // Calculate individual product prices and update their properties
    const updatedProducts = allSelectedProducts.map((product) => {
      // Recalculate the price based on updated properties (e.g., grosswt, stoneWeight, etc.)
      const recalculatedProduct = calculateFinalPrice(product);

      return recalculatedProduct;
    });

    // Calculate the total net amount and total GST amount
    // setAllSelectedProducts(updatedProducts);
    // calculateNetAmount();

    // Update the state with the recalculated product list
    // setAllSelectedProducts(updatedProducts);
  };
  // useEffect(() => {
  //   if (selectedProduct) {
  //     // const finalPrice = calculateFinalPrice(selectedProduct);
  //     setSelectedProductPrice(finalPrice);
  //     setTotalPrice((x) => parseFloat(x) + finalPrice);
  //   }
  // }, [selectedProduct]);
  // console.log(allSelectedProducts);
  const changeTotalPrice = (e) => {
    const newTotalPayableAmount = parseFloat(e.target.value);
    console.log("TotalPayAmt", totalPayableAmount);
    console.log("NewTotalPayAmt", newTotalPayableAmount);
    const perTotalPayableAmount = newTotalPayableAmount / 103;

    setTotalPayableGstAmount((perTotalPayableAmount * 3).toFixed(3));
    setTotalPayableAmount(e.target.value);
    setDiscountAmount(
      (
        parseFloat(allProdctsNetAmount) +
        parseFloat(perTotalPayableAmount * 3) -
        parseFloat(newTotalPayableAmount)
      ).toFixed(3)
    );
    // setTotalPayableAmount(parseFloat(e.target.value));
    // setTotalPayableGstAmount(
    //   parseFloat(newTotalPayableAmount) +
    //     parseFloat(discountAmount) -
    //     parseFloat(allProdctsNetAmount)
    // );
  };
  // const changeTotalPrice = (e) => {
  //   const newTotalPayableAmount = parseFloat(e.target.value);
  //   console.log("TotalPayAmt", totalPayableAmount);
  //   console.log("NewTotalPayAmt", newTotalPayableAmount);
  //   setDiscountAmount(
  //     (
  //       parseFloat(allProdctsNetAmount) +
  //       parseFloat(allProdctsGstAmount) -
  //       parseFloat(newTotalPayableAmount)
  //     ).toFixed(3)
  //   );
  //   setTotalPayableAmount(parseFloat(e.target.value));
  //   setTotalPayableGstAmount(
  //     parseFloat(newTotalPayableAmount) +
  //       parseFloat(discountAmount) -
  //       parseFloat(allProdctsNetAmount)
  //   );

  // };
  console.log(selectedProduct);
  const createOrder = async () => {
    try {
      const formData = {
        Customer_Id: selectedCustomer.id,
        Product_id: allSelectedProducts[0].id,
        Qty: 1,
        Price: `${
          parseFloat(allProdctsNetAmount) + parseFloat(allProdctsGstAmount)
        }`,
        PaymentMode: "Cash",
        GovtTax: `${totalPayableGstAmount}`,
        ReceivedAmt: parseFloat(totalPayableAmount),
        OrderStatus: "Paid",
        CategoryName: `${allSelectedProducts[0].category_Name}`,
        Offer: `${discountAmount}`,
      };
      console.log(formData);
      const response = await fetch(a48, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const rcvdData = await response.json();
      const orderData = rcvdData.data;
      // setOrderId(orderData.id);
      console.log(rcvdData, "1st hit");
      // createOrderItems(rcvdData.data.id);
      if (rcvdData.status === "error") {
        setLoading(false);
        alert(rcvdData.message); // Show error message
      } else {
        setLoading(false);
        createOrderItems(rcvdData.data.id);
      }
    } catch (error) {
      alert(error);
      console.error(error);
      setLoading(false);
    }
  };
  const createOrderItems = async (rcvdId) => {
    try {
      const orderItemsList = allSelectedProducts.map((product) => {
        return {
          OrderId: rcvdId,
          ProductId: product.id,
          // MRP: `${product.mrp}`,
          ProductName: product.collection,
          Quantity: "1",
          StoneWeight: product.stoneWeight,
          HSNCode: `${product.hsn_code}` || "",
          ItemCode: product.itemCode,
          grosswt: product.grosswt,
          netWt: product.netWt,
          StoneWeight: product.stoneWeight,
          Purity: product.purity,
          makingchrg: `${product.making}`,
          Rate: `${product.tblPurity.todaysRate}`,
          HUIDCode: product.huidCode,
          Size: product.size,
          price: `${(
            parseFloat(product.finalPrice) + parseFloat(product.totalGstAmount)
          ).toFixed(3)}`,
        };
      });
      console.log(orderItemsList);
      const response = await fetch(a49, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderItemsList),
      });
      const rcvdData = await response.json();
      const orderData = rcvdData.data;
      setOrderItemsData(orderData);
      console.log(orderData[0].orderId, "second Hit");
      if (rcvdData.status === "error") {
        setLoading(false);
        alert(rcvdData.message);
      } else {
        setLoading(false);
        console.log(rcvdData.data[0].orderId, "rcvdDta 2nd");
        showPDFWithId(rcvdData.data[0].orderId);
      }
    } catch (error) {
      alert(error);
      console.error(error);
      setLoading(false);
    }
  };
  function showPDFWithId(id) {
    // Make the API POST request with the ID
    fetch(a51, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob()) // Convert response to Blob
      .then((blob) => {
        // Create a URL for the Blob object
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl, "_blank");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  // console.log(allSelectedProducts);
  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        <h2 style={{ marginTop: "20px" }}>Customer Bill</h2>
        <div className="customerBillMainOuterBox">
          <div className="customerBillMainInnerBox">
            <div
              style={{ justifyContent: "flex-end" }}
              className="bulkProductAddingTableMain"
            >
              <button
                style={{
                  cursor: "pointer",
                  paddingInline: "60px",
                  margin: "20px",
                }}
                onClick={() => navigate("/adminaddcustomer")}
              >
                Add Customer
              </button>
              <button
                style={{
                  cursor: "pointer",
                  paddingInline: "60px",
                  margin: "20px",
                }}
                onClick={() => navigate("/adminaddbulkstock")}
              >
                Label Product
              </button>
            </div>
            <h4 style={{ margin: "10px", marginTop: "30px" }}>
              CUSTOMER DETAILS
            </h4>
            <div className="customerBillCustomersDetailsMainBox">
              <div className="customerBillCustomersDetailsItems">
                <label>Customer Name</label>
                <input
                  type="text"
                  name="customerName"
                  value={customerName}
                  onInput={handleNameInputChange}
                  list="customerNamesList"
                />
                <datalist id="customerNamesList">
                  {allCsData.map((customer) => (
                    <option
                      key={customer.id}
                      value={`${customer.firstName} ${customer.lastName}`}
                    />
                  ))}
                </datalist>
              </div>
              <div className="customerBillCustomersDetailsItems">
                <label>Mobile Number</label>
                <input
                  type="text"
                  name="customerMobile"
                  value={customerMobile}
                  onInput={handleMobileInputChange}
                  list="customerMobilesList"
                />
                <datalist id="customerMobilesList">
                  {allCsData.map((customer) => (
                    <option key={customer.id} value={customer.mobile} />
                  ))}
                </datalist>
              </div>
              <div className="customerBillCustomersDetailsItems">
                <label>Email</label>
                <input
                  type="text"
                  name="customerEmails"
                  value={customerEmail}
                  onInput={handleEmailInputChange}
                  list="customerEmailsList"
                />
                <datalist id="customerEmailsList">
                  {allCsData.map((customer) => (
                    <option key={customer.id} value={customer.email} />
                  ))}
                </datalist>
              </div>
            </div>
            <h4 style={{ margin: "10px" }}>PRODUCT INFORMATION</h4>
            <div className="customerBillProductsDetailsMainBox">
              <div className="customerBillCustomersDetailsItems">
                <label>Label</label>
                <input
                  type="text"
                  name="productLabel"
                  value={labelName}
                  onInput={handleProductLabelChange}
                  list="productLabelList"
                />
                <datalist id="productLabelList">
                  {allProductData.map((product) => (
                    <option key={product.id} value={product.itemCode} />
                  ))}
                </datalist>
              </div>
              <div className="customerBillCustomersDetailsItems">
                <label>Barcode</label>
                <input
                  type="text"
                  name="barcodeNumber"
                  value={barcode}
                  onInput={handleProductBarcodeChange}
                  list="productBarcodeList"
                />
                <datalist id="productBarcodeList">
                  {allProductData.map((product) => (
                    <option key={product.id} value={product.barcodeNumber} />
                  ))}
                </datalist>
              </div>
              <div className="customerBillCustomersDetailsItems">
                <label>Category Name</label>
                <input
                  type="text"
                  name="productCategory"
                  value={categoryName}
                  readOnly
                />
              </div>
              <div className="customerBillCustomersDetailsItems">
                <label>Product Name</label>
                <input
                  type="text"
                  name="productType"
                  value={productName}
                  readOnly
                />
              </div>
              <div className="customerBillCustomersDetailsItems">
                <label>Collection</label>
                <input
                  type="text"
                  name="collectionName"
                  value={collectionName}
                  readOnly
                />
              </div>
              <div className="customerBillCustomersDetailsItems">
                <label>Purity</label>
                <input
                  type="text"
                  name="purityTypes"
                  value={purityType}
                  readOnly
                />
                {/* <datalist id="purityTypes">
                  {allProductData.map((product) => (
                    <option key={product.id} value={product.purity} />
                  ))}
                </datalist> */}
                {/* <label>Quantity</label>
                <input
                  type="text"
                  name="purityTypes"
                  required="required"
                  value={productQty}
                  onChange={(e) => changeProductQuantity(e)}
                /> */}
                <label>Price</label>
                <input
                  style={{ backgroundColor: "wheat" }}
                  type="text"
                  name="purityTypes"
                  value={selectedProductPrice}
                  readOnly
                />
                <button
                  onClick={() => {
                    if (labelName) {
                      addProductToList(selectedProduct);
                    } else {
                      alert("Label is missing");
                    }
                  }}
                  className="customerBillAddButton"
                >
                  ADD
                </button>
              </div>
            </div>
            <div className="customerBillAddedProductsMainBox">
              <div
                style={{ height: "100px", marginBottom: "1rem" }}
                className={loading == true ? "loading" : "none"}
              >
                <InfinitySpin
                  className={loading == true ? "loading" : "none"}
                  width="150"
                  color="#4fa94d"
                />
              </div>
              {/* <div className="customerBillTotalPriceBoxMain">
                <p>Hello</p>
              </div> */}
              <div
                style={{ width: "65%" }}
                className="customerBillAddedProductsInnerBox"
              >
                <h3 style={{ margin: "0.5rem 0" }}>Added Products</h3>
                {/* <form onSubmit={updatedetailsBox}> */}
                <div
                  className="bulkProductAddingTableMain"
                  style={{
                    width: "auto",
                    margin: "1.5rem",
                    overflowX: "auto",
                  }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Product Type</th>
                        <th>Collection</th>
                        <th>Purity</th>
                        <th>Label</th>
                        <th>Barcode Number</th>
                        <th>TID</th>
                        <th>Product name</th>
                        <th>HUID Code</th>
                        <th>GrossWt</th>
                        <th>StoneWt</th>
                        <th>NetWt</th>
                        <th>Stone Amount</th>
                        <th>Making Per Gram</th>
                        <th>Making Percentage</th>
                        <th>Fixed Making</th>
                        <th>Fixed Wastage</th>
                        <th>Pieces</th>
                        <th>Size</th>
                        <th>MRP</th>
                        <th>Description</th>
                        <th>Occassion</th>
                        <th>Gender</th>
                        <th>Online Status</th>
                        <th>Quantity</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allSelectedProducts !== null &&
                        allSelectedProducts.map((x) => (
                          <tr key={x.id}>
                            <td>
                              <input
                                type="text"
                                placeholder={x.itemType}
                                readOnly
                                // value={x.product_type}
                                // onChange={(e) => handleInputChange(e, x.id, "Product_type")}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder={x.collection}
                                readOnly
                                // onChange={(e) => handleInputChange(e, x.id, "collection")}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder={x.purity}
                                readOnly
                                // onChange={() => {
                                //   setPurity(x.purity);
                                // }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder={x.itemCode}
                                readOnly
                                //   onChange={() => {
                                //     setItemCode(x.itemCode);
                                //   }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder={x.BarcodeNumber}
                                // onChange={(e) =>
                                //   handleInputChange(e, x.id, "barcodeNumber")
                                // }
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                style={{ cursor: "not-allowed" }}
                                type="text"
                                placeholder={x.tid}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder={x.product_Name}
                                value={x.product_Name}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "product_Name")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                maxLength={6}
                                placeholder={x.huidCode}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "huidCode")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.grosswt}
                                value={x.grosswt}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "grosswt")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.stoneWeight}
                                value={x.stoneWeight}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "stoneWeight")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.netWt}
                                value={x.netWt}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "netWt")
                                }
                              />
                            </td>

                            <td>
                              <input
                                type="number"
                                placeholder={x.stoneAmount}
                                value={x.stoneAmount}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "stoneAmount")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.making_per_gram}
                                value={x.making_per_gram}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "making_per_gram")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.making_Percentage}
                                value={x.making_Percentage}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    x.id,
                                    "making_Percentage"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.making_Fixed_Amt}
                                value={x.making_Fixed_Amt}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    x.id,
                                    "making_Fixed_Amt"
                                  );
                                  // recalculatePrices();
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.making_Fixed_Wastage}
                                value={x.making_Fixed_Wastage}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    x.id,
                                    "making_Fixed_Wastage"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.pieces}
                                value={x.pieces}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "pieces")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.size}
                                value={x.size}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "size")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.mrp}
                                value={x.mrp}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "mrp")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder={x.description}
                                value={x.description}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "description")
                                }
                              />
                            </td>

                            <td>
                              <input
                                type="text"
                                placeholder={x.occasion}
                                value={x.occasion}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "occasion")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder={x.gender}
                                // onChange={(e) =>
                                //   handleInputChange(e, x.id, "gender")
                                // }
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder={x.onlineStatus}
                                // onChange={(e) =>
                                //   handleInputChange(e, x.id, "onlineStatus")
                                // }
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder={x.orderedQty}
                                value={x.orderedQty}
                                onChange={(e) =>
                                  handleInputChange(e, x.id, "orderedQty")
                                }
                              />
                            </td>
                            <td>
                              <RxCross2
                                size={"2rem"}
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  removeProductFromList(x.id);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {/* <button
                    style={{ cursor: "pointer" }}
                    // onClick={handleEditProducts}
                  >
                    Save All
                  </button>

                  <button
                  // onClick={() => {
                  //   setAddedProducts([]);
                  //   setSelectedFiles([]);
                  // }}
                  >
                    New Item
                  </button> */}
                  {/* <button>Cancel</button> */}
                </div>
              </div>
              <div className="customerBillTotalPriceBoxMain">
                <div className="customerBillTotalPriceInnerBox">
                  <label>Net Amount</label>
                  {/* <input type="text" value={allProdctsNetAmount} readOnly /> */}
                  <input
                    type="text"
                    value={parseFloat(allProdctsNetAmount)}
                    readOnly
                  />
                  {/* <button>Save</button> */}
                  <label>R.O./Discount(-)</label>
                  <input
                    type="text"
                    value={parseFloat(discountAmount)}
                    readOnly
                    // onChange={(e) => handleProductDiscount(e)}
                  />
                  {/* <button>Cancel</button> */}
                  <label>GST 3%</label>
                  <input
                    type="text"
                    value={parseFloat(totalPayableGstAmount)}
                    readOnly
                  />
                  {/* <button>Print</button> */}
                  <label>Total Amount</label>
                  <input
                    type="text"
                    value={parseFloat(totalPayableAmount)}
                    onChange={(e) => {
                      const newTotalPayableAmount = parseFloat(e.target.value);
                      if (!isNaN(newTotalPayableAmount)) {
                        // Check if the input value is a valid number
                        setTotalPayableGstAmount(
                          ((newTotalPayableAmount / 103) * 3).toFixed(2)
                        );
                        changeTotalPrice(e);
                      } else {
                        setTotalPayableAmount(allProdctsNetAmount);
                      }
                    }}
                  />
                  {/* <button>All Orders</button> */}
                </div>
              </div>
            </div>
            <div className="bulkProductAddingTableMain">
              <button
                style={{ cursor: "pointer", paddingInline: "100px" }}
                onClick={() => {
                  if (selectedCustomer && allSelectedProducts.length > 0) {
                    createOrder();
                  } else {
                    alert("Please add all details");
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
