import React, { useEffect } from "react";
import "../PagesStyles/ProductPage.css";
import { useState } from "react";
import blankStar from "../../Images/blankStar.png";
import filledStar from "../../Images/filledStar.png";
import YouMayLike from "./YouMayLike";
import CustomerFavourites from "./CustomerFavourites";
import banner from "../../Images/CompanyBanner.png";
// {/* <li>MATERIAL : {material}</li>
// <li>GENDER : {gender}</li>
// <li>OCCASION : {occasion}</li>
// {/* <li>METAL : {details[0].metal}</li> */}
// <li>COLLECTION : {collection}</li>
// <li>PURITY : {purity}</li>
// <li>PRODUCT TYPE : {product_type}</li>
// </div> */}
export default function PriceBreakup({
  id,
  product_Id,
  product_No,
  product_Name,
  categoryId,
  categoryName,
  huid,
  netWt,
  size,
  grosswt,
  purity,
  collection,
  occasion,
  gender,
  description,
  product_Type,
  itemType,
  making_per_gram,
  making_Percentage,
  making_Fixed_Amt,
  making_Fixed_Wastage,
  purityRate,
  stoneWeight,
  stoneAmount,
  featured,
  productCode,
  mrp,
  images,
  imageList1,
  imageList2,
  // imageList3,
  // imageList4,
  // imageList5,
  statusType,
}) {
  const [border, setBorder] = useState("border1");
  const [finalProductPrice, setFinalProductPrice] = useState("");
  const [netGoldRatePrice, setNetGoldRatePrice] = useState("");
  const [netMakingCharges, setNetMakingCharges] = useState("");
  const [grossTotalPrice, setGrossTotalPrice] = useState("");
  const [gstAmount, setGstAmount] = useState("");

  const [detailsBox, setDetailsBox] = useState(() => {
    return <div className="blankBox"></div>;
  });
  useEffect(() => {
    setDetailsBox(() => {
      return (
        <div className="priceBreakUpBoxBelow table1">
          <table>
            <tr>
              <th>Material</th>
              <th>Gender</th>
              <th> Occasion</th>
              <th> Collection</th>
              <th> Purity</th>
              <th> Product Type</th>
            </tr>
            <tr>
              <td>{categoryName}</td>
              <td>{gender}</td>
              <td>{occasion}</td>
              <td>{collection}</td>
              <td>{purity}</td>
              <td>{itemType}</td>
            </tr>
          </table>
        </div>
      );
    });
  }, []);
  const ratingArray = [1, 2, 3, 4, 5];
  const [rating, setRating] = useState(() => {
    return (
      // <div className="reviewSectionRating">
      //   </div>
      <img
        onClick={() => giveRate()}
        style={{ width: "20px" }}
        src={blankStar}
        alt="rating"
      />
    );
  });
  const giveRate = () => {
    setRating(() => {
      return <img style={{ width: "20px" }} src={filledStar} alt="rste" />;
    });
  };
  var today = new Date();
  var month = today.toLocaleString("default", {
    month: "long",
  });
  var day = today.getDate();
  var year = today.getFullYear();
  useEffect(() => {
    AOS.init({ duration: 1500 });
    calculateFinalPrice(
      netWt,
      making_per_gram,
      making_Percentage,
      making_Fixed_Amt,
      making_Fixed_Wastage,
      stoneAmount,
      mrp,
      purityRate,
      id
    );
  }, [id]);
  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    purityRate,
    id
  ) => {
    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;
    let grossTotalRate = 1;

    if (mrp !== "" && mrp !== 0) {
      grossTotalRate = mrp;
    }

    grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount;

    let GSTAdded = GST * grossTotalRate;
    let finalPrice = grossTotalRate + GSTAdded;

    // if (mrp !== "" && mrp !== 0) {
    if (mrp !== "" && mrp !== 0) {
      GSTAdded = GST * mrp;
      finalPrice = (mrp + GSTAdded).toFixed(0);
    }
    // finalPrice = parseFloat(mrp);
    // } // Convert to string before set  ting state
    console.log(grossTotalRate * 0.03);
    return (
      finalPrice,
      setFinalProductPrice(finalPrice),
      setNetGoldRatePrice(netGoldRate.toFixed(0)),
      setNetMakingCharges(
        (
          makingCharges1 +
          makingCharges2 +
          makingCharges3 +
          makingCharges4
        ).toFixed(0)
      ),
      setGrossTotalPrice(grossTotalRate.toFixed(0)),
      setGstAmount(GSTAdded.toFixed(0))
    ); // Convert to string before returning
  };
  return (
    <div className="outestPriceBreakupBox">
      <img
        style={{ width: "100%" }}
        src={banner}
        alt="banner"
        data-aos="fade-down"
      />

      <div id="priceBreakupSection" className="priceBreakUpMainBox">
        <div className="priceBreakUpHeading">
          <div
            className={
              border === "border1"
                ? "priceBreakUpItems borderPresent"
                : "priceBreakUpItems"
            }
            onClick={() =>
              setDetailsBox(() => {
                setBorder("border1");
                return (
                  // <div className="priceDetailsBoxBelow">
                  <div className="priceBreakUpBoxBelow table1">
                    <table>
                      <tr>
                        <th>Material</th>
                        <th>Gender</th>
                        <th> Occasion</th>
                        <th> Collection</th>
                        <th> Purity</th>
                        <th> Product Type</th>
                      </tr>
                      <tr>
                        <td>{categoryName}</td>
                        <td>{gender}</td>
                        <td>{occasion}</td>
                        <td>{collection}</td>
                        <td>{purity}</td>
                        <td>{itemType}</td>
                      </tr>
                    </table>
                  </div>
                  // </div>
                );
              })
            }
          >
            {/* <li>MATERIAL : {material}</li> */}
            {/* <li>GENDER : {gender}</li>
                    <li>OCCASION : {occasion}</li>
                    <li>COLLECTION : {collection}</li>
                    <li>PURITY : {purity}</li>
                  <li>PRODUCT TYPE : {product_Type}</li> */}
            <h3>PRODUCT DETAILS</h3>
          </div>

          <div
            className={
              border === "border2"
                ? "priceBreakUpItems borderPresent"
                : "priceBreakUpItems"
            }
            onClick={() =>
              setDetailsBox(() => {
                setBorder("border2");
                if (mrp === "" || mrp == 0) {
                  return (
                    <div className="priceBreakUpBoxBelow">
                      <table>
                        <thead>
                          <tr>
                            <th>Component</th>
                            <th>
                              {categoryName} ({purity}) Rate
                            </th>
                            <th>Weight (g)</th>
                            <th>Discount</th>
                            <th>Final Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {purity} Plain {categoryName}
                            </td>
                            <td>₹{purityRate}</td>
                            <td>{netWt}gm</td>
                            <td>-</td>
                            <td>₹{netGoldRatePrice}</td>
                          </tr>
                          <tr>
                            <td>Stone Amount</td>
                            <td>-</td>
                            <td>{stoneWeight}gm</td>
                            <td>-</td>
                            <td>₹{stoneAmount.toFixed(0)}</td>
                          </tr>
                          <tr>
                            <td>Making Charges</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>₹{netMakingCharges}</td>
                          </tr>
                          <tr>
                            <td>Sub Total</td>
                            <td>-</td>
                            <td>{grosswt}gm Gross Weight</td>
                            <td>-</td>
                            <td>₹{grossTotalPrice}</td>
                          </tr>
                          <tr>
                            <td>GST</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>₹{gstAmount}</td>
                          </tr>
                          <tr>
                            <th>Grand Total</th>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                              <strong style={{ color: "green" }}>
                                ₹{finalProductPrice.toFixed(0)} (Inclusive. of
                                all Taxes)
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                } else {
                  return (
                    <div className="priceBreakUpBoxBelow">
                      <table>
                        <thead>
                          <tr>
                            <th>Component</th>
                            <th>
                              {categoryName} ({purity}) Rate
                            </th>
                            <th>Weight (g)</th>
                            <th>Discount</th>
                            <th>Final Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {purity} Plain {categoryName}
                            </td>
                            <td>₹{purityRate}</td>
                            <td>{netWt}gm</td>
                            <td>-</td>
                            <td>₹{netGoldRatePrice}</td>
                          </tr>
                          {stoneAmount !== "" && stoneAmount !== 0 && (
                            <tr>
                              <td>Stone Amount</td>
                              <td>-</td>
                              <td>{stoneWeight}gm</td>
                              <td>-</td>
                              <td>₹{stoneAmount.toFixed(0)}</td>
                            </tr>
                          )}
                          {/* <tr>
                            <td>Making Charges</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>₹{netMakingCharges}</td>
                          </tr> */}
                          <tr>
                            <td>Sub Total</td>
                            <td>-</td>
                            <td>{grosswt}gm Gross Weight</td>
                            <td>-</td>
                            {/* <td>₹{(mrp / 1.03).toFixed(2)}</td> */}
                            <td>₹{mrp.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td>GST</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>₹{(0.03 * mrp).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <th>Grand Total</th>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                              <strong style={{ color: "green" }}>
                                ₹{(mrp + 0.03 * mrp).toFixed(0)} (Inclusive. of
                                all Taxes)
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                }
              })
            }
          >
            <h3>PRICE BREAKUP</h3>
          </div>

          <div
            className={
              border === "border3"
                ? "priceBreakUpItems borderPresent"
                : "priceBreakUpItems"
            }
            onClick={() =>
              setDetailsBox(() => {
                setBorder("border3");
                return (
                  <div className="productDescriptionBoxBelow">
                    <h3>{description}</h3>
                  </div>
                );
              })
            }
          >
            <h3>PRODUCT DESCRIPTION</h3>
          </div>
          <div
            className={
              border === "border4"
                ? "priceBreakUpItems borderPresent"
                : "priceBreakUpItems"
            }
            onClick={() =>
              setDetailsBox(() => {
                setBorder("border4");
                return (
                  <div className="reviewsBoxBelow">
                    <h2>
                      2 review for
                      <span id="reviewTitle"> {product_Name}</span>
                    </h2>
                    <div className="reviewSection">
                      <img
                        className="reviewDp"
                        src="https://via.placeholder.com/100"
                        alt="DP"
                      />
                      <div className="reviewDetails">
                        <h3>
                          Lorem ipsum dolor sit, amet consectetur adipisicing
                          elit. Possimus alias inventore reiciendis vel
                          cupiditate explicabo, nam accusantium odit eum
                          nesciunt!
                        </h3>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Quas, quos.
                        </p>
                        <p id="reviewDate">{month + " " + day + ", " + year}</p>
                      </div>
                      <div className="reviewSectionRating">
                        {ratingArray.map((x) => rating)}
                      </div>
                    </div>
                    <div className="addReviewMain">
                      <h2>Add a review</h2>
                      {ratingArray.map((x) => rating)}
                      <div className="reviewInputBox"></div>
                      <label>
                        <textarea
                          name="postContent"
                          rows={4}
                          cols={40}
                          className="reviewInput"
                          placeholder="Your Review *"
                        />
                      </label>
                      <div className="reviewInputDetailsBox">
                        <input
                          className="reviewInputDetails"
                          placeholder="Enter Name *"
                        />
                        <input
                          className="reviewInputDetails"
                          placeholder="Enter Email *"
                        />
                      </div>
                      <button className="addToCartBtn submitReview">
                        Submit Review
                      </button>
                    </div>
                  </div>
                );
              })
            }
          >
            {/* NOTE :JUST UNCOMMENT THIS H3 TO SEE REVIEW SECTION */}
            {/* <h3>REVIEWS(1)</h3> */}
          </div>
        </div>
        {detailsBox}
      </div>
      <YouMayLike key={product_Id} />
      <CustomerFavourites key={product_Name} />
    </div>
  );
}
