import React, { useEffect } from "react";
import Heading from "../Heading/Heading";
import Footer from "./Footer";
import "../Components Styles/Footer.css";
export default function RefundAndExchangePolicy() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="ourContactMainBox termsMain">
        <h1>Return & Exchange</h1>
        <div className="terms-items">
          <p>
            If the customer wishes to exchange any jewellery that is purchased
            from <strong> www.mkgharejewellers.com</strong> they can do so, by
            returning the same to
            <strong> www.mkgharejewellers.com</strong>, registered address or to
            any of <strong>M K GHARE JEWELLERS </strong> in India.
          </p>
          <h4> If exchanged within 14 days of purchase:</h4>
          <p>
            100% exchange of invoice value can be availed by the customer, if
            the product is exchanged within 14 days of purchase. Exchange of
            Smart Buy (Make to order) orders, Gold Coins, Customised Jewellery
            orders and personalised jewellery orders are not allowed. For this
            exchange policy of after 14 days of purchase is applicable.
          </p>
          <h4> If exchanged after 14 days of purchase:</h4>
          <p>
            The platinum and gold rate of
            <strong> www.mkgharejewellers.com</strong> or of the Respective
            store is applicable at the time of exchange will be considered as
            the benchmark rate.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
